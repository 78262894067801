import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'

export const tnvedApi = createApi({
  baseQuery,
  reducerPath: 'tnvedApi',
  endpoints: (builder) => ({
    getTnved: builder.query<TnvedInfo, string>({
      query: (query) => `dictionary/alta/tnved/${query}/`,
    }),
  }),
})

export const { useLazyGetTnvedQuery } = tnvedApi
