import React from "react";

import TextField from "@mui/material/TextField";
import PhoneInput, {CountryData} from "react-phone-input-2";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import ru from 'react-phone-input-2/lang/ru.json'

import './company.scss'
import {useOrder} from "../../../../../api/calc";
import {ORDER_FORM} from "../../../calculator/components/main/components/editMain";
import {EMAIL_PATTERN} from "../../../../../utils/validation";

const RUSSIA_COUNTRY_NAME = 'Russia'
const RUSSIA_PHONE_NUMBER_REGEXP = /7\d{10}/g

export enum COMPANY_FORM {
    INN = 'inn',
    COMPANY_NAME = 'company_name',
    ADDRESS = 'address',
    CONTACT_EMAIL = 'contact_email',
    CONTACT_PHONE = 'contact_phone',
}

export type CompanyParams = {
    [COMPANY_FORM.INN]: string
    [COMPANY_FORM.COMPANY_NAME]: string
    [COMPANY_FORM.ADDRESS]: string
    [COMPANY_FORM.CONTACT_EMAIL]: string
    [COMPANY_FORM.CONTACT_PHONE]: string
}

const Company = () => {

  const { t } = useTranslation()

  const order = useOrder();

  const defaultValues = {
    [COMPANY_FORM.CONTACT_EMAIL]: order?.[ORDER_FORM.CONTACT_EMAIL],
    [COMPANY_FORM.CONTACT_PHONE]: order?.[ORDER_FORM.CONTACT_PHONE],
  };

  const { register, handleSubmit, getValues, setValue, reset, clearErrors, setError, formState: { errors, isValid } } = useForm<CompanyParams>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const handleValidatePhoneNumberRequired = () => {
    if (!getValues(COMPANY_FORM.CONTACT_PHONE)) {
      setError(COMPANY_FORM.CONTACT_PHONE, { type: "custom",  message: t('common__text__isRequired') })
    }
    return Boolean(getValues(COMPANY_FORM.CONTACT_PHONE));
  }

  // @ts-ignore
  const handlePhoneChange = (value: string, country, e, formattedValue: string) => {
    setValue(COMPANY_FORM.CONTACT_PHONE, formattedValue)
  }

  const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>, data: CountryData) => {
    let {value} = event.target;
    value = value.replace(/[+()-/ ]/g, "");

    if (data.name === RUSSIA_COUNTRY_NAME) {
      value.match(RUSSIA_PHONE_NUMBER_REGEXP) ? clearErrors(COMPANY_FORM.CONTACT_PHONE) : setError(COMPANY_FORM.CONTACT_PHONE, { type: "custom",  message: t('errors__message__incorrectPhoneError') });
    }
  }

  return (
    <div className='settings-company'>
      <TextField
        {...register(COMPANY_FORM.INN, { required: t('common__text__isRequired') })}
        error={Boolean(errors[COMPANY_FORM.INN])}
        helperText={errors[COMPANY_FORM.INN]?.message}
        variant='outlined'
        label={t('common__text__inn')}
        InputLabelProps={{ shrink: true }}
        className={`settings-company__${COMPANY_FORM.INN}`}
      />
      <TextField
        {...register(COMPANY_FORM.COMPANY_NAME, { required: t('common__text__isRequired') })}
        error={Boolean(errors[COMPANY_FORM.COMPANY_NAME])}
        helperText={errors[COMPANY_FORM.COMPANY_NAME]?.message}
        variant='outlined'
        label={t('settings__text__companyName')}
        InputLabelProps={{ shrink: true }}
        className={`settings-company__${COMPANY_FORM.COMPANY_NAME}`}
        disabled
      />
      <TextField
        {...register(COMPANY_FORM.ADDRESS, { required: t('common__text__isRequired') })}
        error={Boolean(errors[COMPANY_FORM.ADDRESS])}
        helperText={errors[COMPANY_FORM.ADDRESS]?.message}
        variant='outlined'
        label={t('settings__text__physicalAddress')}
        InputLabelProps={{ shrink: true }}
        className={`settings-company__${COMPANY_FORM.ADDRESS}`}
      />
      <TextField
        {...register(COMPANY_FORM.CONTACT_EMAIL, { required: t('common__text__isRequired'), pattern: { value: EMAIL_PATTERN, message: t('errors__message__incorrectEmail') } })}
        error={Boolean(errors[COMPANY_FORM.CONTACT_EMAIL])}
        helperText={errors[COMPANY_FORM.CONTACT_EMAIL]?.message}
        variant='outlined'
        label={t('common__text__email')}
        InputLabelProps={{ shrink: true }}
        disabled
        className={`settings-company__${COMPANY_FORM.CONTACT_EMAIL}`}
      />
      <div className='settings-company__phone'>
        <PhoneInput
          country={'ru'}
          value={getValues(COMPANY_FORM.CONTACT_PHONE)}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          // @ts-ignore
          isValid={!errors[COMPANY_FORM.CONTACT_PHONE]}
          localization={ru}
          specialLabel={t('common__text__phone')}
          inputClass='main-form__phone-input'
        />
        <span className='settings-company__phone-error'>{errors[COMPANY_FORM.CONTACT_PHONE]?.message}</span>
      </div>
      <Button
        className='settings-company__button'
        variant='contained'
        fullWidth
        type='submit'
        startIcon={<CheckIcon />}
        disabled={!isValid}
      >{t('actions__text__saveCompanyData')}</Button>
    </div>
  )
}

export default Company
