import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import {COLORS} from "../../../../../../../config/constants";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

type Props = {
    service: AddService,
    currentRoute?: Route
    routeId: number
    handleChangeServices(service: number, routeId: number): void
}

const ServiceCheckbox = ({service, handleChangeServices, currentRoute, routeId} : Props) => {

  // @ts-ignore
  const getIsChecked = () => currentRoute ? (currentRoute.id === routeId && currentRoute.selected_services?.includes(service.id)) : false;

  const [ isChecked, setIsChecked ] = React.useState(service.id ? getIsChecked() : true);

  useEffect(() => {
    setIsChecked(service.id ? getIsChecked() : true)
  }, [ currentRoute ]);

  const handleChange = () => {
    if (!service.id) {
      return;
    }
    setIsChecked(!isChecked);
    handleChangeServices(service.id, routeId);
  }

  return <FormGroup sx={{ background: COLORS.BEIGE, padding: '6px 0', marginBottom: '1px' }} key={service.name}>
    <FormControlLabel
      control={<Checkbox checked={isChecked} disabled={service.isDisabled || !service.id} size='small' onChange={handleChange} />}
      label={<span className='delivery-card__label'>
        <Typography variant='body2' sx={{ color: COLORS.BLACK }}>
          {service.name}
        </Typography>
        <Typography variant='body2' sx={{ fontWeight: 500, color: isChecked ? COLORS.BLACK : COLORS.BORDER_GRAY}}>
          {service.price}
        </Typography>
      </span>}
    />
  </FormGroup>
}

export default ServiceCheckbox
