import SummaryCalculator from '../../../../common/summaryCalculator';

import React, { useEffect } from "react";
import {
  useAdditionalServices,
  useGetOrderSummaryByIdQuery, useGetRouteByOrderIdQuery,
  useInvoicesByOrderIdQuery,
  useOrderByIdQuery, useSummaryByOrderIdQuery,
} from "../../../../../api/calc";
import isEmpty from "lodash/isEmpty";

type Props = {
    id: number;
}

const Summary = ({id}: Props) => {

  const orderId = id.toString();

  useEffect(() => {

  }, [ orderId ])

  const { data: order } = useOrderByIdQuery(orderId)
  const invoices = useInvoicesByOrderIdQuery(orderId)?.data
  const { data: summaryInfo } = useGetOrderSummaryByIdQuery(orderId)
  const { data: summaryOrder } = useSummaryByOrderIdQuery(orderId)
  const { data: summaryDelivery } = useGetRouteByOrderIdQuery(orderId)

  const userSummaryOrder = {
    ...summaryOrder,
    containers_user: !isEmpty(summaryOrder?.containers_manual) ? summaryOrder?.containers_manual : summaryOrder?.containers
  }

  const additionalServices = useAdditionalServices();

  const expenses: AdditionalService[] = [];
  const services: AdditionalService[] = [];

  additionalServices?.map((additionalService: AdditionalService) => {
    if (additionalService.id) {
      services.push(additionalService)
    } else {
      expenses.push(additionalService)
    }
  })

  const getAllAdditionalData = (additionalData: AdditionalService[] | [], isServices?: boolean) => {
    if (additionalData.length === 0) {
      return undefined;
    }

    let stringValue = '';

    const getAdditionalServiceName = (addService: AdditionalService) => `${stringValue ? `${stringValue}, ` : ''}${addService.name} — ${addService.cost} USD`

    additionalData.map((addService) => {
      // @ts-ignore
      if (isServices && summaryDelivery?.selected_services.includes(addService.id)) {
        stringValue = getAdditionalServiceName(addService);
      }

      if (!isServices) {
        stringValue = getAdditionalServiceName(addService);
      }
    });

    return stringValue;
  }

  const summaryAdditionalServices = {
    expenses: getAllAdditionalData(expenses),
    services: getAllAdditionalData(services, true),
  }

  return (
    <SummaryCalculator
      noInfo
      order={order}
      invoices={invoices}
      // @ts-ignore
      summaryOrder={userSummaryOrder}
      summaryDelivery={summaryDelivery}
      summaryCustoms={summaryInfo?.customs}
      summaryCompleted={summaryInfo?.completed}
      summaryBankCommissions={summaryInfo?.summary}
      summaryAdditionalServices={summaryAdditionalServices}
    />
  )
}

export default Summary
