import React, {ReactNode} from "react";

import {useTranslation} from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Tooltip from "../../../../../../../../common/tooltip";
import { SummaryData } from "../../index";
import './../../summary.scss'

import {INFORMERS_CODES} from "../../../../../../../../../config/constants";
import {useInformers} from "../../../../../../../../../api/informers";

type Props =  {
    summary: SummaryParamsOutput
    renderSummary(data: SummaryData): void
    handleSave?(): void
}

const CombinedCargo = ({summary, renderSummary, handleSave}: Props) => {

  const { t } = useTranslation()

  const informers = useInformers();

  const getItemsCombinedCargo = () => [
    {
      label: t('calc__text__delivery__airfreight'),
      value: `${summary?.volume_weight.avia || 0} ${t('units__text__kg')}`
    },
    {
      label: t('calc__text__delivery__seafreight'),
      value: `${summary?.volume_weight.sea || 0} ${t('units__text__kg')}`
    },
    {
      label: t('calc__text__delivery__trucking'),
      value: `${summary?.volume_weight.auto || 0} ${t('units__text__kg')}`
    },
    {
      label: t('calc__text__delivery__railwayFreight'),
      value: `${summary?.volume_weight.rails || 0} ${t('units__text__kg')}`
    }
  ]

  return (<div className='summary-packaging__block-delivery'>
    <div className='summary-packaging__buttons'>
      <span className='summary-packaging__tooltipContainer'>
        <Tooltip content={informers[INFORMERS_CODES.VOLUME_WEIGHT]}/>
      </span>
    </div>
    <>
      {renderSummary({ title: <b>{t('calc__text__packaging__volumetricWeight')}</b>, items: getItemsCombinedCargo()})}
    </>
    {handleSave && <Button
      variant='contained'
      fullWidth
      onClick={handleSave}
      startIcon={<CheckIcon />}
      sx={{ marginTop: 3}}
    >{t('actions__text__confirmDeliveryMethodCollectShipment')}</Button>}
  </div>)
}

export default CombinedCargo
