import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Summary from '../summary';

import COLORS from 'styles/colors.scss'
import {useTranslation} from "react-i18next";

import './calculation.scss'
import React, {useRef} from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PrintOrder from "../../../../print/order";
import {useReactToPrint} from "react-to-print";
import {
  useInvoicesByOrderIdQuery,
  useOrderByIdQuery,
  useSummaryByOrderIdQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation
} from "../../../../../api/calc";
import AlertBottom from "../../../../common/alertBottom";

const enum ACTIONS {
    PRINT = "PRINT",
    EDIT = "EDIT",
    DELETE = "DELETE",
}

type Props = {
  order: Calculation;
  refetch(): void;
}

export const enum CalculationStatus {
  DRAFT = 'draft',
  DONE = 'done',
}

const Calculation = ({order, refetch}: Props) => {

  // @ts-ignore
  const isCompleted = order.status === CalculationStatus.DONE;

  const [ action, setAction ] = React.useState<string>();
  const [ isExpanded, setIsExpanded ] = React.useState(false);
  const [ openAlert, setOpenAlert ] = React.useState(false);

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [ deleteOrder ] = useDeleteOrderMutation()

  const componentRef = useRef();

  const handleAction = (value: string) => {
    setAction(value);
    // @ts-ignore
    if (value === ACTIONS.EDIT) {
      navigate(`/cabinet/calculator/${order.num}#title`)
    }

    if (value === ACTIONS.DELETE) {
      // @ts-ignore
      deleteOrder(order.num).then(() => {
        setOpenAlert(true);
        refetch()
      })
    }
  }

  const getPageMargins = () => `@page { margin: 32px 48px !important; }`

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: `${t('computation__text__computation')} №${order.num}`
  });

  return (
    <>
      <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: '20px 24px',
            'svg': { height: '32px', width: '32px' },
            '.Mui-expanded.MuiAccordionSummary-content': {
              margin: '12px 0 10px'
            }
          }}
        >
          <div className='calculation'>
            {isCompleted ? <CheckCircleIcon sx={{ color: COLORS.GREEN, marginRight: 1, width: '32px', height: '32px'}} /> : <CheckCircleOutlineIcon sx={{ color: COLORS.SLIGHTLY_GRAY, marginRight: 1, width: '32px', height: '32px' }} />}
            <div className='calculation__content'>
              <Typography variant='subtitle1' className='calculation__number' onClick={() => navigate(`/cabinet/calculator/${order.num}#title`)}>
                {`${t('computation__text__computation')} №${order.num}`}
              </Typography>
              <div className='calculation__title'>
                <Typography variant='h5' sx={{ fontWeight: 500}}>{order.order_name}</Typography>
                <Typography variant='h6' sx={{ marginRight: 1}}>{order.summary?.total_amount ? `${order.summary?.total_amount} USD` : ''} </Typography>
              </div>
              <Typography variant='caption'>
                {`${t('common__text__creating')}: ${moment(order.created_at).format('DD.MM.YYYY hh:mm:ss')} | ${t('common__text__editing')}: ${moment(order.updated_at).format('DD.MM.YYYY hh:mm:ss')}`}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='calculation__details'>
            {isExpanded && <Summary id={order.id} />}
          </div>
          <FormControl
            sx={{ marginTop: 4, width: '344px'}}>
            <InputLabel id='action-select'>{t('common__text__actions')}</InputLabel>
            <Select
              labelId='action-select'
              id='action-select'
              value={action}
              label={t('common__text__actions')}
              onChange={({ target: { value } }) => handleAction(value)}
            >
              <MenuItem value={ACTIONS.EDIT}>{t('actions__text__edit')}</MenuItem>
              <MenuItem value={ACTIONS.DELETE}>{t('actions__text__delete')}</MenuItem>
              {isCompleted && <MenuItem value={ACTIONS.PRINT} onClick={handlePrint}>{t('actions__text__print')}</MenuItem>}
            </Select>
          </FormControl>
          {isExpanded && isCompleted && <div className='calculation__print-version'>
            <PrintOrder ref={componentRef} />
            <style>{getPageMargins()}</style>
          </div>}
        </AccordionDetails>
      </Accordion>
      <AlertBottom
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        message={t('actions__message__removeCalculation')}
      />
    </>
  )
}

export default Calculation
