import React, {ReactNode, useState} from 'react'

import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import COLORS from 'styles/colors.scss'
import './template.scss'
import cx from "classnames";

type TemplateParams = {
    text: string
    isCompleted?: boolean
    inProgress?: boolean
    isExpanded?: boolean
    isDisabled?: boolean
    onClick(): void
    info: {
        label: string
        value: ReactNode
        isStrong?: boolean
    }[]
}

const Template = (props: TemplateParams) => {

  const shouldShowProgressIcon = (!props.isDisabled || props.inProgress) && !props.isCompleted;

  return (
    <Accordion
      sx={{
        boxShadow: 0,
        marginBottom: 1,
        '&.Mui-expanded:last-of-type': {
          marginBottom: '24px',
        }
      }}
      expanded={props.isExpanded}
      disabled={props.isDisabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='rightPanel'
        id='rightPanel'
        onClick={props.onClick}
        sx={{ '.MuiAccordionSummary-content, .Mui-expanded.MuiAccordionSummary-content': { margin: '16px 0 8px' } }}
      >
        <Typography variant='subtitle1' className={cx('right-panel__text', {
          'right-panel__text_noData': props.isDisabled && !props.isCompleted,
        })}>
          {props.isCompleted && <CheckCircleIcon sx={{ color: COLORS.GREEN, marginRight: '12px', width: '24px', height: '24px'}} /> }
          {shouldShowProgressIcon && <CheckCircleOutlineIcon sx={{ color: COLORS.SLIGHTLY_GRAY, marginRight: 1, width: '24px', height: '24px' }} />}
          {props.text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '2px 16px 0' }}>
        <ul className='right-panel__details'>
          {props.info.map((item, index) => (
            <li className='right-panel__details-item' key={index}>
              <Typography variant='caption'>
                {item.label}
                <br />
                <span className='right-panel__details-value'>{item.value}</span>
              </Typography>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  )
}

export default Template
