import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {FormProvider, useForm, useFieldArray } from "react-hook-form";
import isEmpty from 'lodash/isEmpty'

import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import PaymentItem from './components/payment-item';
import PaymentCompleted from "./components/payment-completed";
import PaymentCompletedEdit from "./components/payment-completed-edit";
import PaymentAccordionContainer from "./components/payment-accordion-container";

import {
  useUpdateBankCommissionsMutation,
  useBankCommissionsByInvoiceId,
  useSetCompletedByOrderIdMutation,
} from "../../../../../../../../../api/calc";

import { NOTIFICATION_TYPE} from "../../../../../../../../common/notification";
import Notification from "../../../../../../../../common/notification";
import AlertBottom from "../../../../../../../../common/alertBottom";

import './payments.scss';
import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";
import {Simulate} from "react-dom/test-utils";

export enum ADD_PAYMENT_FORM {
  COMMISSION_PERCENTAGE = 'commission_percentage',
  PAYMENT_AMOUNT = 'payment_amount',
  BANK_TRANSFER_FEE = 'bank_transfer_fee',
  MIN_BANK_TRANSFER_FEE = 'min_bank_transfer_fee',
  MAX_BANK_TRANSFER_FEE = 'max_bank_transfer_fee',
  CURRENCY_CONTROL_FEE = 'currency_control_fee',
  MIN_CURRENCY_CONTROL_FEE = 'min_currency_control_fee',
  MAX_CURRENCY_CONTROL_FEE = 'max_currency_control_fee'
}

export type AddParamsPaymentForm = {
  [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: string
  [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: string
  [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: string
  [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: string
  [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: string
}

const INITIAL_VALUES_PAYMENT = {
  [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: '',
  [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: '',
  [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: '',
  [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: '',
  [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: '',
  [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: '',
  [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: '',
  [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: '',
};

type PaymentsType = {
  payments: AddParamsPaymentForm[]
}

type Props = {
  invoiceNumber: string
  invoiceAmount: number
  invoiceId: string
  refetch(): void
}

const Payments = ({ invoiceNumber, invoiceAmount, invoiceId, refetch }: Props) => {

  const { t } = useTranslation()

  const [ showAddNewPaymentCompleted, setShowAddNewPaymentCompleted ] = useState(false);

  const [ openAlert, setOpenAlert ] = React.useState(false);
  const [ errorText, setErrorText ] = React.useState('');
  const [ formIsReady, setFormIsReady ] = React.useState(false);

  const methods = useForm<PaymentsType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      payments: [ INITIAL_VALUES_PAYMENT ],
    },
  });

  const [ saveBankCommissions ] = useUpdateBankCommissionsMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const orderId = useGetCalcIdFromRoute()

  const { control, handleSubmit, getValues, formState, watch} = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: 'payments',
  });

  const allFieldsFilled = (item: any) => Object.values(item).every((value) => value !== '');

  const allItemsFilled = () => {
    const allPayments = getValues().payments;
    return allPayments.every((payment) => allFieldsFilled(payment));
  };

  watch(() => {
    setFormIsReady(allItemsFilled());
  });


  const invoiceBankCommissions = useBankCommissionsByInvoiceId(invoiceNumber);

  const isCompletedInvoice = !isEmpty(invoiceBankCommissions?.banking_commissions);

  const onSubmit = handleSubmit (async (values) => {

    try {

      const response: any = isCompletedInvoice ?
        invoiceBankCommissions && await saveBankCommissions({
          invoiceId,
          payments: invoiceBankCommissions.banking_commissions
        }) :
        await saveBankCommissions({invoiceId, payments : values.payments})
      if (response?.error) {
        setErrorText(response.error.data.error);
      } else {
        setErrorText('')
        setOpenAlert(true)
        refetch()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setCompleted({
        orderId,
        banking_commissions: false
      })
    }
  })

  const addNewPaymentToCompletedInvoice = () => {

    if (!invoiceBankCommissions?.banking_commissions){
      return
    }

    const currentIndex = invoiceBankCommissions.banking_commissions.length;

    const currentPercent = getValues(`payments.${currentIndex}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`);

    const currentAmount = getValues(`payments.${currentIndex}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`);

    const paymentTitle = currentPercent && currentAmount ? `(${currentPercent}% — ${currentAmount} USD)` : '';

    return (
      <PaymentAccordionContainer
        title={`${t('calc__text__bankCommissions__payment')}-${currentIndex + 1} ${paymentTitle}`}>
        <div className='payment__form'>
          <PaymentCompletedEdit
            onClose={() => setShowAddNewPaymentCompleted(false)}
            invoiceNumber={invoiceNumber}
            noPadding
            invoiceAmount={invoiceAmount}
          />
        </div>
      </PaymentAccordionContainer>
    )
  }

  return (
    <FormProvider {...methods}>
      {isCompletedInvoice && invoiceBankCommissions?.banking_commissions ? (
        <>
          {invoiceBankCommissions.banking_commissions.map((item: BankCommissions, indexItem: number) => (
            <div className='payments__completed' key={indexItem}>
              <PaymentCompleted
                // @ts-ignore
                index={item.id}
                payment={item}
                indexItem={indexItem}
                invoiceNumber={invoiceNumber}
                invoiceAmount={invoiceAmount}
              />
            </div>
          ))}
          {showAddNewPaymentCompleted ? addNewPaymentToCompletedInvoice() : (
            <Button
              variant='outlined'
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => setShowAddNewPaymentCompleted(true)}
              sx={{ marginTop: 4 }}
            >
              {`${t('calc__text__bankCommissions__addPayment')}-${invoiceBankCommissions.banking_commissions.length + 1}` }
            </Button>
          )}
        </>
      ): (
        <>
          {fields.map((field, index) => {

            const isFirstItem = index === 0;

            const currentPercent = getValues(`payments.${index}.${ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE}`);

            const currentAmount = getValues(`payments.${index}.${ADD_PAYMENT_FORM.PAYMENT_AMOUNT}`);

            const paymentTitle = currentPercent && currentAmount ? `(${currentPercent}% — ${currentAmount} USD)` : '';

            return (
              <PaymentAccordionContainer
                key={field.id}
                title={`${t('calc__text__bankCommissions__payment')}-${index + 1} ${paymentTitle}`}
                remove={isFirstItem ? undefined : remove}
                index={index}
              >
                <PaymentItem
                  control={control}
                  index={index}
                  isFirstItem={isFirstItem}
                  invoiceAmount={invoiceAmount}
                />
              </PaymentAccordionContainer>
            )
          })}
          {formIsReady && <Button
            variant='outlined'
            fullWidth
            startIcon={<AddIcon />}
            disabled={!formIsReady}
            onClick={() => {
              append(INITIAL_VALUES_PAYMENT)
            }}
            sx={{ marginTop: 4 }}
          >{`${t('calc__text__bankCommissions__addPayment')}-${fields.length + 1}` }</Button>}
        </>
      )}
      {<Button
        variant='contained'
        fullWidth
        startIcon={<CheckIcon />}
        disabled={!isCompletedInvoice && !formIsReady}
        onClick={onSubmit}
        sx={{ marginTop: 4 }}
      >{`${t('calc__text__bankCommissions__savePayments')} №${invoiceNumber}` }</Button>}
      {openAlert && (
        <AlertBottom
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          message={t('common__message__dataSavedSuccessfully')}
        />
      )}
      {errorText && <Notification
        type={NOTIFICATION_TYPE.WARNING}
        title={t('common__message__warning')}
        text={errorText}
        sx={{ marginTop: 3 }}
        noCloseButton
      />}
    </FormProvider>
  );
}

export default Payments
