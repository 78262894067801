import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import Completed from '../../../../completed';
import 'react-phone-input-2/lib/material.css'
import Invoice from "./components/invoice";
import {CALC_ID, useInvoices, useOrderByIdQuery, useSetCompletedByOrderIdMutation} from 'api/calc'
import Preloader from "../../../../common/preloader";
import { useGetCalcIdFromRoute } from 'utils/hooks'
import EditMainInfo, { ORDER_FORM } from './components/editMain'
import ModalBig from '../../../../common/modalBig';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../../config/constants";

type Props = {
  handleAdd(): void
}

const Main = ({handleAdd} : Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const { data: order, refetch, isSuccess, isError, isLoading } = useOrderByIdQuery(orderId)

  const [ showCompletedForm, setShowCompletedForm ] = useState<boolean | undefined>();

  const [ isLoadedFirstTime, setIsLoadedFirstTime ] = useState(true);

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const invoices = useInvoices();
  const navigate = useNavigate();

  const [ showAddInvoiceFrom, setShowAddInvoiceFrom ] = useState(Boolean(order?.num && !invoices?.length));

  useEffect(() => {
    if (isError && !isLoading) {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`, { replace: true });
    }
  }, [ isLoading ])

  useEffect(() => {
    if (order?.num) {
      setShowCompletedForm(true)
    }
  }, [ order?.num ])

  useEffect(() => {
    if (orderId) {
      localStorage.setItem(CALC_ID, orderId);
      setIsLoadedFirstTime(false)
    } else {
      setIsLoadedFirstTime(true)
    }

  }, [ orderId ])


  const handleSend = () => {
    setShowCompletedForm(true)
    setOpenEditModal(false)
    setShowAddInvoiceFrom(true)
    refetch()
  }

  const onClose = () => {
    setShowCompletedForm(true);
    setOpenEditModal(false)
  }

  const handleComplete = (hasRemoved?: boolean) => {

    // @ts-ignore
    if (!orderId) {
      return
    }

    if (!isLoadedFirstTime) {

      const resetParams = {
        packaging: false,
        delivery: false,
        customs: false,
        additional_services: false
      }

      setCompleted({
        orderId,
        common: hasRemoved? invoices?.length !== 1 : true,
        ...(isSuccess && invoices?.length !== 1 && resetParams)
      })
    }
  }

  const handleRemove = () => {
    if (invoices?.length === 1){
      handleComplete(true);
    }
  }

  const getCompletedInfo = () => {

    const phone = order?.[ORDER_FORM.CONTACT_PHONE];
    const email = order?.[ORDER_FORM.CONTACT_EMAIL];

    return [
      {
        label: t('calc__text__main__nameOfOrder'),
        value: order?.[ORDER_FORM.ORDER_NAME]
      },
      {
        label: t('common__text__contactProfile'),
        value: <>{order?.[ORDER_FORM.CONTACT_NAME]} {order?.[ORDER_FORM.CONTACT_SURNAME]},&nbsp;
          <a href={`tel:+${phone}`}>{phone}</a>,&nbsp;
          <a href={`mailto:${email}`}>{email}</a></>
      }
    ]
  }

  if (!orderId) {
    return <>
      <EditMainInfo onSend={handleSend} />
      {order?.num && <Invoice handleAdd={handleAdd} handleComplete={handleComplete} handleRemove={handleRemove} />}
    </>
  }

  if (showCompletedForm === undefined) {
    return <Preloader />
  }

  return <>
    {showCompletedForm && <Completed
      info={getCompletedInfo()}
      onEdit={() => setOpenEditModal(true)}
      hasTitle
    />}
    {openEditModal && <ModalBig isOpen={openEditModal}
      onClose={onClose}
      title={t('calc__text__main__editMainData')}
    ><EditMainInfo onSend={handleSend} noBorder hasCancelButton onClose={onClose} /></ModalBig>}
    {order?.num && <Invoice
      openByDefault={showAddInvoiceFrom}
      handleAdd={handleAdd}
      handleComplete={handleComplete}
      handleRemove={handleRemove}
    />}
  </>
}

export default Main
