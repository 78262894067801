import { createSlice } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { REHYDRATE } from 'redux-persist'
import { baseQuery } from 'api'
import { useGetCalcIdFromRoute } from 'utils/hooks'
import sortBy from "lodash/sortBy";
import { useSelector } from 'react-redux'
import { rootReducer } from 'store'
import isEmpty from 'lodash/isEmpty'
import {informersApi} from "./informers";

export const CALC_ID = "calcId";

export const getCalcId = () => localStorage.getItem(CALC_ID);

export const calcApi = createApi({
  baseQuery,
  reducerPath: 'calcApi',
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) return action.payload[reducerPath]
  },
  endpoints: (builder) => ({
    createOrder: builder.mutation<OrderParamsOutput, OrderParams>({
      query: (body) => ({ url: `calc-api/v4.0/order/`, method: 'POST', body }),
    }),
    orderById: builder.query<OrderParamsOutput, string>({
      query: (id) => id && ({ url: `calc-api/v4.0/order/${id}/`, method: 'GET' }), // id example 0001289
    }),
    updateOrder: builder.mutation<OrderParamsOutput, UpdateOrderParams>({
      query: ({ id, ...body }) => ({ url: `calc-api/v4.0/order/${id}/`, method: 'POST', body }),
    }),
    deleteOrder: builder.mutation<OrderParamsOutput, RemoveOrderParams>({
      query: (id) => ({ url: `calc-api/v4.0/order/${id}/`, method: 'DELETE' }),
    }),
    createInvoice: builder.mutation<InvoiceParamsOutput, InvoiceParamsInput>({
      query: ({id, ...body}) => ({ url: `calc-api/v4.0/order/${id}/invoice/`, method: 'POST', body }),
    }),
    invoicesByOrderId: builder.query<InvoiceParamsOutput[], string>({
      query: (id) => ({ url: `calc-api/v4.0/order/${id}/invoices/`, method: 'GET' }), // id example 0001289
    }),
    updateInvoice: builder.mutation<InvoiceParamsOutput, InvoiceUpdateParamsInput>({
      query: ({orderId, invoiceId, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/invoice/${invoiceId}/`, method: 'POST', body }),
    }),
    removeInvoice: builder.mutation<InvoiceParamsOutput, InvoiceRemoveParamsInput>({
      query: ({orderId, invoiceId}) => ({ url: `calc-api/v4.0/order/${orderId}/invoice/${invoiceId}/`, method: 'DELETE' }),
    }),
    summaryByOrderId: builder.query<SummaryParamsOutput, string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/packaging/`, method: 'GET' }),
    }),
    saveSummaryByOrderId: builder.mutation<SummaryParamsOutput, UpdateSummaryParamsInput>({
      query: ({orderId, isEmpty, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/packaging/`, method: 'POST', body }),
    }),
    changePrice: builder.query<CalculatePriceOutput, CalculatePriceInput>({
      query: (body) => ({ url: `calc-api/v4.0/calculate/price/`, body, method: 'POST' }),
    }),
    changePlaces: builder.query<CalculatePlacesOutput, CalculatePlacesInput>({
      query: (body) => ({ url: `calc-api/v4.0/calculate/places/`, body, method: 'POST' }),
    }),
    createProduct: builder.mutation<ProductParamsOutput, ProductParamsInput>({
      query: ({invoiceId, ...body}) => ({ url: `calc-api/v4.0/invoice/${invoiceId}/product/`, method: 'POST', body }),
    }),
    productsByInvoiceId: builder.query<ProductParamsOutput[], number>({
      query: (invoiceId) => ({ url: `calc-api/v4.0/invoice/${invoiceId}/products/`, method: 'GET' }),
    }),
    productById: builder.query<ProductParamsOutput, ProductGetParamsInput>({
      query: ({invoiceId, productId}) => ({ url: `calc-api/v4.0/invoice/${invoiceId}/product/${productId}/`, method: 'GET' }),
    }),
    removeProduct: builder.mutation<void, ProductRemoveParamsInput>({
      query: ({ invoiceId, productId }) => ({ url: `calc-api/v4.0/invoice/${invoiceId}/product/${productId}/`, method: 'DELETE' }),
    }),
    updateProduct: builder.mutation<ProductParamsOutput, ProductUpdateParamsInput>({
      query: ({invoiceId, productId, ...body}) => ({ url: `calc-api/v4.0/invoice/${invoiceId}/product/${productId}/`, body, method: 'POST' }),
    }),
    declarationsByOrderId: builder.query<DeclarationsParamsOutput[], string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/declarations/`, method: 'GET' }),
    }),
    declarationsSetCompleted: builder.mutation<DeclarationsParamsOutput[], string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/declarations/completed/`, method: 'POST' }),
    }),
    updateDeclaration: builder.mutation<DeclarationPostParamsOutput, DeclarationPostParamsInput>({
      query: ({orderId, code, country, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/declarations/${country}${code}/`, method: 'POST', body }),
    }),
    calculateDeclaration: builder.mutation<DeclarationCalculatePostParamsOutput, DeclarationPostParamsInput>({
      query: ({orderId, code, country, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/declarations/${country}${code}/calculate/`, method: 'POST', body }),
    }),
    declarationSummaryByOrderId: builder.query<DeclarationSummaryParamsOutput, string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/customs/`, method: 'GET' }),
    }),
    getRoutesById: builder.mutation<DeliveryRouteParamsOutput, DeliveryRouteParamsInput>({
      query: ({ orderId, limit, page, city_name_from, city_name_to, ...body }) => ({ url: `calc-api/v4.0/order/${orderId}/delivery/routes/?limit=${limit || 20}&page=${page || 1}`, method: 'POST', body }),
    }),
    getOrderSummaryById: builder.query<OrderSummaryParamsOutput, string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/summary/`, method: 'GET' }),
    }),
    getOrderSummaryInvoicesById: builder.query<OrderSummaryInvoicesParamsOutput[], string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/summary-invoices/`, method: 'GET' }),
    }),
    chooseRouteByOrderId: builder.mutation<RouteParamsOutput, RouteParamsInput>({
      query: ({orderId, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/delivery/`, method: 'PUT', body }),
    }),
    addServicesToRouteByOrderId: builder.mutation<RouteParamsOutput, RouteParamsUpdateInput>({
      query: ({orderId, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/delivery/`, method: 'POST', body }),
    }),
    getRouteByOrderId: builder.query<Route, string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/delivery/` }),
    }),
    getOrders: builder.mutation<OrdersParamsOutput, OrdersParamsInput>({
      query: ({ limit, page, ordering, min_total_amount, max_total_amount, search }) => ({ url: 'calc-api/v4.0/orders/', params: { limit, page, ordering, min_total_amount, max_total_amount, search } }),
    }),
    cloneCalculation: builder.mutation<OrderParamsOutput, string>({
      query: (id) => ({ url: `calc-api/v4.0/order/${id}/clone/`, method: 'POST'}),
    }),
    getAdditionalServicesByOrderId: builder.query<AdditionalServicesParamsOutput, string>({
      query: (orderId) => ({ url: `calc-api/v4.0/order/${orderId}/additional-services/`, }),
    }),
    setCompletedByOrderId: builder.mutation<CompletedParamsOutput, CompletedParamsOutput>({
      query: ({orderId, ...body}) => ({ url: `calc-api/v4.0/order/${orderId}/completed/`, method: 'POST', body }),
    }),
    updateBankCommissions: builder.mutation<BankCommissions[], BankCommissionsInput>({
      query: ({invoiceId, payments}) => ({ url: `calc-api/v4.0/order/invoice/${invoiceId}/banking_commissions/`, method: 'PUT', body: payments }),
    }),
    getBankCommissionsByOrderId: builder.query<BankCommissionsSummary, ParamsBankCommissionsOrderSummary>({
      query: ({orderId}) => ({ url: `calc-api/v4.0/order/${orderId}/invoices/banking_commissions/` }),
    }),
  }),
})

export const {
  useOrderByIdQuery,
  useInvoicesByOrderIdQuery,
  useProductsByInvoiceIdQuery,
  useLazyProductByIdQuery,
  useLazyChangePriceQuery,
  useLazyChangePlacesQuery,
  useSummaryByOrderIdQuery,
  useDeclarationsByOrderIdQuery,
  useDeclarationSummaryByOrderIdQuery,
  useGetOrderSummaryByIdQuery,
  useGetOrderSummaryInvoicesByIdQuery,
  useGetRouteByOrderIdQuery,
  useGetAdditionalServicesByOrderIdQuery,
  useGetBankCommissionsByOrderIdQuery
} = calcApi

export const calcSlice = createSlice({
  name: 'calcParams',
  initialState: {
    order: {} as OrderParamsOutput,
    orders: {} as OrdersParamsOutput,
    params: {} as UserParams,
    paramsOrder: {} as OrdersParamsInput,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      calcApi.endpoints.createOrder.matchFulfilled,
      (state, { payload }) => {
        state.order = { ...state.order, ...payload}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.orderById.matchFulfilled,
      (state, { payload }) => {
        state.order = { ...state.order, ...payload}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.updateOrder.matchFulfilled,
      (state, { payload }) => {
        state.order = {
          ...state.order,
          ...payload,
          invoices: state.order?.invoices
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.createInvoice.matchFulfilled,
      (state, { payload }) => {
        const invoices = state.order?.invoices
        state.order = {...state.order, invoices: invoices ? [ ...invoices, payload ] : [ payload ]}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.invoicesByOrderId.matchFulfilled,
      (state, { payload }) => {
        state.order = {...state.order, invoices: payload}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.updateInvoice.matchFulfilled,
      (state, { payload }) => {
        const { id } = payload
        const invoices = state.order?.invoices
        const newInvoices = invoices?.map(invoice => invoice.id === id ? payload : invoice)
        state.order = {...state.order, invoices: newInvoices}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.productsByInvoiceId.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        const invoices = state.order?.invoices
        const newInvoices = invoices?.map(invoice => invoice.id === originalArgs ? {
          ...invoice,
          products: payload,
        } : invoice)
        state.order = {...state.order, invoices: newInvoices}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.productById.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {

        const { productId, invoiceId } = originalArgs;

        const invoices = state.order?.invoices

        const getProducts = (products?: ProductParamsOutput[]) => {
          return products?.map(product => product.id === productId ? {
            ...payload,
          } : product)
        }

        const newInvoices = invoices?.map(invoice => invoice.id === invoiceId ? {
          ...invoice,
          products: getProducts(invoice.products),
        } : invoice)

        state.order = {...state.order, invoices: newInvoices}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getOrderSummaryById.matchFulfilled,
      (state, { payload }) => {
        state.order = {
          ...state.order,
          id: payload.id,
          summaryCompleted: payload.completed,
          summaryOrder: {
            ...state.order.summaryOrder,
            // @ts-ignore
            total_amount: payload?.summary?.total_amount,
            total_banking_commissions: payload?.summary?.total_banking_commissions,
            total_currency_control_fees: payload?.summary?.total_currency_control_fees,
            total_payment_amount: payload?.summary?.total_payment_amount,
            total_payment_amount_with_commissions: payload?.summary?.total_payment_amount_with_commissions,
          }
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getOrderSummaryInvoicesById.matchFulfilled,
      (state, { payload }) => {
        state.order = {
          ...state.order,
          summaryInvoices: payload,
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.setCompletedByOrderId.matchFulfilled,
      (state, { payload }) => {
        state.order = {
          ...state.order,
          summaryCompleted: payload
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.summaryByOrderId.matchFulfilled,
      (state, { payload }) => {
        state.order = {
          ...state.order,
          summaryOrder: {
            ...state.order.summaryOrder,
            ...payload,
            containers_user: !isEmpty(payload.containers_manual) ? payload.containers_manual : payload.containers
          },
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.saveSummaryByOrderId.matchFulfilled,
      (state, { payload,  meta: { arg: { originalArgs } }  }) => {
        state.order = {
          ...state.order,
          summaryOrder: originalArgs.isEmpty ? {} : {
            ...state.order.summaryOrder,
            ...payload,
            containers_user:  payload.containers_manual,
          }
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.declarationsByOrderId.matchFulfilled,
      (state, { payload }) => {
        state.order = {...state.order, declarations: payload}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.declarationSummaryByOrderId.matchFulfilled,
      (state, { payload }) => {
        state.order = {...state.order, summaryCustoms: payload }
      }
    )
    builder.addMatcher(
      informersApi.endpoints.getInformers.matchFulfilled,
      (state, { payload }) => {
        const informers = {}
        payload.map((informer) => {
          const code = informer.code;
          // @ts-ignore
          informers[code] = informer.description
        })
        // @ts-ignore
        state.order = {...state.order, informers }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getRoutesById.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        state.order = { ...state.order, routes: payload }
        state.params = { ...state.params, ...originalArgs}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getRouteByOrderId.matchFulfilled,
      (state, { payload  }) => {
        state.order = { ...state.order, route: payload }
        state.params = { ...state.params }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.addServicesToRouteByOrderId.matchFulfilled,
      (state, { payload }) => {
        const routeData = payload.route ? { ...state.order.route } : undefined;
        const newRouteData = {
          ...routeData,
          selected_services: payload.services,
        };
        // @ts-ignore
        state.order = { ...state.order, route: newRouteData }
        state.params = { ...state.params }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getOrders.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } }  }) => {
        state.orders = payload
        state.paramsOrder = {
          ...state.paramsOrder,
          ...originalArgs,
        }
      }
    )
    builder.addMatcher(
      calcApi.endpoints.cloneCalculation.matchFulfilled,
      (state, { payload }) => {
        state.order = { ...state.order, ...payload}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getAdditionalServicesByOrderId.matchFulfilled,
      (state, { payload }) => {
        state.order = {...state.order, additionalServices: payload}
      }
    )
    builder.addMatcher(
      calcApi.endpoints.getBankCommissionsByOrderId.matchFulfilled,
      (state, { payload, meta: { arg: { originalArgs } } }) => {

        // @ts-ignore
        if (originalArgs.isChanging){
          if (!state.order.bankCommissions){
            return;
          }

          const getBankCommissions = (invoice: BankCommissionsInvoice) => {
            if (originalArgs.isRemove) {
              // @ts-ignore
              return invoice.banking_commissions.filter(item => item.id !== payload.id)
            }

            // @ts-ignore
            if (payload.id){
              return invoice.banking_commissions.map((bankingCommission) =>
              // @ts-ignore
                bankingCommission.id === payload.id ? payload: bankingCommission
              )
            }

            // @ts-ignore
            if (payload.indexItem){
              return invoice.banking_commissions.map((bankingCommission, index) =>
              // @ts-ignore
                index === payload.indexItem ? payload: bankingCommission
              )
            }

            return [ ...invoice.banking_commissions, payload ]
          }
          const newBankCommissions = state.order.bankCommissions?.invoices?.map(
            invoice => invoice.invoice_num === originalArgs.invoiceNumber ? {
              ...invoice,
              banking_commissions: getBankCommissions(invoice),
            } : invoice)

          state.order = {
            ...state.order,
            // @ts-ignore
            bankCommissions: {
              ...state.order.bankCommissions,
              invoices: newBankCommissions
            }
          }
        } else {
          state.order = {...state.order, bankCommissions: payload}
        }
      }
    )
  },
})

export const useCreateOrderMutation = () => {
  return calcApi.useCreateOrderMutation({ fixedCacheKey: 'createOrder' })
}

export const useUpdateOrderMutation = () => {
  return calcApi.useUpdateOrderMutation({ fixedCacheKey: 'updateOrder' })
}

export const useDeleteOrderMutation = () => {
  return calcApi.useDeleteOrderMutation({ fixedCacheKey: 'deleteOrder' })
}

export const useCreateInvoiceMutation = () => {
  return calcApi.useCreateInvoiceMutation({ fixedCacheKey: 'createInvoice' })
}

export const useUpdateInvoiceMutation = () => {
  return calcApi.useUpdateInvoiceMutation({ fixedCacheKey: 'updateInvoice' })
}

export const useRemoveInvoiceMutation = () => {
  return calcApi.useRemoveInvoiceMutation({ fixedCacheKey: 'removeInvoice' })
}

export const useCreateProductMutation = () => {
  return calcApi.useCreateProductMutation({ fixedCacheKey: 'createProduct' })
}

export const useRemoveProductMutation = () => {
  return calcApi.useRemoveProductMutation({ fixedCacheKey: 'removeProduct' })
}

export const useUpdateProductMutation = () => {
  return calcApi.useUpdateProductMutation({ fixedCacheKey: 'updateProduct' })
}

export const useGetRoutesMutation = () => {
  return calcApi.useGetRoutesByIdMutation({ fixedCacheKey: 'getRoutesById' })
}

export const useUpdateSummaryByOrderIdMutation = () => {
  return calcApi.useSaveSummaryByOrderIdMutation({ fixedCacheKey: 'saveSummaryByOrderId' })
}

export const useUpdateDeclarationMutation = () => {
  return calcApi.useUpdateDeclarationMutation({ fixedCacheKey: 'updateDeclaration' })
}

export const useCalculateDeclarationMutation = () => {
  return calcApi.useCalculateDeclarationMutation({ fixedCacheKey: 'calculateDeclaration' })
}

export const useDeclarationsSetCompletedMutation = () => {
  return calcApi.useDeclarationsSetCompletedMutation({ fixedCacheKey: 'declarationsSetCompleted' })
}

export const useChooseRouteByOrderIdMutation = () => {
  return calcApi.useChooseRouteByOrderIdMutation({ fixedCacheKey: 'chooseRouteByOrderId' })
}

export const useAddServicesToRouteByOrderIdMutation = () => {
  return calcApi.useAddServicesToRouteByOrderIdMutation({ fixedCacheKey: 'addServicesToRouteByOrderId' })
}

export const useGetOrdersMutation = () => {
  return calcApi.useGetOrdersMutation({ fixedCacheKey: 'getOrders' })
}

export const useCloneCalculationMutation = () => {
  return calcApi.useCloneCalculationMutation({ fixedCacheKey: 'cloneCalculation' })
}

export const useSetCompletedByOrderIdMutation = () => {
  return calcApi.useSetCompletedByOrderIdMutation({ fixedCacheKey: 'setCompletedByOrderId' })
}

export const useUpdateBankCommissionsMutation = () => {
  return calcApi.useUpdateBankCommissionsMutation({ fixedCacheKey: 'updateBankCommissions' })
}

export const useGetOrder = () => {
  const orderId = useGetCalcIdFromRoute()
  return useOrderByIdQuery(orderId, { skip: !orderId })
}

export const useGetInvoices = () => {
  const orderId = useGetCalcIdFromRoute()
  const invoices = useInvoicesByOrderIdQuery(orderId, { skip: !orderId })?.data
  return sortBy(invoices, 'id')
}

export const useInvoicesData = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.invoices)
}

export const useSummary = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.summaryOrder)
}

export const useParamsData = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.params)
}

export const useOrderParamsData = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.paramsOrder)
}

export const useUpdateSummaryByOrderId = ({orderId, summary} : SummaryParamsUpdate) => {
  return calcApi.util.upsertQueryData('summaryByOrderId', orderId, summary)
}

export const useUpdateProductById = (data: ProductParamsOutput) => {
  return calcApi.util.upsertQueryData('productById', {invoiceId: data.invoice_id, productId: data.id}, data)
}

export const useUpdateBankCommissionsByInvoiceId = ({ orderId, data, invoiceNumber, isRemove}: ParamsBankCommissionsOrderSummary) => {
  const args = {
    orderId,
    invoiceNumber,
    isChanging: true,
    isRemove,
  }
  // @ts-ignore
  return calcApi.util.upsertQueryData('getBankCommissionsByOrderId', args, data)
}

export const useResetOrderId = (orderId: string) => {
  // @ts-ignore
  return calcApi.util.upsertQueryData('orderById', orderId, {
    id: undefined,
    num: undefined,
    created_at: undefined,
    updated_at: undefined,
    status : undefined,
    invoices: undefined,
    declarations: undefined,
    summaryCustoms: {},
    summaryOrder: {
      // @ts-ignore
      total_amount: undefined,
      total_payment_amount: undefined,
      total_banking_commissions: undefined,
      total_currency_control_fees: undefined,
      total_payment_amount_with_commissions: undefined
    },
    summaryInvoices: undefined,
    summaryCompleted: {
      common: false,
      packaging: false,
      delivery: false,
      customs: false,
      additional_services: false
    },
    additionalServices: undefined,
    routes: { results: [] },
    route: undefined,
  })
}


export const useOrder = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order)
}

export const useSummaryOrder = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.summaryOrder)
}

export const useSummaryCustoms = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.summaryCustoms)
}

export const useSummaryCompleted = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.summaryCompleted)
}

export const useSummaryInvoices = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.summaryInvoices)
}

export const useInvoices = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.invoices)
}

export const useBankCommissions = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.bankCommissions);
}

export const useBankCommissionsByInvoiceId = (id: string) => {
  const bankCommissions = useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.bankCommissions?.invoices);
  return bankCommissions?.find((item: BankCommissionsInvoice) => item.invoice_num === id);
}

export const useCurrentRoute = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.route)
}

export const useAdditionalServices = () => {
  return useSelector(({ calcParams }: ReturnType<typeof rootReducer>) => calcParams.order.additionalServices)
}
