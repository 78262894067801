import {useTranslation} from "react-i18next";
import { CONTAINERS_FORM, METHODS} from "../../cabinet/calculator/components/packaging/components/summary";

type Props = {
  summaryOrder: SummaryParamsOutput | Record<string, never>
}

const SummaryContainers = ({summaryOrder} : Props) => {

  const { t } = useTranslation();

  if (summaryOrder.delivery_type === METHODS.COMBINED_CARGO){
    return (<span>
      {`${t('calc__text__delivery__airfreight')} — ${summaryOrder?.volume_weight.avia} ${t('units__text__kg')},
          ${t('calc__text__delivery__seafreight')} — ${summaryOrder?.volume_weight.sea} ${t('units__text__kg')},
          ${t('calc__text__delivery__trucking')} — ${summaryOrder?.volume_weight.auto} ${t('units__text__kg')},
          ${t('calc__text__delivery__railwayFreight')} — ${summaryOrder?.volume_weight.rails} ${t('units__text__kg')}`}
    </span>)

  } else if (summaryOrder.delivery_type === METHODS.CONTAINERS){
    const ft20 = `20 Ft — ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20]} ${t('units__text__items')}`
    const ft40 = `40 Ft — ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40]} ${t('units__text__items')}`
    const ft40hc = `40 Ft High cube — ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE]} ${t('units__text__items')}`

    return <span>
      {`${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20] ? `${ft20}${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40] || summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] ? ', ': ''}` : ''} 
      ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40] ? `${ft40}${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] ? ', ': ''}`  : ''}
      ${summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] ? ft40hc : ''}`}
    </span>
  }
  return null;
}

export default SummaryContainers;
