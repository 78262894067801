import Main from './components/main';
import Packaging from './components/packaging';
import Delivery from './components/delivery';
import Customs from './components/customs';
import Additional from './components/additional';
import Summary from './components/summary';
import BankCommission from './components/bank-commission';

import {useCurrentRoute, useGetOrderSummaryByIdQuery, useResetOrderId, useSummaryCompleted} from 'api/calc'

import Template from './components/template';

import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import './../cabinet.scss'
import './calculator.scss'
import {
  CALC_ID,
  getCalcId,
  useSummaryCustoms
} from "../../../api/calc";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getPluralForm} from "../../../utils/validation";
import RightPanel from "./components/right-panel";
import Modal from "./components/modal";

import {useLazyGetInformersQuery} from "../../../api/informers";
import {useGetCalcIdFromRoute} from "../../../utils/hooks";
import {ROUTES} from "../../../config/constants";
import ModalBig from "../../common/modalBig";
import {useDispatch} from "react-redux";

const Calculator = () => {

  const { t } = useTranslation()

  const [ shouldOpenContinueModal, setShouldOpenContinueModal ] = useState(false)

  const orderId = useGetCalcIdFromRoute()

  window.onpopstate = () => setShouldOpenContinueModal(true);

  const [ informersRequest ] = useLazyGetInformersQuery()

  const { refetch } = useGetOrderSummaryByIdQuery(orderId, { skip: !orderId })

  const navigate = useNavigate();

  const dispatch = useDispatch()

  const calcId = getCalcId();

  const summaryCustoms = useSummaryCustoms()
  const summaryCompleted = useSummaryCompleted()
  const currentRoute = useCurrentRoute()?.id

  const [ expandedSteps, setExpandedSteps ] = useState({
    main: false,
    packaging: false,
    delivery: false,
    customs: false,
    additionalServices: false,
    banking_commissions: false,
  })

  useEffect(() => {
    if (calcId && !orderId) {
      setShouldOpenContinueModal(true);
    }
    informersRequest('');
    if (orderId){
      refetch();
    }
  }, [])

  useEffect(() => {
    if (summaryCompleted) {
      setExpandedSteps({
        main: Boolean(!summaryCompleted?.common),
        packaging: Boolean(summaryCompleted?.common && !summaryCompleted?.packaging),
        delivery: Boolean(summaryCompleted?.packaging && !summaryCompleted?.delivery),
        customs: Boolean(summaryCompleted?.delivery && !summaryCompleted?.customs),
        banking_commissions: Boolean(summaryCompleted?.banking_commissions),
        additionalServices: Boolean(summaryCompleted?.customs && !summaryCompleted?.additional_services)
      })
    }
  }, [ summaryCompleted ])



  const CUSTOMS_DECLENSIONS: PluralForms = [ t('declension__text__declarationOne'), t('declension__text__declarationSeveral'), t('declension__text__declarationMany') ];
  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];

  const handleGoToCalculation = () => {
    setShouldOpenContinueModal(false)
    navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}/${calcId}`)
  }

  const handleGoToNewCalculation = () => {
    navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`)
    localStorage.removeItem(CALC_ID);
    // @ts-ignore
    dispatch(useResetOrderId(orderId));
    setShouldOpenContinueModal(false)
  }

  const shouldShowSummary = summaryCompleted && Object.values(summaryCompleted).every(item => item)

  const handleChangeExpandedSteps = (data: any) => {
    setExpandedSteps({
      ...expandedSteps,
      ...data,
    })
  }

  return (
    <>
      <div className='wrapper calculator'>
        <Typography variant='h4' sx={{
          marginBottom: 5
        }} id='title'>
          {t('calc__text__shippingCalculator')}
        </Typography>
        <Template
          text={t('calc__text__step1')}
          isCompleted={summaryCompleted?.common}
          inProgress={Boolean(orderId)} id='main'
          isExpanded={expandedSteps.main}
        >
          <Main handleAdd={() => handleChangeExpandedSteps({main: true, packaging: true})} />
        </Template>
        <Template
          text={t('calc__text__step2')}
          isCompleted={summaryCompleted?.packaging}
          id='packaging'
          isDisabled={!summaryCompleted?.common}
          isExpanded={expandedSteps.packaging}
        >
          <Packaging handleComplete={() =>  handleChangeExpandedSteps({packaging: true, delivery: true})}/>
        </Template>
        <Template
          text={t('calc__text__step3')}
          isCompleted={summaryCompleted?.delivery}
          id='delivery'
          isDisabled={!summaryCompleted?.packaging}
          isExpanded={expandedSteps.delivery}
        >
          <Delivery handleChoose={() =>  handleChangeExpandedSteps({delivery: true, customs: true})}/>
        </Template>
        <Template
          text={`${t('calc__text__step4')}${summaryCustoms?.declaration_count ? ` — ${summaryCustoms?.declaration_count} ${getPluralForm(summaryCustoms?.declaration_count, CUSTOMS_DECLENSIONS)}` : ''}  ${summaryCustoms?.product_count ? `(${summaryCustoms?.product_count} ${getPluralForm(summaryCustoms?.product_count, PRODUCTS_DECLENSIONS)})` : ''}`}
          isCompleted={summaryCompleted?.customs}
          id='customs'
          isDisabled={!summaryCompleted?.packaging}
          isExpanded={expandedSteps.customs}
        >
          {summaryCompleted?.packaging && <Customs
            handleComplete={() =>  handleChangeExpandedSteps({customs: true, invoices: true})}
          />}
        </Template>
        <Template
          text={t('calc__text__step5')}
          id='invoices'
          isCompleted={summaryCompleted?.banking_commissions}
          isDisabled={!summaryCompleted?.customs}
          isExpanded={expandedSteps.banking_commissions}
        >
          {summaryCompleted?.customs && <BankCommission
            handleComplete={() => handleChangeExpandedSteps({invoices: true, additional_services: true})}
          />}
        </Template>
        <Template
          text={t('calc__text__stepAdditional')}
          id='additional'
          isCompleted={summaryCompleted?.additional_services}
          isDisabled={!summaryCompleted?.delivery}
          isExpanded={expandedSteps.additionalServices}
        >
          {currentRoute && <Additional
            handleComplete={() => handleChangeExpandedSteps({additionalServices: true})}
          />}
        </Template>
        {shouldShowSummary && <Summary/>}
      </div>
      <RightPanel />
      {shouldOpenContinueModal && calcId && <ModalBig
        isOpen={shouldOpenContinueModal}
        onClose={() => setShouldOpenContinueModal(false)}
        title={t('calc__text__modal__continuationOfTheCalculation')}
      >
        <Modal
          handleYes={handleGoToCalculation}
          handleNo={handleGoToNewCalculation}
          id={calcId}
        />
      </ModalBig>}
    </>
  )
}

export default Calculator
