import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import './add_form.scss'
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm, FormProvider } from "react-hook-form";
import PackageItem from '../package_item'
import CommonPackage from '../common_package'
import cx from "classnames";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CommonInvoice from "../common_invoice";

import {
  useCreateProductMutation, useLazyProductByIdQuery, useSummaryByOrderIdQuery, useUpdateProductMutation
} from "../../../../../../../../../api/calc";
import get from "lodash/get";
import Notification, {NOTIFICATION_TYPE} from "../../../../../../../../common/notification";
import CommonFields from './../common_fields'
import PalletesFields from '../palletes_fields'

import {
  getCommonFields,
  getCountries,
  getExciseItemFields,
  getPackageItemFields, getPalletFields,
  PACKAGE_FORM,
  PackageForm
} from "./helpers";
import RowCode from "../row_code";
import { useLazyGetCountryQuery } from "../../../../../../../../../api/dictionary";
import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";

type Props = {
  invoice: InvoiceParamsOutput
  count?: number
  id?: number
  invoiceId?: number
  insideModal?: boolean
  handleSuccess(): void
  onCancel(): void
}

type OptionData = {
  code: string
  label: string
  id: string
}

const AddForm = ( { onCancel, invoice, handleSuccess, count, id, invoiceId, insideModal } : Props ) => {

  const { t } = useTranslation()

  const [ showErrorNotification, setShowErrorNotification ] = useState<boolean | string | undefined>(false);

  const [ isCommonPackage, setIsCommonPackage ] = useState(false);

  const orderId = useGetCalcIdFromRoute()

  const { refetch: refetchSummary } = useSummaryByOrderIdQuery(orderId)

  const [ defaultCountry, setDefaultCountry ] = useState<OptionData>()

  const [ isAdditional, setIsAdditional ] = useState(false);

  const [ hasPalletes, setHasPalletes ] = useState(false);

  const methods = useForm<PackageForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { register, getValues, trigger, handleSubmit, clearErrors, setError, reset, formState: { isValid, errors } } = methods

  useEffect(() => {
    if (id) {
      productRequest({
        invoiceId: invoice.id,
        productId: id,
      }).then((productData) => {

        // @ts-ignore
        reset(productData.data)
        setIsCommonPackage(Boolean(productData.data?.[PACKAGE_FORM.VOLUME_MANUAL]))
        setIsAdditional(Boolean(productData.data?.[PACKAGE_FORM.EXCISE_RATE]?.description))
        setHasPalletes(Boolean(productData.data?.[PACKAGE_FORM.PALLETE_TYPE]))

        const countryCode = productData.data?.country_code;

        if (countryCode) {
          countriesRequest({search: countryCode})?.then((response) => {
            // @ts-ignore
            response?.data && setDefaultCountry(getCountries(response?.data).find(country => country.code === countryCode))
          });
        }

      })

    }
  }, [ id ])


  const [ productRequest ] = useLazyProductByIdQuery()

  const [ countriesRequest ] = useLazyGetCountryQuery()

  // const defaultCountryCode = getValues()[PACKAGE_FORM.COUNTRY_CODE]

  // useEffect(() => {
  //   console.log(defaultCountryCode);
  //   if (defaultCountryCode) {
  //     countriesRequest({search: defaultCountryCode})?.then((response) => {
  //       response?.data && setDefaultCountry(getCountries(response.data).find(country => country.code === defaultCountryCode))
  //     });
  //   }
  // }, [ defaultCountryCode ])

  const [ createProduct ] = useCreateProductMutation()

  const [ updateProduct ] = useUpdateProductMutation()

  const checkErrors = (isSuccess: boolean, field: any, errors?: any) => {
    if (isSuccess) {
      clearErrors(field)
      return
    }

    errors[field]?.[0] ? setError(field, { type: 'custom', message: errors[field]?.[0]} ) : clearErrors(field)

  }

  const handleSend = async(values: PackageForm) => {

    const commonFields = getCommonFields(values)

    const packageItemFields = getPackageItemFields(values)

    const commonPackageFields = {
      [PACKAGE_FORM.VOLUME_MANUAL]: values[PACKAGE_FORM.VOLUME_MANUAL],
      [PACKAGE_FORM.NET_WEIGHT_MANUAL]: values[PACKAGE_FORM.NET_WEIGHT_MANUAL],
      [PACKAGE_FORM.GROSS_WEIGHT_MANUAL]: values[PACKAGE_FORM.GROSS_WEIGHT_MANUAL],
    }

    const dataToSend = {
      ...commonFields,
      ...(isCommonPackage && commonPackageFields),
      ...(!isCommonPackage && packageItemFields),
      ...(isAdditional && getExciseItemFields(values)),
      ...(hasPalletes && getPalletFields(values)),
    }

    if (!hasPalletes) {
      delete dataToSend[PACKAGE_FORM.PALLETE_TYPE];
      delete dataToSend[PACKAGE_FORM.PALLETE_CNT];
      delete dataToSend[PACKAGE_FORM.PALLETE_HEIGHT];
      delete dataToSend[PACKAGE_FORM.PALLETE_WEIGHT];
      delete dataToSend[PACKAGE_FORM.TWO_TIERS_ALLOWED];
    }

    if (!isAdditional) {
      delete dataToSend[PACKAGE_FORM.EXCISE_VALUE];
      delete dataToSend[PACKAGE_FORM.EXCISE_RATE];
    }

    const result = id ?
      await updateProduct({ invoiceId: invoice.id, productId : id, ...dataToSend})
      : await createProduct({ invoiceId: invoice.id, ...dataToSend})

    // @ts-ignore
    const errorData = result?.error?.data
    const productId = get(result, 'data.id', '')

    checkErrors(Boolean(productId), PACKAGE_FORM.LENGTH, errorData)
    checkErrors(Boolean(productId), PACKAGE_FORM.WIDTH, errorData)
    checkErrors(Boolean(productId), PACKAGE_FORM.HEIGHT, errorData)
    checkErrors(Boolean(productId), PACKAGE_FORM.NET_WEIGHT_CARTOON, errorData)
    checkErrors(Boolean(productId), PACKAGE_FORM.GROSS_WEIGHT_CARTOON, errorData)

    if (!productId || errorData?.non_field_errors) {
      setShowErrorNotification(errorData?.non_field_errors?.[0] || true);
      return
    }
    setShowErrorNotification(false);
    handleSuccess()
    refetchSummary()
    reset()
  }

  const checkIsValid = () => {
    return isAdditional ? getValues(PACKAGE_FORM.EXCISE_RATE) && (getValues(PACKAGE_FORM.EXCISE_VALUE) || getValues(PACKAGE_FORM.EXCISE_VALUE) === 0) && isValid: isValid;
  }

  const onSubmit = handleSubmit(values => handleSend(values))

  return <>
    <div className={cx({
      'add-product': true,
      'add-product_padding': id,
    })}>
      {id && <CommonInvoice index={invoiceId} invoice={invoice} />}
      <Divider/>
      <Typography variant='h6' sx={{ marginTop: 3 }}>{t('common__text__addItem')} {count || 1}</Typography>
      <FormProvider {...methods}>
        <form className={cx({
          'add-product__form': true,
          'add-product__form_isCommon': isCommonPackage,
          'add-product__form_hasPalletes': hasPalletes,
          'add-product__form_isAdditional': isAdditional,
        })} onSubmit={onSubmit}>
          <CommonFields />
          <FormControlLabel
            className={`add-product__common_package`}
            checked={isCommonPackage}
            control={<Switch
              color='secondary'
            />}
            onChange={(event, checked) => setIsCommonPackage(checked)}
            label={t('common__text__totalDimensionsOfTheLoad')}
            sx={{ marginLeft: 0}}
          />
          <FormControlLabel
            className={`add-product__${PACKAGE_FORM.PALLETES}`}
            {...register(PACKAGE_FORM.PALLETES)}
            control={<Checkbox checked={hasPalletes} />}
            onChange={() => {
              setHasPalletes(!hasPalletes);
              trigger([ PACKAGE_FORM.PALLETES ])
            }}
            label={t('calc__text__packaging__calculationByPallet')}
          />
          {isCommonPackage ? <CommonPackage /> : <PackageItem />}
          {id && defaultCountry && <RowCode defaultCountry={defaultCountry} setAdditional={(value) => setIsAdditional(value)} isAdditional={isAdditional} id={id} invoiceId={invoice.id} insideModal={insideModal}/>}
          {!id && <RowCode setAdditional={(value) => setIsAdditional(value)} isAdditional={isAdditional} id={id} invoiceId={invoice.id} insideModal={insideModal} />}
          {hasPalletes && <PalletesFields />}
          <div className='add-product__row-buttons'>
            <Button
              variant='outlined'
              fullWidth
              onClick={onCancel}
            >{t('actions__text__cancel')}</Button>
            <Button
              variant='contained'
              fullWidth
              type='submit'
              startIcon={<CheckIcon />}
              disabled={!checkIsValid()}
              className='add-product__addButton'
            >{`${t('actions__text__save')} ${t('common__text__addItem')}-${ count || 1 }`}</Button>
          </div>
        </form>
      </FormProvider>
      {showErrorNotification &&
          <Notification
            type={NOTIFICATION_TYPE.ERROR}
            title={t('errors__message__save')}
            text={typeof showErrorNotification === 'string' ?  showErrorNotification : t('errors__message__tryLater5Min')}
            sx={{ marginTop: 4 }}/>}
    </div>
  </>
}

export default AddForm
