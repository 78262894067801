import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import './modalSmall.scss'

import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography";
import {ReactNode} from "react";

type Props = {
    onContinue: (index?: number | string) => void,
    onClose: () => void;
    isOpen: boolean,
    index?: number | string,
    buttonYesText: string,
    title?: string,
    description: ReactNode
}

const PackageModal = ({ onContinue, isOpen, index, onClose, title, description, buttonYesText }: Props) => {

  const { t } = useTranslation()

  const handleClickYes = () => index ? onContinue(index) : onContinue()

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-common-title'
      aria-describedby='modal-common-description'
    >
      <div className='modal-common'>
        {title && <Typography variant='h6' sx={{marginBottom: '32px'}}>{title}</Typography>}
        <Typography variant='body1' className='modal-common__description'>{description}</Typography>
        <div className='modal-common__actions'>
          <Button
            className='modal-common__actions_cancel'
            onClick={onClose}
            color='secondary'
            sx={{ letterSpacing: '0.4px', marginRight: 1 }}
          >
            {t('actions__text__cancel')}
          </Button>
          <Button
            className='modal-common__actions_confirm'
            onClick={handleClickYes}
            type='submit'
            color='error'
            sx={{ letterSpacing: '0.4px'}}
          >
            {buttonYesText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default PackageModal
