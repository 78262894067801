import React, { ReactNode, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import COLORS from 'styles/colors.scss'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './modalBig.scss'
import cx from "classnames";

type Props = {
    onClose: () => void;
    isOpen: boolean,
    title: string,
    children: ReactNode,
    isSearch?: boolean,
    insideModal?: boolean
}

const ModalBig = ({ isOpen, onClose, title, children, isSearch, insideModal }: Props) => {

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll='body'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      maxWidth={ isSearch ? 'xl' : false}
      sx={{
        '& .MuiDialog-paperScrollBody': {
          maxWidth: insideModal ? '688px': '752px',
        },
      }}
    >
      <DialogTitle className='title' sx={{ lineHeight: 1.2 }}>
        {title}
        <IconButton color={COLORS.LIGHT_GRAY} sx={{ padding: 0}} onClick={onClose}>
          <CloseIcon sx={{ width: '28px', height: '28px' }}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{
        background: isSearch ? COLORS.WHITE: 'auto',
        padding: isSearch ? '0 !important': '32px 24px'
      }}>
        <div className={cx({
          'children': true,
          'children_isSearch': isSearch,
        })}>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalBig
