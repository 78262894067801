import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField'
import { useForm } from "react-hook-form";
import cx from 'classnames'
import ru from 'react-phone-input-2/lang/ru.json'
import PhoneInput, {CountryData} from 'react-phone-input-2'
import Button from '@mui/material/Button';
import 'react-phone-input-2/lib/material.css'
import CheckIcon from '@mui/icons-material/Check';
import get from "lodash/get";
import {
  useCreateOrderMutation,
  useGetOrder,
  useUpdateOrderMutation,
} from 'api/calc'

import { useNavigate } from "react-router-dom";
import Notification, {NOTIFICATION_TYPE} from '../../../../../../common/notification'
import './editMainInfo.scss'
import {useUser} from "../../../../../../../api/auth";
import {EMAIL_PATTERN, RUSSIA_COUNTRY_NAME, RUSSIA_PHONE_NUMBER_REGEXP} from "../../../../../../../utils/validation";

export enum ORDER_FORM {
  ORDER_NAME = 'order_name',
  CONTACT_NAME = 'contact_name',
  CONTACT_SURNAME = 'contact_surname',
  CONTACT_EMAIL = 'contact_email',
  CONTACT_PHONE = 'contact_phone',
}

export type OrderParams = {
  [ORDER_FORM.ORDER_NAME]: string
  [ORDER_FORM.CONTACT_NAME]: string
  [ORDER_FORM.CONTACT_SURNAME]: string
  [ORDER_FORM.CONTACT_EMAIL]: string
  [ORDER_FORM.CONTACT_PHONE]: string
}

type Props = {
    onSend(): void
    noBorder?: boolean
    hasCancelButton?: boolean
    onClose?(): void
}

const EditMainInfo = ({ onSend, noBorder, hasCancelButton, onClose } : Props) => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data: order } = useGetOrder()

  const user = useUser()

  const [ showErrorNotification, setShowErrorNotification ] = useState<boolean | undefined>(false);

  const [ createOrder ] = useCreateOrderMutation()

  const [ updateOrder ] = useUpdateOrderMutation()

  const defaultValues = {
    [ORDER_FORM.ORDER_NAME]: order?.[ORDER_FORM.ORDER_NAME],
    [ORDER_FORM.CONTACT_NAME]: order?.[ORDER_FORM.CONTACT_NAME],
    [ORDER_FORM.CONTACT_SURNAME]: order?.[ORDER_FORM.CONTACT_SURNAME],
    [ORDER_FORM.CONTACT_EMAIL]: order?.[ORDER_FORM.CONTACT_EMAIL],
    [ORDER_FORM.CONTACT_PHONE]: order?.[ORDER_FORM.CONTACT_PHONE],
  };

  const { register, handleSubmit, getValues, setValue, reset, clearErrors, setError, formState: { errors, isValid } } = useForm<OrderParams>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (order?.num) {
      reset(defaultValues)
    }
  }, [ order?.num ])

  useEffect(() => {
    if (user?.first_name && !defaultValues[ORDER_FORM.CONTACT_NAME]) {
      setValue(ORDER_FORM.CONTACT_NAME, user.first_name)
    }

    if (user?.last_name && !defaultValues[ORDER_FORM.CONTACT_SURNAME]) {
      setValue(ORDER_FORM.CONTACT_SURNAME, user.last_name)
    }

    if (user?.email && !defaultValues[ORDER_FORM.CONTACT_EMAIL]) {
      setValue(ORDER_FORM.CONTACT_EMAIL, user.email)
    }

    if (user?.phone && !defaultValues?.[ORDER_FORM.CONTACT_PHONE]) {
      setValue(ORDER_FORM.CONTACT_PHONE, user.phone)
    }
  }, [ user ])


  const handleValidatePhoneNumberRequired = () => {
    if (!getValues(ORDER_FORM.CONTACT_PHONE)) {
      setError(ORDER_FORM.CONTACT_PHONE, { type: "custom",  message: t('common__text__isRequired') })
    }
    return Boolean(getValues(ORDER_FORM.CONTACT_PHONE));
  }

  // @ts-ignore
  const handlePhoneChange = (value: string, country, e, formattedValue: string) => {
    setValue(ORDER_FORM.CONTACT_PHONE, formattedValue)
  }

  const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>, data: CountryData) => {
    let {value} = event.target;
    value = value.replace(/[+()-/ ]/g, "");

    if (data.name === RUSSIA_COUNTRY_NAME) {
      value.match(RUSSIA_PHONE_NUMBER_REGEXP) ? clearErrors(ORDER_FORM.CONTACT_PHONE) : setError(ORDER_FORM.CONTACT_PHONE, { type: "custom",  message: t('errors__message__incorrectPhoneError') });
    }
  }

  const onSubmit = handleSubmit(async values => {
    if (!handleValidatePhoneNumberRequired) return

    if (order?.num) {
      const result = await updateOrder({ id: order?.num, ...values})
      const id = get(result, 'data.num', '')
      if (!id) {
        setShowErrorNotification(true);
        return
      }
      onSend();
      setShowErrorNotification(false);
      return
    }

    const result = await createOrder(values)
    const id = get(result, 'data.num', '')
    if (!id) {
      setShowErrorNotification(true);
      return
    }
    onSend()
    setShowErrorNotification(false);
    if (id) navigate(`${id}` )

  }, () => handleValidatePhoneNumberRequired())

  return (
    <>
      <form
        id='main'
        className='main-form'
        onSubmit={onSubmit}
      >
        <div className={cx({
          'calculator__group': true,
          'calculator__group_noBorder': noBorder,
        })}>
          <TextField
            {...register(ORDER_FORM.ORDER_NAME, { required: t('common__text__isRequired') })}
            fullWidth
            error={Boolean(errors[ORDER_FORM.ORDER_NAME])}
            helperText={errors[ORDER_FORM.ORDER_NAME]?.message}
            variant='outlined'
            label={t('calc__text__main__nameOfOrder')}
            InputLabelProps={{ shrink: true }}
            id={ORDER_FORM.ORDER_NAME}
          />
          <Typography variant='h6' className='main-form__group-title' sx={{ marginTop: 3, marginBottom: 3 }}>{t('common__text__contactProfile')}</Typography>
          <div className='calculator__container'>
            <TextField
              {...register(ORDER_FORM.CONTACT_NAME, { required: t('common__text__isRequired') })}
              error={Boolean(errors[ORDER_FORM.CONTACT_NAME])}
              helperText={errors[ORDER_FORM.CONTACT_NAME]?.message}
              variant='outlined'
              label={t('common__text__firstName')}
              InputLabelProps={{ shrink: true }}
              id={ORDER_FORM.CONTACT_NAME}
            />
            <TextField
              {...register(ORDER_FORM.CONTACT_SURNAME, { required: t('common__text__isRequired') })}
              error={Boolean(errors[ORDER_FORM.CONTACT_SURNAME])}
              helperText={errors[ORDER_FORM.CONTACT_SURNAME]?.message}
              variant='outlined'
              label={t('common__text__lastName')}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...register(ORDER_FORM.CONTACT_EMAIL, { required: t('common__text__isRequired'), pattern: { value: EMAIL_PATTERN, message: t('errors__message__incorrectEmail') } })}
              error={Boolean(errors[ORDER_FORM.CONTACT_EMAIL])}
              helperText={errors[ORDER_FORM.CONTACT_EMAIL]?.message}
              variant='outlined'
              label={t('common__text__email')}
              InputLabelProps={{ shrink: true }}
            />
            <div className='main-form__phone'>
              <PhoneInput
                country={'ru'}
                value={getValues(ORDER_FORM.CONTACT_PHONE)}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
                // @ts-ignore
                isValid={!errors[ORDER_FORM.CONTACT_PHONE]}
                localization={ru}
                specialLabel={t('common__text__phone')}
                inputClass='main-form__phone-input'
              />
              <span className='main-form__phone-error'>{errors[ORDER_FORM.CONTACT_PHONE]?.message}</span>
            </div>
          </div>
          <div className='main-form__buttons'>
            <Button
              variant='contained'
              fullWidth={!hasCancelButton}
              type='submit'
              startIcon={<CheckIcon />}
              disabled={!isValid}
              sx={{ minWidth: hasCancelButton ? '416px': 'auto'}}
            >{t('actions__text__saveMainData')}</Button>
            {hasCancelButton && <Button
              variant='outlined'
              sx={{ minWidth: '196px', marginLeft: 3}}
              onClick={onClose}
            >{t('actions__text__cancel')}</Button>}
          </div>
          {showErrorNotification && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__save')} text={t('errors__message__tryLater5Min')} sx={{ marginTop: 4 }} />}
        </div>
      </form>
    </>
  )
}

export default EditMainInfo
