import React from "react";
import {useTranslation} from "react-i18next";
import { useForm} from "react-hook-form";
import {useDispatch} from "react-redux";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

import {
  checkTwoDecimalsAfterDot,
  checkFloat, checkDecimalOrZero,
} from "../../../../../../../../../../../utils/validation";
import { useUpdateBankCommissionsByInvoiceId } from '../../../../../../../../../../../api/calc';
import {ADORNMENTS} from "../../../../../../../../../../../config/constants";
import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";

import './payment-completed-edit.scss'
import cx from "classnames";
import isEmpty from "lodash/isEmpty";

enum ADD_PAYMENT_FORM {
  COMMISSION_PERCENTAGE = 'commission_percentage',
  PAYMENT_AMOUNT = 'payment_amount',
  BANK_TRANSFER_FEE = 'bank_transfer_fee',
  MIN_BANK_TRANSFER_FEE = 'min_bank_transfer_fee',
  MAX_BANK_TRANSFER_FEE = 'max_bank_transfer_fee',
  CURRENCY_CONTROL_FEE = 'currency_control_fee',
  MIN_CURRENCY_CONTROL_FEE = 'min_currency_control_fee',
  MAX_CURRENCY_CONTROL_FEE = 'max_currency_control_fee'
}

type AddParamsPaymentForm = {
  [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: string
  [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: string
  [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: string
  [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: string
  [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: string
  [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: string
}

type Props = {
    data?: BankCommissions
    invoiceNumber: string
    noPadding?: boolean
    indexItem?: number
    invoiceAmount: number
    onClose(): void
}

const PaymentCompletedEdit = ({data, onClose, invoiceNumber, noPadding, indexItem, invoiceAmount}: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const orderId = useGetCalcIdFromRoute()

  const methods = useForm<AddParamsPaymentForm>({
    defaultValues: {
      [ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]: data?.commission_percentage || '',
      [ADD_PAYMENT_FORM.PAYMENT_AMOUNT]: data?.payment_amount || '',
      [ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]: data?.bank_transfer_fee || '',
      [ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]: data?.min_bank_transfer_fee || '',
      [ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]: data?.max_bank_transfer_fee || '',
      [ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]: data?.currency_control_fee || '',
      [ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]: data?.min_currency_control_fee || '',
      [ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]: data?.max_currency_control_fee || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState: {errors, isValid},
  } = methods;

  const errorNumber = t('errors__message__incorrectValue');

  const onSubmit = handleSubmit (async (values) => {

    if (!values){
      return
    }

    const paymentData = {
      ...values,
      id: data?.id,
      indexItem,
    }

    const dataToSend = {
      orderId,
      invoiceNumber,
      data: paymentData
    }

    // @ts-ignore
    dispatch(useUpdateBankCommissionsByInvoiceId(dataToSend));

    // setOpenAlert(true);
    onClose();

  })

  const handleChangeCommissionPercentage = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const isErrorValue = value < 0 || value > 100;

    if (isErrorValue || checkTwoDecimalsAfterDot(value, errorNumber) !== true){
      setError(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE, t('errors__message__incorrectValue'));
    } else {
      clearErrors([ ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE ])
      setValue(ADD_PAYMENT_FORM.PAYMENT_AMOUNT, (invoiceAmount *  value / 100).toString());
      trigger(ADD_PAYMENT_FORM.PAYMENT_AMOUNT);
    }


  }

  const handleChangePaymentAmount = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const isErrorValue = value < 0 || value > invoiceAmount;

    if (isErrorValue || checkTwoDecimalsAfterDot(value, errorNumber) !== true){
      setError(ADD_PAYMENT_FORM.PAYMENT_AMOUNT, t('errors__message__incorrectValue'));
    } else {
      clearErrors([ ADD_PAYMENT_FORM.PAYMENT_AMOUNT ])
      const percentage = (value / invoiceAmount * 100).toFixed(2);
      setValue(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE, percentage);
      trigger(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE);
    }

  }


  return <form className={cx(
    'payments__completed__edit', {
      'payments__completed__edit_no-padding': noPadding,
    })}>
    <Typography variant='subtitle1'><b>{t('calc__text__bankCommissions__invoicePayment')}</b></Typography>
    <div className='payment__form-row'>
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE,
          {
            required: t('common__text__isRequired'),
            min: {value: 0, message: t('errors__message__incorrectValue')},
          }
        )}
        error={Boolean(errors[ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE])}
        helperText={errors[ADD_PAYMENT_FORM.COMMISSION_PERCENTAGE]?.message}
        variant='outlined'
        label={`${t('calc__text__bankCommissions__payment')}-${indexItem || 1}, ${t('calc__text__bankCommissions__percentages')}`}
        InputLabelProps={{shrink: true}}
        fullWidth
        onChange={handleChangeCommissionPercentage}
        InputProps={{
          endAdornment: ADORNMENTS.PERCENT,
        }}
      />
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.PAYMENT_AMOUNT,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkFloat(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.PAYMENT_AMOUNT])}
        helperText={errors[ADD_PAYMENT_FORM.PAYMENT_AMOUNT]?.message}
        variant='outlined'
        label={`${t('calc__text__bankCommissions__payment')}-${indexItem || 1}, ${t('calc__text__bankCommissions__amount')}`}
        InputLabelProps={{shrink: true}}
        fullWidth
        onChange={handleChangePaymentAmount}
        InputProps={{
          endAdornment: ADORNMENTS.PRICE,
        }}
      />
    </div>
    <Typography variant='subtitle1'
      sx={{marginBottom: 1}}><b>{t('calc__text__bankCommissions__bankCommissionForTheTransfer')}</b></Typography>
    <div className='payment__form-row'>
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.BANK_TRANSFER_FEE,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.BANK_TRANSFER_FEE])}
        helperText={errors[ADD_PAYMENT_FORM.BANK_TRANSFER_FEE]?.message}
        variant='outlined'
        label={t('calc__text__bankCommissions__commission')}
        InputLabelProps={{shrink: true}}
        fullWidth
        InputProps={{
          endAdornment: ADORNMENTS.PERCENT,
        }}
      />
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE])}
        helperText={errors[ADD_PAYMENT_FORM.MIN_BANK_TRANSFER_FEE]?.message}
        variant='outlined'
        label={t('calc__text__bankCommissions__minCommission')}
        InputLabelProps={{shrink: true}}
        fullWidth
        InputProps={{
          endAdornment: ADORNMENTS.PRICE,
        }}
      />
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE])}
        helperText={errors[ADD_PAYMENT_FORM.MAX_BANK_TRANSFER_FEE]?.message}
        variant='outlined'
        label={t('calc__text__bankCommissions__maxCommission')}
        InputLabelProps={{shrink: true}}
        fullWidth
        InputProps={{
          endAdornment: ADORNMENTS.PRICE,
        }}
      />
    </div>
    <Typography variant='subtitle1'
      sx={{marginBottom: 1}}><b>{t('calc__text__bankCommissions__bankCommissionForTheTransfer')}</b></Typography>
    <div className='payment__form-row'>
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE])}
        helperText={errors[ADD_PAYMENT_FORM.CURRENCY_CONTROL_FEE]?.message}
        variant='outlined'
        label={t('calc__text__bankCommissions__commission')}
        InputLabelProps={{shrink: true}}
        fullWidth
        InputProps={{
          endAdornment: ADORNMENTS.PERCENT,
        }}
      />
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE])}
        helperText={errors[ADD_PAYMENT_FORM.MIN_CURRENCY_CONTROL_FEE]?.message}
        variant='outlined'
        label={t('calc__text__bankCommissions__minCommission')}
        InputLabelProps={{shrink: true}}
        fullWidth
        InputProps={{
          endAdornment: ADORNMENTS.PRICE,
        }}
      />
      <TextField
        // @ts-ignore
        {...register(ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE,
          {
            required: t('common__text__isRequired'),
            validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
          })}
        error={Boolean(errors[ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE])}
        helperText={errors[ADD_PAYMENT_FORM.MAX_CURRENCY_CONTROL_FEE]?.message}
        variant='outlined'
        label={t('calc__text__bankCommissions__maxCommission')}
        InputLabelProps={{shrink: true}}
        fullWidth
        InputProps={{
          endAdornment: ADORNMENTS.PRICE,
        }}
      />
    </div>
    <div className='payment__form-row'>
      <Button
        variant='outlined'
        onClick={onClose}
      >{t('actions__text__cancel')}</Button>
      <Button
        variant='contained'
        fullWidth
        startIcon={<CheckIcon/>}
        disabled={!isEmpty(errors)}
        onClick={onSubmit}
        className='payment__buttonSave'
      >{t('actions__text__save')}</Button>
    </div>
  </form>
}

export default PaymentCompletedEdit
