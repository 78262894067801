import React, {useEffect, useState} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {COLORS, INFORMERS_CODES} from "../../../../../../../config/constants";
import uniq from "lodash/uniq";

import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Tooltip from "../../../../../../common/tooltip";

import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

import MainInfo from "../main_info";
import ModalVatRate from "../modal_VatRate";
import ModalCode from "../modal_code";
import ModalChooseVat from "../modal_chooseVat";
import Preferences from "../preferences";

import {Alert} from "../../../../../../../assets/svg";

import './add_form.scss'
import {useLazyGetTnvedQuery} from "../../../../../../../api/tnved";
import {useForm, FormProvider} from "react-hook-form";
import get from "lodash/get";
import {useCalculateDeclarationMutation, useUpdateDeclarationMutation} from "../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import Notification, {NOTIFICATION_TYPE} from "../../../../../../common/notification";
import {useInformers} from "../../../../../../../api/informers";
import Link from "@mui/material/Link";
import {getTnvedVAT} from "../../utils";

type Props = {
  index: number
  declaration: Declaration
  count: number
  feesTotal: number
  insideModal?: boolean
  onSave(): void
  onCancel?(): void
  setIsConfirmButtonDisable?(value: boolean): void
}

export enum DECLARATION_FORM {
  ORIGIN_CERTIFICATE = 'origin_certificate',
  PRODUCT_NAMES = 'product_names',
  VAT_RATE = 'vat_rate',
  VAT_RATE_RAW = 'vat_rate_raw',
  VAT = 'vat',
  DUTY_RATE = 'duty_rate',
  DUTY = 'duty',
  SUBTOTAL = 'subtotal',
  LOWIN = 'lowin',
  IMPORT_TAX = 'import_tax',
  HAS_PREFERENCES = 'has_preferences'
}

export type DeclarationFormFields = {
  [DECLARATION_FORM.ORIGIN_CERTIFICATE]: boolean
  [DECLARATION_FORM.HAS_PREFERENCES]: boolean
}

const AddForm = ( {onCancel, index, declaration, onSave, count, feesTotal, insideModal, setIsConfirmButtonDisable} : Props) => {

  const [ vatRate, setVatRate ] = useState<number | Vat>(declaration[DECLARATION_FORM.VAT_RATE_RAW]?.Value ? declaration[DECLARATION_FORM.VAT_RATE_RAW] : declaration[DECLARATION_FORM.VAT_RATE]);

  const [ rawVatRateChanged, setRawVatRateChanged ] = useState(Boolean(declaration[DECLARATION_FORM.VAT_RATE_RAW]?.Value));

  const [ showErrorNotification, setShowErrorNotification ] = useState<boolean | undefined>(false);

  const [ openModalVatRate, setOpenModalVatRate ] = useState(false);
  const [ openModalCode, setOpenModalCode ] = useState(false);
  const [ openModalChooseVat, setOpenModalChooseVat ] = useState(false);

  const [ tnvedInfo, setTnvedInfo ] = useState<TnvedInfo>();

  const [ customsCalculation, setCustomsCalculation ] = useState<CustomCalculation>({
    customsValue: declaration[DECLARATION_FORM.LOWIN],
    duty: declaration[DECLARATION_FORM.DUTY],
    customsPayment: declaration[DECLARATION_FORM.SUBTOTAL],
    vat: declaration[DECLARATION_FORM.VAT],
  });

  const [ tnvedRequest ] = useLazyGetTnvedQuery()

  const [ updateDeclaration ] = useUpdateDeclarationMutation()
  const [ calculateDeclaration ] = useCalculateDeclarationMutation()

  const informers = useInformers();

  const orderId = useGetCalcIdFromRoute()

  const methods = useForm<DeclarationFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues:{
      [DECLARATION_FORM.ORIGIN_CERTIFICATE]: declaration[DECLARATION_FORM.ORIGIN_CERTIFICATE],
      [DECLARATION_FORM.HAS_PREFERENCES]: declaration[DECLARATION_FORM.HAS_PREFERENCES]
    }
  });

  const { register, handleSubmit, getValues } = methods;

  useEffect(() => {
    tnvedRequest(declaration.code)?.then((response) => {
      setTnvedInfo(response?.data);
    });
  }, [])

  const { t } = useTranslation()

  const tnvedInfoVat = getTnvedVAT(tnvedInfo);

  const onSubmit = handleSubmit(async values => {

    const isRawVat = typeof vatRate !== 'number';

    const stringVatParams = {
      [DECLARATION_FORM.VAT_RATE] : vatRate
    }

    const rawVatParams = {
      [DECLARATION_FORM.VAT_RATE_RAW] : vatRate
    }

    const result = await updateDeclaration({
      orderId,
      code: declaration.code,
      country: declaration.country_data.code,
      ...(isRawVat && rawVatParams),
      ...(!isRawVat && stringVatParams),
      ...values})

    const declarationInfo = get(result, 'data')

    if (!declarationInfo) {
      setShowErrorNotification(true);
      return;
    }

    setShowErrorNotification(false);
    onSave();

    if (onCancel){
      onCancel();
    }

  })

  const handleChangeCheckbox = async (field: DECLARATION_FORM.ORIGIN_CERTIFICATE | DECLARATION_FORM.HAS_PREFERENCES, checked: boolean) => {
    await calculateDeclaration({
      orderId,
      code: declaration.code,
      country: declaration.country_data.code,
      ...(field === DECLARATION_FORM.ORIGIN_CERTIFICATE && {
        [DECLARATION_FORM.ORIGIN_CERTIFICATE]: checked,
        [DECLARATION_FORM.HAS_PREFERENCES]: getValues()[DECLARATION_FORM.HAS_PREFERENCES],
      }),
      ...(field === DECLARATION_FORM.HAS_PREFERENCES && {
        [DECLARATION_FORM.ORIGIN_CERTIFICATE]: getValues()[DECLARATION_FORM.ORIGIN_CERTIFICATE],
        [DECLARATION_FORM.HAS_PREFERENCES]: checked,
      }),
    }).then((response) => {
      setCustomsCalculation({
        // @ts-ignore
        customsValue: response?.data.invc,
        // @ts-ignore
        duty: response?.data.duty,
        // @ts-ignore
        customsPayment: response?.data.total,
        // @ts-ignore
        vat: response?.data.vat,
      })
    })
  }

  const checkUniqValues = () => {
    const tnvedInfoVatValues: string[] = []
    tnvedInfoVat?.map((item) => tnvedInfoVatValues.push(item.Value))
    return uniq(tnvedInfoVatValues).length === tnvedInfoVatValues.length;
  }

  const hasUniqVatValues = checkUniqValues();

  const exciseValue = Number((declaration.excise_value * declaration.excise).toFixed(2));

  const setMainButtonIsDisabled = () => {
    if (!hasUniqVatValues && setIsConfirmButtonDisable) {
      setIsConfirmButtonDisable(!rawVatRateChanged)
    }
  }

  setMainButtonIsDisabled();

  const handleChangeVat = async (type: string | Vat) => {

    const isRawVat = typeof type !== 'string';

    setVatRate(isRawVat ? type: Number(type))

    setRawVatRateChanged(true);

    const stringVatParams = {
      [DECLARATION_FORM.VAT_RATE] : Number(type)
    }

    const rawVatParams = {
      [DECLARATION_FORM.VAT_RATE_RAW] : type
    }

    await calculateDeclaration({
      orderId,
      code: declaration.code,
      country: declaration.country_data.code,
      ...(isRawVat && rawVatParams),
      ...(!isRawVat && stringVatParams),
    }).then((response) => {
      setCustomsCalculation({
        // @ts-ignore
        customsValue: response?.data.invc,
        // @ts-ignore
        duty: response?.data.duty,
        // @ts-ignore
        customsPayment: response?.data.total,
        // @ts-ignore
        vat: response?.data.vat,
      })
    })

    setMainButtonIsDisabled()
  }

  return <FormProvider {...methods}>
    <form className={cx( {
      'customs-declaration-container': onCancel,
    })}
    id='customs'
    onSubmit={onSubmit}
    >
      {onCancel && <MainInfo isCompleted isOpen index={index} count={count} feesTotal={feesTotal} />}
      <div className='customs-declaration'>
        <div className='customs-declaration__title'>
          <Typography variant='h6' className='customs-declaration__link' onClick={() => setOpenModalCode(true)}>{`${t('common__text__codeOfHS')} ${declaration.code} (${declaration.country_data.name})`}</Typography>
          <Typography variant='body2' sx={{ color: COLORS.BLACK }}>{declaration[DECLARATION_FORM.PRODUCT_NAMES].join(', ')}</Typography>
        </div>
        {declaration.allow_preferences && <Preferences
          code={declaration.code}
          handleChangeCheckbox={(event: React.ChangeEvent, checked: boolean) => handleChangeCheckbox(DECLARATION_FORM.HAS_PREFERENCES, checked)}
          defaultChecked={declaration[DECLARATION_FORM.HAS_PREFERENCES]}
        />}
        <div className='customs-declaration__form'>
          {tnvedInfoVat && <div className='customs-declaration__container'>
            <div className={cx({
              'customs-declaration__subtitle': hasUniqVatValues,
            })}>
              <div className='customs-declaration__label-container'>
                <Typography variant='caption' className='customs-declaration__label'>{t('common__text__vatRate')}</Typography>
                {hasUniqVatValues && <Alert onClick={() => setOpenModalVatRate(true)} />}
              </div>
            </div>
            {hasUniqVatValues ? (
              <>
                <FormControl>
                  <RadioGroup
                    name='radio-buttons-group'
                    value={vatRate}
                    onChange={({ target: { value: type } }) => handleChangeVat(type)}
                  >{tnvedInfoVat.map((item: Vat) => <FormControlLabel key={item?.ValueDetail?.ValueCount} sx={{ marginLeft: 0 }} value={item?.ValueDetail?.ValueCount} control={<Radio />} label={item?.Value} />)}
                  </RadioGroup>
                </FormControl>
              </>
            ) : (
              <>{rawVatRateChanged ? <div className='customs-declaration__changeVat'>
                <Typography variant='subtitle1'>
                  {/* @ts-ignore */}
                  {vatRate?.Value}
                </Typography>
                <Typography variant='body2' sx={{ marginLeft: 2 }}>
                  <Link onClick={() => setOpenModalChooseVat(true)} className='customs-declaration__linkModalChooseVat'>
                    {t('calc__text__customs__changeTheVatRate')}
                  </Link>
                </Typography>
              </div> : (
                <Typography variant='body2'>
                  <Link onClick={() => setOpenModalChooseVat(true)} className='customs-declaration__linkModalChooseVat' sx={{ marginBottom: 2 }}>
                    {t('calc__text__customs__choosingTheVatRate')}
                  </Link>
                </Typography>
              )}</>
            )}
          </div>}
          <div className='customs-declaration__item'>
            <Typography variant='caption' className='customs-declaration__label'>{t('calc__text__customs__vatAmount')}</Typography>
            <Typography variant='subtitle1'>{`${customsCalculation.vat} USD`}</Typography>
          </div>
          <div className='customs-declaration__item'>
            <FormGroup>
              <div className='customs-declaration__label-container'>
                <Typography variant='caption' className='customs-declaration__label'>{t('calc__text__customs__certificateOfOrigin')}</Typography>
                <Tooltip content={informers?.[INFORMERS_CODES.ORIGIN_CERTIFICATE]}/>
              </div>
              <FormControlLabel
                {...register(DECLARATION_FORM.ORIGIN_CERTIFICATE)}
                control={<Checkbox
                  defaultChecked={declaration[DECLARATION_FORM.ORIGIN_CERTIFICATE]}
                  onChange={(event: React.ChangeEvent, checked: boolean) => handleChangeCheckbox(DECLARATION_FORM.ORIGIN_CERTIFICATE, checked)}/>}
                label={t('common__text__yes')}
                sx={{ marginTop: 1}}
              />
            </FormGroup>
          </div>
          <div className='customs-declaration__item'>
            <div className='customs-declaration__label-container'>
              <Typography variant='caption' className='customs-declaration__label'>{t('calc__text__customs__customsCost')}</Typography>
              <Tooltip content={informers?.[INFORMERS_CODES.CUSTOMS_COST]}/>
            </div>
            <Typography variant='subtitle1' className='customs-declaration__value'>{customsCalculation.customsValue} USD</Typography>
          </div>

          {declaration[DECLARATION_FORM.IMPORT_TAX].description && (
            <div className='customs-declaration__item'>
              <div>
                <Typography variant='caption' className='customs-declaration__label'>{t('calc__text__customs__dutyRate')}</Typography>
                <Typography variant='subtitle1'>{declaration[DECLARATION_FORM.IMPORT_TAX].description}</Typography>
              </div>
            </div>
          )}

          <div className='customs-declaration__item'>
            <div>
              <Typography variant='caption' className='customs-declaration__label'>{t('calc__text__customs__dutyAmount')}</Typography>
              <Typography variant='subtitle1'>{`${customsCalculation.duty} USD`}</Typography>
            </div>
          </div>



          <div className='customs-declaration__item'>
            <div className='customs-declaration__label-container'>
              <Typography variant='caption' className='customs-declaration__label'>{t('common__text__customsPayment')}</Typography>
              <Tooltip content={informers?.[INFORMERS_CODES.CUSTOMS_PAYMENT]}/>
            </div>
            <Typography variant='subtitle1' sx={{ fontWeight: 500}}>{customsCalculation.customsPayment} USD</Typography>
          </div>
          {Boolean(exciseValue) && (
            <div className='customs-declaration__item'>
              <div className='customs-declaration__label-container'>
                <Typography variant='caption' className='customs-declaration__label'>{t('calc__text__customs__excise')}</Typography>
                <Tooltip content={informers?.[INFORMERS_CODES.CUSTOMS_PAYMENT]}/>
              </div>
              <Typography variant='subtitle1'>{exciseValue} USD</Typography>
            </div>
          )}
        </div>
      </div>
      {onCancel && (<div className='customs-declaration__row-buttons'>
        <Button
          variant='outlined'
          fullWidth
          onClick={onCancel}
        >{t('actions__text__cancel')}</Button>
        <Button
          variant='contained'
          fullWidth
          type='submit'
          startIcon={<CheckIcon />}
        >{`${t('actions__text__save')} ${t('common__text__addItem')}`}</Button>
      </div>)}
      {tnvedInfoVat && <ModalVatRate isOpen={openModalVatRate} onClose={() => setOpenModalVatRate(false)} info={tnvedInfoVat}/>}
      <ModalCode
        isOpen={openModalCode}
        onClose={() => setOpenModalCode(false)}
        insideModal={insideModal}
        code={declaration.code}
        tnvedInfo={tnvedInfo}
      />
      {/* @ts-ignore */}
      <ModalChooseVat isOpen={openModalChooseVat} onClose={() => setOpenModalChooseVat(false)} insideModal={insideModal} vatRate={tnvedInfoVat} handleSave={handleChangeVat} currentVatRate={vatRate} />
      {showErrorNotification && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__save')} text={t('errors__message__tryLater5Min')} sx={{ marginTop: 4 }}/>}
    </form>
  </FormProvider>
}

export default AddForm
