import React, {useEffect, useState} from 'react'
import './searchForm.scss'
import {useTranslation} from "react-i18next";
import { useForm, FormProvider } from 'react-hook-form'
import CachedIcon from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';
import LocationInput from './location_input';

import DateInput from './date_input';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import {COLORS} from "../../../../../../../config/constants";
import Lines from './lines.svg';
import cx from "classnames";
import {useParamsData} from "../../../../../../../api/calc";
import { useLazyGetLocationsQuery } from "../../../../../../../api/locations";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';

export enum DELIVERY_FORM {
    LOCATION_FROM = 'location_from',
    LOCATION_TO = 'location_to',
    CITY_NAME_FROM = 'city_name_from',
    CITY_NAME_TO = 'city_name_to',
    PLANNED_SHIPMENT_DATE = 'planned_shipment_date',
}

export type DeliveryFormFields = {
    [DELIVERY_FORM.LOCATION_FROM]: string
    [DELIVERY_FORM.LOCATION_TO]: string
    [DELIVERY_FORM.CITY_NAME_FROM]?: string
    [DELIVERY_FORM.CITY_NAME_TO]?: string
    [DELIVERY_FORM.PLANNED_SHIPMENT_DATE]: string
}

type Props = {
    isBigInputs?: boolean
    onSend?(data: DeliveryRouteParamsMain): void
}

type OptionData = {
    label: string
    id: string
    text: string
    countryCode: string
}

const SearchForm = ( { isBigInputs, onSend }: Props) => {

  const [ changeCitiesButtonIsActive, setChangeCitiesButtonIsActive ] = useState<boolean>(false);
  const [ locationFromId, setLocationFromId ] = useState<string | undefined>();
  const [ locationToId, setLocationToId ] = useState<string | undefined>();

  const [ defaultCityFrom, setDefaultCityFrom ] = useState<OptionData | undefined>();
  const [ defaultCityTo, setDefaultCityTo ] = useState<OptionData | undefined>();
  const [ showProgressBar, setShowProgressBar ] = useState(isBigInputs);

  const filterData = useParamsData();

  const [ locationsRequest ] = useLazyGetLocationsQuery()

  useEffect(() => {

    const mapCities = (data: any) => {
      return data.map(({  id, name, country, code }: LocationDictionary) => ({ id: Number(id), label: `${name} (${code})`, text: name, countryCode: country.code }))
    }

    if (filterData?.[DELIVERY_FORM.LOCATION_FROM]) {
      // @ts-ignore
      locationsRequest({id: filterData[DELIVERY_FORM.LOCATION_FROM]}).then((response) => {
        setDefaultCityFrom(mapCities(response?.data)[0])
      })
      setLocationFromId((filterData?.[DELIVERY_FORM.LOCATION_FROM]));
    }

    if (filterData?.[DELIVERY_FORM.LOCATION_TO]) {
      // @ts-ignore
      locationsRequest({id: filterData[DELIVERY_FORM.LOCATION_TO]}).then((response) => setDefaultCityTo(mapCities(response.data)[0]))
      setLocationToId((filterData?.[DELIVERY_FORM.LOCATION_TO]));
    }
  }, [ filterData ])

  useEffect(() => {
    if (isBigInputs) {
      setTimeout(() => {
        setShowProgressBar(false)
      }, 1000);
    }
  }, [ isBigInputs ])

  const methods = useForm<DeliveryFormFields>({
    defaultValues: {
      [DELIVERY_FORM.LOCATION_FROM]: filterData?.[DELIVERY_FORM.CITY_NAME_FROM],
      [DELIVERY_FORM.LOCATION_TO]: filterData?.[DELIVERY_FORM.CITY_NAME_TO],
      [DELIVERY_FORM.PLANNED_SHIPMENT_DATE]: filterData?.[DELIVERY_FORM.PLANNED_SHIPMENT_DATE],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { t } = useTranslation()

  const { getValues, setValue } = methods

  const changeCities = async () => {
    const valueFrom = getValues()[DELIVERY_FORM.LOCATION_FROM]
    const valueTo = getValues()[DELIVERY_FORM.LOCATION_TO]
    setValue(DELIVERY_FORM.LOCATION_FROM, valueTo)
    setValue(DELIVERY_FORM.LOCATION_TO, valueFrom)
  }

  // watch((array) => setChangeCitiesButtonIsActive(Boolean(array[DELIVERY_FORM.CITY_FROM]) && Boolean(array[DELIVERY_FORM.CITY_TO])))

  const handleSend = () => {
    if (!onSend){
      return
    }

    onSend({
      [DELIVERY_FORM.LOCATION_FROM]: locationFromId,
      [DELIVERY_FORM.LOCATION_TO]: locationToId,
      [DELIVERY_FORM.CITY_NAME_FROM]: getValues()[DELIVERY_FORM.LOCATION_FROM],
      [DELIVERY_FORM.CITY_NAME_TO]: getValues()[DELIVERY_FORM.LOCATION_TO],
      [DELIVERY_FORM.PLANNED_SHIPMENT_DATE]: moment(getValues()[DELIVERY_FORM.PLANNED_SHIPMENT_DATE]).format('YYYY-MM-DD'),
    });

  }

  const searchButtonIsActive = (locationFromId && locationToId && moment(getValues()[DELIVERY_FORM.PLANNED_SHIPMENT_DATE]).isValid()) ||
      filterData?.[DELIVERY_FORM.CITY_NAME_FROM] && filterData?.[DELIVERY_FORM.CITY_NAME_TO] && filterData?.[DELIVERY_FORM.PLANNED_SHIPMENT_DATE];

  return (
    <FormProvider {...methods}>
      <form
        id='delivery'
        className={cx({
          'search-container': isBigInputs,
        })}
      >
        {showProgressBar ? <CircularProgress /> : (
          <>
            <div className='searchForm'>
              {filterData[DELIVERY_FORM.CITY_NAME_FROM] && defaultCityFrom && (
                <LocationInput
                  fieldName={DELIVERY_FORM.LOCATION_FROM}
                  label={t('common__text__fromCity')}
                  placeholder={t('calc__text__delivery__exampleShenzhen')}
                  isBigInput={isBigInputs}
                  changeCityFrom={(id) => setLocationFromId(id)}
                  defaultValue={defaultCityFrom}
                />
              )}
              {!filterData[DELIVERY_FORM.CITY_NAME_FROM] && (
                <LocationInput
                  fieldName={DELIVERY_FORM.LOCATION_FROM}
                  label={t('common__text__fromCity')}
                  placeholder={t('calc__text__delivery__exampleShenzhen')}
                  isBigInput={isBigInputs}
                  changeCityFrom={(id) => setLocationFromId(id)}
                  defaultValue={defaultCityFrom}
                />
              )}
              <div className={cx('searchForm__line', {
                'searchForm__line_isBig': isBigInputs,
              })}>
                <img src={Lines} />
                <IconButton
                  className='searchForm__change-button'
                  sx={{
                    border: `1px solid ${changeCitiesButtonIsActive ? COLORS.BLUE : COLORS.DARK_GRAY}`,
                    background: COLORS.WHITE,
                    ':hover': {
                      background: COLORS.DARK_GRAY,
                    },
                    '&.Mui-disabled' : {
                      background: COLORS.DARK_GRAY,
                    }
                  }}
                  onClick={changeCities}
                  disabled={!changeCitiesButtonIsActive}
                >
                  <CachedIcon sx={{ color: changeCitiesButtonIsActive ? COLORS.BLUE : COLORS.BORDER_GRAY}}  />
                </IconButton>
              </div>
              {filterData[DELIVERY_FORM.CITY_NAME_TO] && defaultCityTo && (
                <LocationInput
                  fieldName={DELIVERY_FORM.LOCATION_TO}
                  label={t('common__text__toCity')}
                  placeholder={t('calc__text__delivery__exampleMoscow')}
                  isBigInput={isBigInputs}
                  changeCityTo={(id) => setLocationToId(id)}
                  defaultValue={defaultCityTo}
                />
              )}
              {!filterData[DELIVERY_FORM.CITY_NAME_TO] && (
                <LocationInput
                  fieldName={DELIVERY_FORM.LOCATION_TO}
                  label={t('common__text__toCity')}
                  placeholder={t('calc__text__delivery__exampleMoscow')}
                  isBigInput={isBigInputs}
                  changeCityTo={(id) => setLocationToId(id)}
                  defaultValue={defaultCityTo}
                />
              )}
              <DateInput isBigInputs defaultValue={filterData?.[DELIVERY_FORM.PLANNED_SHIPMENT_DATE] ? moment(filterData?.[DELIVERY_FORM.PLANNED_SHIPMENT_DATE]) : undefined}/>
            </div>
            <Button
              startIcon={<SearchIcon />}
              // type='submit'
              disabled={!searchButtonIsActive}
              variant='contained'
              fullWidth
              onClick={handleSend}
              sx={{
                marginTop: '12px',
                maxWidth: isBigInputs ? '360px' : '332px',
              }}
            >{t('actions__text__findDeliveryOptions')}</Button>
          </>
        )}
      </form>
    </FormProvider>
  )
}

export default SearchForm
