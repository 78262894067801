import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {getPluralForm} from "../../../utils/validation";
import cx from 'classnames'

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Template from './components/template';
import {Link} from "react-router-dom";

import './right-panel.scss';
import { ORDER_FORM } from "../../cabinet/calculator/components/main/components/editMain";
import moment from "moment";
import {DELIVERY_FORM} from "../../cabinet/calculator/components/delivery/components/searchForm";
import {METHODS} from "../../cabinet/calculator/components/packaging/components/summary";
import SummaryContainers from "../summaryContainers";

enum CURRENT_STEP {
  MAIN = 'main',
  PACKAGING = 'packaging',
  DELIVERY = 'delivery',
  CUSTOMS = 'customs',
  BANK_COMMISSIONS = 'bank_commissions',
  ADDITIONAL = 'additional',
}

type Props = {
  noInfo?: boolean
  order?: OrderParamsOutput
  invoices?: InvoiceParamsOutput[]
  summaryOrder?: SummaryParamsOutput | Record<string, never>
  summaryCustoms?: DeclarationSummaryParamsOutput | Record<string, never>
  summaryDelivery?: Route | Record<string, never>
  summaryCompleted?: Completed
  summaryAdditionalServices?: SummaryAdditionalServices,
  summaryBankCommissions?: OrderSummary
}

const SummaryCalculator = ({noInfo, order, summaryCustoms, summaryOrder, invoices, summaryDelivery, summaryCompleted, summaryAdditionalServices, summaryBankCommissions}: Props) => {

  const { t } = useTranslation()

  const [ currentStep, setCurrentStep ] = useState<CURRENT_STEP | undefined>(undefined)

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        window.scrollTo({
          behavior: 'smooth',
          top:
              element.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              10,
        })
      }
    }
  }, [ window.location ]);

  const getNoData = (field: string) => <Typography variant='caption' className='no-data'>
    <Link to={`/cabinet/calculator#${field}`} >{t('actions__text__fill')}</Link>
  </Typography>;

  const notEnoughData = <Typography variant='caption' className='no-data'>
    {t('errors__message__insufficientData')}
  </Typography>;

  const getInfoMain = () => {
    const data = [];
    data.push({
      label: t('calc__text__main__nameOfOrder'),
      value:  order?.[ORDER_FORM.ORDER_NAME] || getNoData(ORDER_FORM.ORDER_NAME),
    })
    invoices?.map((invoice, index) => {
      data.push({
        label: `${t('common__text__invoice')}-${index + 1}`,
        value: `№ ${invoice.invoice_num} ${t('common__text__from')} ${moment(invoice.invoice_date).format('DD.MM.YYYY')}`
      })
    })
    return data;
  }

  const INVOICE_DECLENSIONS: PluralForms = [ t('declension__text__invoiceOne'), t('declension__text__invoiceSeveral'), t('declension__text__invoiceMany') ];

  const getInfoPackaging = (isCompleted: boolean) => {
    const data = [
      {
        label: summaryOrder?.products_count ? (`${t('common__text__numberOfProducts')} ${t('common__text__in')} ${invoices?.length ? `${invoices?.length} ${getPluralForm(invoices?.length, INVOICE_DECLENSIONS)}` : ''}`) : t('common__text__numberOfProducts'),
        value: summaryOrder?.products_count || notEnoughData,
      },
      {
        label: t('calc__text__rightPanel__totalCostOfGoods'),
        value: summaryOrder?.price_total ? `${summaryOrder?.price_total} USD` : notEnoughData,
      },
      {
        label: t('common__text__totalNetGrossWeight'),
        value: summaryOrder?.volume_total ? <>{summaryOrder?.volume_total} {t('units__text__meter')}<sup>3</sup> / {summaryOrder?.net_weight_total} {t('units__text__kg')} / {summaryOrder?.gross_weight_total} {t('units__text__kg')}</> : notEnoughData,
      },
      {
        label: t('calc__text__rightPanel__totalPositions'),
        value: summaryOrder?.places_total || notEnoughData,
      },
    ];
    if (summaryOrder && isCompleted && summaryOrder) {
      data.push({
        label: summaryOrder.delivery_type === METHODS.COMBINED_CARGO ? t('calc__text__packaging__volumetricWeight') : t('calc__text__rightPanel__numberOfContainers'),
        value: <SummaryContainers summaryOrder={summaryOrder}/>,
      })
    }

    return data;
  }

  const infoDelivery = [
    {
      label: t('calc__text__rightPanel__from'),
      value: summaryDelivery?.city_from?.name || getNoData(DELIVERY_FORM.LOCATION_FROM),
    },
    {
      label: t('calc__text__rightPanel__to'),
      value: summaryDelivery?.city_to?.name || getNoData(DELIVERY_FORM.LOCATION_TO),
    },
    {
      label: t('common__text__date'),
      value: summaryDelivery?.planned_shipment_date ? moment(summaryDelivery?.planned_shipment_date).format('DD.MM.YYYY') : getNoData(DELIVERY_FORM.PLANNED_SHIPMENT_DATE),
    },
    {
      label: t('calc__text__rightPanel__preliminaryCostOfDelivery'),
      // @ts-ignore
      value: (summaryDelivery?.total_price) ? `${summaryDelivery?.total_price} USD` : notEnoughData,
    },
  ]

  const infoCustoms = [
    {
      label: t('calc__text__rightPanel__customsDeclarationForGoods'),
      value: summaryCustoms?.product_count || notEnoughData,
    },
    {
      label: t('common__text__codesOfHS'),
      value: summaryCustoms?.code_count || notEnoughData,
    },
    {
      label: t('calc__text__customs__brokerCommission'),
      value: summaryCustoms?.bro_total ? `${summaryCustoms?.bro_total || 0} USD` : notEnoughData,
    },
    {
      label: t('calc__text__rightPanel__preliminaryCostOfCustoms'),
      value: summaryCustoms?.fees_total ? `${summaryCustoms?.fees_total} USD` : notEnoughData,
    },
  ]


  const infoBankCommissions = [
    {
      label: t('calc__text__bankCommissions__payments'),
      value: `${summaryBankCommissions?.total_payment_amount}$`
    },
    {
      label: t('calc__text__bankCommissions__finalCommissionOfTheBank'),
      value: `${summaryBankCommissions?.total_banking_commissions}$`
    },
    {
      label: t('calc__text__bankCommissions__finalCurrencyControlCommission'),
      value: `${summaryBankCommissions?.total_currency_control_fees}$`
    },
    {
      label: t('calc__text__bankCommissions__totalToBePaid'),
      value: `${summaryBankCommissions?.total_payment_amount_with_commissions}$`
    },
  ]

  const infoAdditional = [
    {
      label: t('calc__text__additional__possibleAdditionalCosts'),
      value: summaryAdditionalServices?.expenses || '—',
    },
    {
      label: t('common__text__additionalServices'),
      value: summaryAdditionalServices?.services || '—',
    },
  ]

  const handleChangeStep = (step: CURRENT_STEP) => setCurrentStep(currentStep === step ? undefined : step)

  return (
    <Card className={cx('summary-total')}>
      {!noInfo && order?.id && <>
        <CardContent sx={{ padding: '24px'}}>
          <Typography variant='h6'>
            {t('calc__text__rightPanel__yourCalculationOfCargoDelivery')}
          </Typography>
          <Typography variant='caption'>
            {`№ ${order?.id} ${t('common__text__from')} ${moment(order?.created_at).format(`Do MMMM YYYY, HH:mm:ss `)}` }
          </Typography>
        </CardContent>
        <Divider/>
      </>}
      <CardContent sx={{ padding: '0 8px'}}>
        <Template text={`1. ${t('calc__text__rightPanel__mainData')}`} isCompleted={summaryCompleted?.common} info={getInfoMain()} isExpanded={currentStep === CURRENT_STEP.MAIN} onClick={() => handleChangeStep(CURRENT_STEP.MAIN)} inProgress={Boolean(order?.id)}/>
      </CardContent>
      <Divider/>
      <CardContent sx={{ padding: '0 8px'}}>
        <Template text={`2. ${t('common__text__packaging')}`} isCompleted={summaryCompleted?.packaging} info={getInfoPackaging(Boolean(summaryCompleted?.packaging))} isExpanded={currentStep === CURRENT_STEP.PACKAGING} onClick={() => handleChangeStep(CURRENT_STEP.PACKAGING)} isDisabled={!summaryCompleted?.common}/>
      </CardContent>
      <Divider/>
      <CardContent sx={{ padding: '0 8px'}}>
        {/* @ts-ignore */}
        <Template text={`3. ${t('common__text__delivery')}`} isCompleted={summaryCompleted?.delivery} info={infoDelivery} isExpanded={currentStep === CURRENT_STEP.DELIVERY} onClick={() => handleChangeStep(CURRENT_STEP.DELIVERY)} isDisabled={!summaryCompleted?.packaging}/>
      </CardContent>
      <Divider/>
      <CardContent sx={{ padding: '0 8px'}}>
        <Template text={`4. ${t('common__text__customs')}`} isCompleted={summaryCompleted?.customs} info={infoCustoms} isExpanded={currentStep === CURRENT_STEP.CUSTOMS} onClick={() => handleChangeStep(CURRENT_STEP.CUSTOMS)} isDisabled={!summaryCompleted?.packaging}/>
      </CardContent>
      <Divider/>
      <CardContent sx={{ padding: '0 8px'}}>
        <Template text={`5. ${t('common__text__bankCommissions')}`} isCompleted={summaryCompleted?.banking_commissions} info={infoBankCommissions} isExpanded={currentStep === CURRENT_STEP.BANK_COMMISSIONS} onClick={() => handleChangeStep(CURRENT_STEP.BANK_COMMISSIONS)} isDisabled={!summaryCompleted?.customs}/>
      </CardContent>
      <Divider/>
      <CardContent sx={{ padding: '0 8px', '&:last-child': { paddingBottom: 0 }}}>
        <Template text={`${t('common__text__additional')}`} isCompleted={summaryCompleted?.additional_services} info={infoAdditional} isExpanded={currentStep === CURRENT_STEP.ADDITIONAL} onClick={() => handleChangeStep(CURRENT_STEP.ADDITIONAL)} isDisabled={!summaryCompleted?.packaging}/>
      </CardContent>
      {!noInfo && <>
        <Divider/>
        <CardContent sx={{ padding: '16px 24px 24px'}}>
          <Typography variant='h6' className='right-panel__total'>
            <span className='right-panel__total-item'>
              {t('common__text__total')}
            </span>
            <span className='right-panel__total-item'>{summaryOrder?.total_amount ? `${summaryOrder?.total_amount} USD` : notEnoughData}</span>
          </Typography>
        </CardContent>
      </>}
    </Card>
  )
}

export default SummaryCalculator
