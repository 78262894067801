import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { getPluralForm } from "../../../../../../../../../utils/validation";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

type Props = {
  index?: number
  invoice: InvoiceParamsOutput
  isOpen?: boolean
  showArrow?: boolean
  handleClickArrow?(): void
}

const CommonInvoice = ( {index, invoice, isOpen, handleClickArrow, showArrow} : Props ) => {

  const { t } = useTranslation()

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];

  return <div className='packaging-invoice__row'>
    <div>
      <Typography variant='caption' className='packaging-invoice__caption'>{t('common__text__invoice')}-{index ? index + 1 : 1}</Typography>
      <Typography variant='subtitle1' className='packaging-invoice__name'><b>№ {invoice.invoice_num}</b> — {invoice.product_count ? `${invoice.product_count} ${getPluralForm(invoice.product_count, PRODUCTS_DECLENSIONS)}`: t('common__text__noItems')}</Typography>
    </div>
    {invoice.price_total && (
      <div>
        <Typography variant='caption' className='packaging-invoice__caption'>{t('common__text__totalCost')}</Typography>
        <Typography variant='subtitle1' className='packaging-invoice__name'><b>{invoice.price_total} USD</b></Typography>
      </div>
    )}
    {showArrow && <IconButton onClick={handleClickArrow}>
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </IconButton>}
  </div>
}

export default CommonInvoice
