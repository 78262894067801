import React from "react";

import Typography from "@mui/material/Typography";

import {useTranslation} from "react-i18next";

import ModalBig from "../../../../../../common/modalBig";
import Link from "@mui/material/Link";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import RadioGroup from '@mui/material/RadioGroup';

import Divider from "@mui/material/Divider";

import './modal_chooseVat.scss';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
    isOpen: boolean
    onClose(): void
    insideModal?: boolean
    vatRate?: Vat[]
    handleSave(value: Vat) : void
    currentVatRate?: Vat
}

export const getMergeVatRates = (vatRate?: Vat[]) => vatRate ? mapValues(groupBy(vatRate, 'MainCondition'),
  clist => clist.map(car => omit(car, 'MainCondition'))) : [];

const ModalChooseVat = ( {isOpen, onClose, insideModal, vatRate, handleSave, currentVatRate}: Props) => {

  const [ currentVat, setCurrentVat ] = React.useState<{ value: string, title: string, mainTitle: string } | undefined>(currentVatRate ? {
    value: currentVatRate.Value,
    title: currentVatRate.Condition,
    mainTitle: currentVatRate.Directory?.RuName
  }: undefined);

  const renderVatGroup = (vatRates: Vat[], title: string) => <>
    <Divider sx={{ marginTop: 3}} />
    <Typography variant='caption' sx={{ marginTop: 3, display: 'block' }}>
      {vatRates[0].Directory.RuName}
    </Typography>
    <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 2}}>
      {title}
    </Typography>
    {vatRates.map((vatRate, index) => <FormControlLabel
      key={index}
      onClick={() => setCurrentVat({ value: vatRate.Value, title: vatRate.Condition, mainTitle: vatRate.Directory.RuName})}
      sx={{ marginLeft: 0, marginBottom: 2 }}
      control={<Radio />}
      checked={currentVat?.value === vatRate.Value && currentVat?.title === vatRate.Condition && currentVat?.mainTitle === vatRate.Directory.RuName}
      label={<><b>{vatRate.Value} </b> {vatRate.Condition}</>} />
    )}
    <Typography variant='body2' sx={{ marginBottom: 1}}>
      <Link href={vatRates[0].Link} target='_blank' sx={{  display: 'block' }}>
        {vatRates[0].Document}
      </Link>
    </Typography>
  </>

  const { t } = useTranslation()

  const mergeVatRates = getMergeVatRates(vatRate);

  const onSend = () => {
    if (!currentVat) {
      return;
    }
    const userValue = vatRate?.find((item) => item.Value === currentVat.value && currentVat?.title === item.Condition && currentVat?.mainTitle === item.Directory.RuName)
    userValue && handleSave(userValue)
    onClose()
  }

  return <ModalBig
    isOpen={isOpen}
    onClose={onClose}
    title={t('calc__text__customs__choosingTheVatRate')}
    insideModal={insideModal}
  >
    <div className='choose-vat'>
      <Typography variant='h6'>
        {t('actions__message__selectValue')}
      </Typography>
      <FormControl>
        <RadioGroup
          name='radio-buttons-group'
        >
          {/* @ts-ignore */}
          {Object.keys(mergeVatRates).map((vatRate) => renderVatGroup(mergeVatRates[vatRate], vatRate))}
        </RadioGroup>
      </FormControl>
      <Divider sx={{ marginTop: 3}} />
      <div className='choose-vat__buttons'>
        <Button
          variant='outlined'
          onClick={onClose}
          sx={{ width: 198, marginRight: 4}}
        >{t('actions__text__cancel')}</Button>
        <Button
          variant='contained'
          sx={{ width: 418}}
          disabled={!currentVat}
          onClick={onSend}
        ><CheckIcon />{t('actions__text__save')}</Button>
      </div>
    </div>
  </ModalBig>
}

export default ModalChooseVat
