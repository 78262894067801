import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import every from "lodash/every";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";

import MainInfo from './components/main_info';

import CustomsDeclaration from "./components/customs_declaration";
import Summary from "./components/summary";
import {
  useDeclarationsByOrderIdQuery,
  useDeclarationSummaryByOrderIdQuery, useSetCompletedByOrderIdMutation, useSummaryCompleted,
} from "../../../../../api/calc"

import './customs.scss'
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import cx from "classnames";

type Props = {
  handleComplete(): void
}

const Customs = ({handleComplete}: Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const { data: declarations, refetch: refetchDeclaration } = useDeclarationsByOrderIdQuery(orderId)
  const { data: summary, refetch: refetchSummary } = useDeclarationSummaryByOrderIdQuery(orderId)

  const [ isConfirmButtonDisable, setIsConfirmButtonDisable ] = useState(false);

  const summaryCompleted = useSummaryCompleted()
  const declarationsCompleted = every(declarations, [ 'is_completed', true ]);

  const onSave = () => {
    refetchDeclaration();
    refetchSummary();
  }

  const [ shouldShowCustomsInfo, setShouldShowCustomsInvoiceInfo ] = useState(true)

  if (!declarations) {
    return null;
  }

  const renderOneDeclaration = (declaration: DeclarationsParamsOutput, index: number) => (
    <div className={cx('customs', {
      'customs_completed': declaration.is_completed,
    })} key={index}>
      <MainInfo isCompleted={declaration.is_completed} isOpen={shouldShowCustomsInfo} handleClickArrow={() => setShouldShowCustomsInvoiceInfo(!shouldShowCustomsInfo)} showArrow index={index} count={declaration.code_count} feesTotal={declaration.fees_total} />
      {shouldShowCustomsInfo && declaration.codes.map((declarationByCode: Declaration) =>
        <CustomsDeclaration
          isCompleted={declaration.is_completed}
          declaration={declarationByCode}
          index={index}
          key={declarationByCode.code}
          onSave={onSave}
          count={declaration.code_count}
          feesTotal={declaration.fees_total}
          setIsConfirmButtonDisable={setIsConfirmButtonDisable}
        />)}
    </div>
  )

  const renderDeclarations = () => declarations.map((declaration, index) => renderOneDeclaration(declaration, index));

  const handleSaveAll = async () => {
    await setCompleted({
      orderId,
      customs: true,
    })
    refetchDeclaration();
    handleComplete();
  }

  return <>
    {renderDeclarations()}
    {(!declarationsCompleted || !summaryCompleted?.customs) && <Button
      variant='contained'
      fullWidth
      type='submit'
      startIcon={<CheckIcon />}
      onClick={handleSaveAll}
      disabled={isConfirmButtonDisable}
      sx={{ marginBottom: 4}}
    >{t('actions__text__saveCustoms')}</Button>}
    {summary && <Summary summary={summary} />}
  </>
}

export default Customs
