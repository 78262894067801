import React, {useState} from "react";

import TextField from "@mui/material/TextField";
import {ORDER_FORM} from "../../../calculator/components/main/components/editMain";
import PhoneInput, {CountryData} from "react-phone-input-2";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import ru from 'react-phone-input-2/lang/ru.json'

import './account.scss'
import {useUser, useUpdateUserInfoMutation} from "../../../../../api/auth";
import AlertBottom from "../../../../common/alertBottom";
import {EMAIL_PATTERN} from "../../../../../utils/validation";

const RUSSIA_COUNTRY_NAME = 'Russia'
const RUSSIA_PHONE_NUMBER_REGEXP = /7\d{10}/g

export enum ACCOUNT_FORM {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    EMAIL = 'email',
    PHONE = 'phone',
}

export type AccountParams = {
    [ACCOUNT_FORM.FIRST_NAME]: string
    [ACCOUNT_FORM.LAST_NAME]: string
    [ACCOUNT_FORM.EMAIL]: string
    [ACCOUNT_FORM.PHONE]: string
}

const Account = () => {

  const { t } = useTranslation()

  const user = useUser()

  const [ hasSent, setHasSent ] = useState(false);

  const [ updateUserInfo ] = useUpdateUserInfoMutation()

  const [ openAlert, setOpenAlert ] = React.useState(false);

  const defaultValues = {
    [ACCOUNT_FORM.FIRST_NAME]: user?.[ACCOUNT_FORM.FIRST_NAME],
    [ACCOUNT_FORM.LAST_NAME]: user?.[ACCOUNT_FORM.LAST_NAME],
    [ACCOUNT_FORM.EMAIL]: user?.[ACCOUNT_FORM.EMAIL],
    [ACCOUNT_FORM.PHONE]: user?.[ACCOUNT_FORM.PHONE],
  };

  const { register, handleSubmit, getValues, setValue, clearErrors, setError, formState: { errors, isValid } } = useForm<AccountParams>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const handleValidatePhoneNumberRequired = () => {
    if (!getValues(ACCOUNT_FORM.PHONE)) {
      setError(ACCOUNT_FORM.PHONE, { type: "custom",  message: t('common__text__isRequired') })
    }
    return Boolean(getValues(ACCOUNT_FORM.PHONE));
  }

  // @ts-ignore
  const handlePhoneChange = (value: string, country, e, formattedValue: string) => {
    setValue(ACCOUNT_FORM.PHONE, formattedValue)
  }

  const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>, data: CountryData) => {
    let {value} = event.target;
    value = value.replace(/[+()-/ ]/g, "");

    if (data.name === RUSSIA_COUNTRY_NAME) {
      value.match(RUSSIA_PHONE_NUMBER_REGEXP) ? clearErrors(ACCOUNT_FORM.PHONE) : setError(ACCOUNT_FORM.PHONE, { type: "custom",  message: t('errors__message__incorrectPhoneError') });
    }
  }

  const onSubmit = handleSubmit(async values => {
    if (!handleValidatePhoneNumberRequired) return
    await updateUserInfo(values);
    setHasSent(true);
    setOpenAlert(true);
  }, () => handleValidatePhoneNumberRequired())

  return (
    <>
      <form
        className='settings-account'
        onSubmit={onSubmit}
        onChange={() => setHasSent(false)}
      >
        <TextField
          {...register(ACCOUNT_FORM.FIRST_NAME, { required: t('common__text__isRequired') })}
          error={Boolean(errors[ACCOUNT_FORM.FIRST_NAME])}
          helperText={errors[ACCOUNT_FORM.FIRST_NAME]?.message}
          variant='outlined'
          label={t('common__text__firstName')}
          InputLabelProps={{ shrink: true }}
          id={ORDER_FORM.CONTACT_NAME}
        />
        <TextField
          {...register(ACCOUNT_FORM.LAST_NAME, { required: t('common__text__isRequired') })}
          error={Boolean(errors[ACCOUNT_FORM.LAST_NAME])}
          helperText={errors[ACCOUNT_FORM.LAST_NAME]?.message}
          variant='outlined'
          label={t('common__text__lastName')}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...register(ACCOUNT_FORM.EMAIL, { required: t('common__text__isRequired'), pattern: { value: EMAIL_PATTERN, message: t('errors__message__incorrectEmail') } })}
          error={Boolean(errors[ACCOUNT_FORM.EMAIL])}
          helperText={errors[ACCOUNT_FORM.EMAIL]?.message}
          variant='outlined'
          label={t('common__text__email')}
          InputLabelProps={{ shrink: true }}
          disabled
        />
        <div className='settings-account__phone'>
          <PhoneInput
            country={'ru'}
            value={getValues(ACCOUNT_FORM.PHONE)}
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
            // @ts-ignore
            isValid={!errors[ACCOUNT_FORM.PHONE]}
            localization={ru}
            specialLabel={t('common__text__phone')}
            inputClass='settings-account__phone-input'
          />
          <span className='settings-account__phone-error'>{errors[ACCOUNT_FORM.PHONE]?.message}</span>
        </div>
        <Button
          className='settings-account__button'
          variant='contained'
          fullWidth
          type='submit'
          startIcon={<CheckIcon />}
          disabled={!isValid || hasSent}
        >{t('actions__text__saveAccountData')}</Button>
      </form>
      <AlertBottom
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        message={t('common__message__dataSavedSuccessfully')}
      />
    </>
  )
}

export default Account
