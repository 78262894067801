import React from "react";
import Invoice from './components/invoice';
import Summary from './components/summary';

import {
  useGetInvoices
} from "../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";

type Props = {
  handleComplete(): void
}

const Packaging = ({handleComplete} : Props) => {

  const invoices = useGetInvoices()

  const orderId = useGetCalcIdFromRoute()

  if (!invoices || !orderId) {
    return null;
  }

  const productsCount = invoices.reduce(
    (accumulator, invoice) => accumulator + (invoice?.product_count || 0),
    0
  );

  return <>
    {invoices.map((invoice: InvoiceParamsOutput, key: number) => <Invoice invoice={invoice} key={invoice.id} index={key} productsCount={productsCount} />)}
    {productsCount > 0 && <Summary productsCount={productsCount} handleComplete={handleComplete} />}
  </>
}

export default Packaging
