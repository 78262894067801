import SummaryCalculator from '../../../../common/summaryCalculator'
import {
  CALC_ID, useCloneCalculationMutation,
  useInvoicesData,
  useOrder, useResetOrderId,
  useSummary,
  useSummaryCustoms,
  useCurrentRoute, useSummaryCompleted,
  useAdditionalServices
} from "../../../../../api/calc";
import sortBy from "lodash/sortBy";
import Button from "@mui/material/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../../../../config/constants";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import './right-panel.scss';
import Add from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";

const RightPanel = () => {

  const order = useOrder();
  const invoices = useInvoicesData();
  const sortedInvoices = sortBy(invoices, 'id');
  const summaryOrder = useSummary();
  const summaryCustoms = useSummaryCustoms();
  const summaryDelivery = useCurrentRoute();
  const summaryCompleted = useSummaryCompleted()
  const additionalServices = useAdditionalServices()

  const [ cloneOrder ] = useCloneCalculationMutation()

  const navigate = useNavigate();

  const dispatch = useDispatch()

  const orderId = useGetCalcIdFromRoute()

  const expenses: AdditionalService[] = [];
  const services: AdditionalService[] = [];

  additionalServices?.map((additionalService: AdditionalService) => {
    if (additionalService.id) {
      services.push(additionalService)
    } else {
      expenses.push(additionalService)
    }
  })

  const getAllAdditionalData = (additionalData: AdditionalService[] | [], isServices?: boolean) => {
    if (additionalData.length === 0) {
      return undefined;
    }

    let stringValue = '';

    const getAdditionalServiceName = (addService: AdditionalService) => `${stringValue ? `${stringValue}, ` : ''}${addService.name} — ${addService.cost} USD`

    additionalData.map((addService) => {
      // @ts-ignore
      if (isServices && summaryDelivery?.selected_services.includes(addService.id)) {
        stringValue = getAdditionalServiceName(addService);
      }

      if (!isServices) {
        stringValue = getAdditionalServiceName(addService);
      }
    });

    return stringValue;
  }

  const summaryAdditionalServices = {
    expenses: getAllAdditionalData(expenses),
    services: getAllAdditionalData(services, true),
  }

  const { t } = useTranslation()

  const openNewCalculation = () => {

    navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`, { replace: true })
    localStorage.removeItem(CALC_ID);
    // @ts-ignore
    dispatch(useResetOrderId(''));
  }

  const cloneCalculation = () => cloneOrder(orderId)?.then((data) => {
    // @ts-ignore
    const newNumber = data?.data?.num;

    if (newNumber) {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}/${newNumber}`);
    }}
  )

  return (
    <>
      <div className='right-panel-buttons'>
        <Button
          variant='outlined'
          sx={{ width: orderId ? '168px': '100%', marginBottom: 4}}
          onClick={openNewCalculation}
          startIcon={<Add />}
          fullWidth={!orderId}
        >{t('calc__text__rightPanel__newCalculation')}</Button>
        {orderId && <Button
          variant='outlined'
          sx={{ width: '168px', marginBottom: 4}}
          onClick={cloneCalculation}
          startIcon={<ContentCopyIcon />}
        >{t('calc__text__rightPanel__copyCalculation')}</Button>}
      </div>
      <div className='right-panel'>
        <SummaryCalculator
          order={order}
          invoices={sortedInvoices}
          summaryOrder={summaryOrder}
          summaryCustoms={summaryCustoms}
          summaryDelivery={summaryDelivery}
          summaryCompleted={summaryCompleted}
          summaryAdditionalServices={summaryAdditionalServices}
        />
      </div>
    </>
  )
}

export default RightPanel
