import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import Button from "@mui/material/Button";
import {
  useInvoicesByOrderIdQuery, useRemoveInvoiceMutation
} from "../../../../../../../api/calc";
import React, {useEffect, useState} from "react";
import Completed from "../../../../../../completed";
import EditInvoice from "./components/editInvoice";
import './invoice.scss'
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import ModalBig from '../../../../../../common/modalBig';
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";

import ModalSmall from "../../../../../../common/modalSmall";
import {useDispatch} from "react-redux";

type Props = {
  openByDefault?: boolean
  handleAdd(): void
  handleComplete(): void
  handleRemove(): void
}

const Invoice = ({openByDefault, handleAdd, handleComplete, handleRemove} : Props) => {

  const { t } = useTranslation()

  const [ showAddForm, setShowAddFrom ] = useState<boolean | undefined>(openByDefault);

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const [ currentInvoiceId, setCurrentInvoiceId ] = useState<number>();

  const [ shouldOpenRemoveModal, setShouldOpenRemoveModal ] = useState(false)

  const [ removeInvoice ] = useRemoveInvoiceMutation()

  const orderId = useGetCalcIdFromRoute()

  const dispatch = useDispatch()

  const { data: invoices, isSuccess, refetch } = useInvoicesByOrderIdQuery(orderId, { skip: !orderId })

  const sortedInvoices = sortBy(invoices, 'id');

  useEffect(() => {
    if (!openByDefault) {
      setShowAddFrom(!invoices);
    }
  }, [ isSuccess ])

  const getCompletedInfo = (invoice: InvoiceParamsOutput, index: number) =>[
    {
      label: `${t('common__text__invoice')}-${index + 1}`,
      value: <><b>{`№ ${invoice.invoice_num}`}</b><span>{` ${t('common__text__from')} ${moment(invoice.invoice_date).format('DD.MM.YYYY')}, ${invoice.incoterms}, ${invoice.location_city.name}`}</span></>
    }
  ]

  const handleAddSuccess = () => {
    refetch()
    setShowAddFrom(false)
    setOpenEditModal(false)
  }

  const onEdit = (id: number) => {
    setCurrentInvoiceId(id)
    setOpenEditModal(true)
  }

  const onClose = () => {
    setOpenEditModal(false)
  }

  const removeItem = async (invoiceId?: number | string) => {
    if (invoiceId) {
      await removeInvoice({orderId, invoiceId: Number(invoiceId)})
      setShouldOpenRemoveModal(false)
      await refetch()
      await handleRemove();
    }
  }

  const onRemove = (id: number) => {
    setCurrentInvoiceId(id)
    setShouldOpenRemoveModal(true)
  }

  const handleNextStep = async () => {
    handleComplete();
    handleAdd();
  }

  const handleCloseAddForm = () => {
    setShowAddFrom(false);
  }

  return (
    <div className='invoices'>
      {sortedInvoices?.map((invoice, index) => <Completed
        key={invoice.id}
        info={getCompletedInfo(invoice, index)}
        id={invoice.id}
        onEdit={onEdit}
        onRemove={onRemove}
      />)}
      {showAddForm ? (
        <EditInvoice handleSuccess={handleAddSuccess} hasCancelButton onClose={handleCloseAddForm} />
      ) : (<div className='invoices__buttons'>
        <Button
          color='secondary'
          fullWidth
          variant='outlined'
          onClick={() => setShowAddFrom(true)}
          startIcon={<AddIcon />}
        >
          {`${t('actions__text__addInvoice')}-${sortedInvoices.length + 1}` }
        </Button>
        <Button
          fullWidth
          variant='contained'
          onClick={handleNextStep}
          endIcon={<ArrowForwardIosIcon />}
          sx={{ marginLeft: 3 }}
          disabled={!invoices?.length}
        >
          {t('actions__text__nextStep')}
        </Button>
      </div>)}
      {openEditModal && <ModalBig
        isOpen
        onClose={() => setOpenEditModal(false)}
        title={t('calc__text__main__editInvoice')}
      >
        <EditInvoice handleSuccess={handleAddSuccess} id={currentInvoiceId} noBorder hasCancelButton onClose={onClose} />
      </ModalBig>}
      {shouldOpenRemoveModal && <ModalSmall
        isOpen={shouldOpenRemoveModal}
        onContinue={removeItem}
        index={currentInvoiceId}
        onClose={() => setShouldOpenRemoveModal(false) }
        title={t('common__text__removeInvoice')}
        description={<>{t('actions__message__removeConfirm')}<span className='invoice__text_bold'>{` ${t('common__text__invoice')}-${findIndex(sortedInvoices, (invoice) => invoice.id === currentInvoiceId) + 1}`}?</span></>}
        buttonYesText={t('actions__text__yesDelete')}
      />}
    </div>
  )
}

export default Invoice
