import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Completed from "../../../../../../../../../../completed";
import ModalBig from "../../../../../../../../../../common/modalBig";
import PaymentCompletedEdit from "../payment-completed-edit";
import {
  useBankCommissionsByInvoiceId,
  useUpdateBankCommissionsByInvoiceId,
} from "../../../../../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";
import {useDispatch} from "react-redux";

type Props = {
  indexItem: number
  payment: BankCommissions
  invoiceNumber: string
  invoiceAmount: number
}

const PaymentCompleted = ({ payment, indexItem, invoiceNumber, invoiceAmount } : Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const dispatch = useDispatch()

  const [ showModal, setShowModal ] = useState(false);

  const invoiceBankCommissions = useBankCommissionsByInvoiceId(invoiceNumber);

  const getInfo = () => [
    {
      label: `${t('calc__text__bankCommissions__payment')}-${indexItem + 1}`,
      value: payment.number ? `№ ${payment.number}`: '',
      isStrong: true,
    },
    {
      label: `${t('calc__text__bankCommissions__payment')}, ${t('calc__text__bankCommissions__percentages')} / ${t('calc__text__bankCommissions__payment')}, ${t('calc__text__bankCommissions__amount')}`,
      value: `${payment.commission_percentage}% / ${payment.payment_amount} USD`
    },
    {
      label: t('calc__text__bankCommissions__bankCommissionForTheTransfer'),
      value: `${payment.bank_transfer_fee}% (${t('common__text__from')} ${payment.min_bank_transfer_fee} USD ${t('common__text__before')} ${payment.max_bank_transfer_fee} USD)`
    },
    {
      label: t('calc__text__bankCommissions__currencyControlCommission'),
      value: `${payment.currency_control_fee}% (${t('common__text__from')} ${payment.min_currency_control_fee} USD ${t('common__text__before')} ${payment.max_currency_control_fee} USD)`
    },
  ]

  const handleRemove = () => {

    if (!invoiceBankCommissions){
      return;
    }

    const dataToSend = {
      orderId,
      invoiceNumber,
      data: {
        id: payment.id,
      },
      isRemove: true
    }

    // @ts-ignore
    dispatch(useUpdateBankCommissionsByInvoiceId(dataToSend));
  }

  return <>
    <Completed
      info={getInfo()}
      onEdit={() => setShowModal(true)}
      onRemove={handleRemove}
      twoRows
    />
    <ModalBig
      isOpen={showModal}
      onClose={()=> setShowModal(false)}
      title={`${t('calc__text__bankCommissions__editPayment')}-${indexItem + 1}, ${t('common__text__invoice')} № ${invoiceNumber}` }
    >
      <PaymentCompletedEdit
        onClose={()=> setShowModal(false)}
        data={payment}
        invoiceNumber={invoiceNumber}
        indexItem={indexItem}
        invoiceAmount={invoiceAmount}
      />
    </ModalBig>
  </>
}

export default PaymentCompleted
