import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";

import './common-invoice.scss';

type Props = {
    isOpen?: boolean
    index: number
    invoiceNumber: string
    invoiceAmount?: number
    handleClickArrow?(): void
}

const InvoiceTitle = ({isOpen, handleClickArrow, invoiceNumber, invoiceAmount, index} : Props ) => {

  const { t } = useTranslation()

  return <div className='payment__invoice'>
    <div>
      <Typography variant='caption' className='packaging-invoice__caption'>{t('common__text__invoice')}-{index + 1}</Typography>
      <Typography variant='subtitle1' className='packaging-invoice__name'><b>№ {invoiceNumber}</b></Typography>
    </div>
    {invoiceAmount && (
      <div>
        <Typography variant='caption' className='packaging-invoice__caption'>{t('common__text__totalCost')}</Typography>
        <Typography variant='subtitle1' className='packaging-invoice__name'><b>{invoiceAmount} USD</b></Typography>
      </div>
    )}
    <IconButton onClick={handleClickArrow}>
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
  </div>
}

export default InvoiceTitle
