import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import './edit.scss';
import {
  useAddServicesToRouteByOrderIdMutation,
  useCurrentRoute, useSetCompletedByOrderIdMutation,
} from "../../../../../../../api/calc";

import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import cx from "classnames";

type Props = {
  services: AdditionalService[],
  handleEdit?(): void,
  isModal?: boolean
  isCompleted?: boolean,
  handleComplete?(): void
}

const Edit = ({ services, isModal, handleEdit, handleComplete, isCompleted } : Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const currentRoute = useCurrentRoute()

  const [ activeAddServicesIds, setActiveAddServicesIds ] = useState<number[]>(currentRoute ? currentRoute.selected_services : [])

  const [ refuseAddServices, setRefuseAddServices ] = useState(false);

  const [ addServices ] = useAddServicesToRouteByOrderIdMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const REFUSE_ALL_SERVICES = {
    id: 'refuse',
    name: t('calc__text__additional__refuseAdditionalServices')
  }

  const userHasAddServices = services.find((service) => service.id && activeAddServicesIds.includes(service.id))

  useEffect(() => {
    setActiveAddServicesIds(currentRoute ? currentRoute.selected_services : [])
    if (isCompleted && !userHasAddServices){
      setRefuseAddServices(true);
    }

  }, [ currentRoute ]);

  const handleChangeCheckbox = (id: number | null, isChecked: boolean) => {
    if (!id){
      return
    }
    const newServices = isChecked ? [ ...activeAddServicesIds, id ] : activeAddServicesIds.filter(service => service !== id)
    setActiveAddServicesIds(newServices);
  }

  const handleSend = async () => {
    await addServices({
      orderId,
      services: activeAddServicesIds
    })
    await setCompleted({
      orderId,
      additional_services: true
    })

    handleEdit && handleEdit();
    handleComplete && handleComplete();
  }

  const servicesIds = services.reduce((arr: number[], curr: AdditionalService) => {
    if (typeof curr.id === "number") {
      arr.push(curr.id);
    }
    return arr;
  }, []);

  const handleChangeRefuseAddServices = (checked: boolean) => {
    setRefuseAddServices(checked)
    if (checked) {
      const activeAddServices = activeAddServicesIds.filter(item => !servicesIds.includes(item));
      setActiveAddServicesIds(activeAddServices);
    }
  }

  return (
    <div className={cx({
      'additional-modal': isModal,
    })}>
      <Typography variant='body1' sx={{
        fontWeight: 500,
        marginTop: isModal ? 0 : '28px',
      }}>
        {t('common__text__additionalServices')}
      </Typography>
      {services.length === 0 && <Typography variant='body1' sx={{ marginTop: '4px'}}>
        {t('common__text__nonExistent')}
      </Typography>}
      {services.map((service) => <FormControlLabel key={service.id}
        control={<Checkbox checked={(service?.id && !refuseAddServices) ? activeAddServicesIds.includes(service.id) : false}/>}
        label={<span>{service.name} — <b>{service.cost} USD</b></span>}
        sx={{ marginTop: 1}}
        disabled={refuseAddServices}
        onChange={(event, checked) => handleChangeCheckbox(service?.id, checked)}
      />)}
      {services.length > 0 && <FormControlLabel key={REFUSE_ALL_SERVICES.id}
        control={<Checkbox checked={refuseAddServices}/>}
        label={REFUSE_ALL_SERVICES.name}
        sx={{ marginTop: 1}}
        onChange={(event, checked) => handleChangeRefuseAddServices(checked)}
      />}
      <Button
        variant='contained'
        fullWidth
        type='submit'
        startIcon={<CheckIcon />}
        sx={{ marginTop: 4}}
        onClick={handleSend}
      >{refuseAddServices || !userHasAddServices ? t('actions__text__saveAdditionalDataNoAddServices') : t('actions__text__saveAdditionalData')}</Button>
    </div>
  )
}

export default Edit
