import React from "react";
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cx from 'classnames'

import {COLORS} from "../../../../../../config/constants";
import './summary_item.scss';

type SummaryItemGeneral = {
    title: string
    price: React.ReactNode
}

type SummaryItem = {
    title: string
    price?: React.ReactNode
    additional?: SummaryItemGeneral[]
    notification?: React.ReactNode
}

type Props = {
    item: SummaryItem;
}

const SummaryItem = ({item} : Props) => {

  if (!item.additional) {
    return (<div>
      <div className={cx('summary-calculator__item',
        {'summary-calculator__item_notification': item.notification}
      )}>
        <Typography variant='body2'>
          {item.title}
        </Typography>
        {item.price && <Typography variant='subtitle2' sx={{marginRight: 4}}>
          {item.price}
        </Typography>}
      </div>
      <div className='summary-calculator__item_notificaiton-container'>
        {item.notification}
      </div>
    </div>);
  }

  return (
    <Accordion sx={{
      boxShadow: 'none',
      marginBottom: 0,
      borderBottom: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
      '&:before': {display: 'none'},
      padding: '0px 8px 0 22px',
      '&:last-child': {
        marginBottom: 0,
        borderBottom: 'none'
      },
      '&.Mui-expanded': {
        marginBottom: 0,
      }
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ height: '53px' }}
      >
        <div className='summary-calculator__item summary-calculator__item_no-padding'>
          <Typography variant='body2'>
            {item.title}
          </Typography>
          <Typography variant='subtitle2'>
            {item.price}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px 16px 24px 24px' }}>
        <ul className='summary-calculator__additional'>
          {item.additional.map((itemAdditional, index) => (
            <li className='summary-calculator__item summary-calculator__item_additional' key={index}>
              <Typography variant='body2'>
                {itemAdditional.title}
              </Typography>
              <Typography variant='subtitle2'>
                {itemAdditional.price}
              </Typography>
            </li>))}
        </ul>

      </AccordionDetails>
    </Accordion>
  )
}

export default SummaryItem
