import React, {useState} from "react";

import {useTranslation} from "react-i18next";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

import './payment-accordion-container.scss';

type Props = {
    title: string;
    children: React.ReactNode;
    index?: number;
    remove?(id: number): void;
}

const PaymentAccordionContainer = ({ children, title, index, remove }: Props) => {

  const { t } = useTranslation()

  const [ shouldShowPaymentInfo, setShouldShowPaymentInfo ] = useState(true)

  const handleChangeShowPaymentInfo = () => setShouldShowPaymentInfo(!shouldShowPaymentInfo);

  return <div className='payment'>
    <Accordion
      expanded={shouldShowPaymentInfo}
      onChange={handleChangeShowPaymentInfo}
      sx={{
        marginBottom: 0,
        boxShadow: 'none',
        '&.Mui-expanded:last-of-type': {
          marginBottom: 0,
        }
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          minHeight: 4,
          '.MuiAccordionSummary-content': {
            display: 'block',
            margin: 0,
          },
          '.Mui-expanded.MuiAccordionSummary-content': {
            margin: 0,
          }
        }}
      >
        <div className='payment__title'>
          <Typography variant='h6'>
            {title}
          </Typography>
          <div className='payment__title-buttons'>
            {remove && index && (
              <IconButton aria-label='delete'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '40px',
                }}
                onClick={() => remove(index)}
              >
                <DeleteIcon />
                <Typography variant='body2'>{t('calc__text__bankCommissions__deletePayment')}</Typography>
              </IconButton>
            )}
            <IconButton onClick={handleChangeShowPaymentInfo} sx={{padding: 0}}>
              {shouldShowPaymentInfo ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}>
        {children}
      </AccordionDetails>
    </Accordion>
  </div>
}

export default PaymentAccordionContainer
