import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import { useFormContext } from "react-hook-form";
import {checkDecimal, checkTwoDecimalsAfterDot} from "../../../../../../../../../utils/validation";
import { PACKAGE_FORM, PackageForm } from "../add_form/helpers";
import {ADORNMENTS} from "../../../../../../../../../config/constants";
import PalletesType from '../palletes_type';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useLazyGetPalletesQuery} from "../../../../../../../../../api/palletes";

type Option = {
  value: string
  label: string
}

type PalletOption = {
  code: string
  name: string
}

enum STATUSES {
  LOADING = 'loading',
  SUCCESS = 'success',
  NO_DATA = 'no_data'
}

const PalletesFields = () => {

  const { t } = useTranslation()

  const { register, getValues, formState: { errors } } = useFormContext<PackageForm>()

  const [ defaultPalletType, setDefaultPalletType ] = useState<Option>()

  const [ defaultPalletTypeRequestStatus, setDefaultPalletTypeRequestStatus ] = useState<STATUSES>(STATUSES.LOADING)

  const [ hasTwoTiers, setHasTwoTiers ] = useState(false);

  const errorValue = t('errors__message__incorrectValueTwoDecimalsAfterDot');

  const [ palletesRequest ] = useLazyGetPalletesQuery()

  const userPalletType = getValues()[PACKAGE_FORM.PALLETE_TYPE]

  useEffect(() => {
    if (userPalletType) {
      palletesRequest("")?.then((response) => {
        const currentPalletType = response.data.find((item: PalletOption) => item.code === userPalletType);
        setDefaultPalletType({
          value: currentPalletType.code,
          label: currentPalletType.name,
        })
        setDefaultPalletTypeRequestStatus(STATUSES.SUCCESS);
      }).catch(() => setDefaultPalletTypeRequestStatus(STATUSES.NO_DATA));
      setHasTwoTiers(Boolean(getValues()[PACKAGE_FORM.TWO_TIERS_ALLOWED]))
    } else {
      setDefaultPalletTypeRequestStatus(STATUSES.NO_DATA);
    }
  }, [ userPalletType ])


  const shouldShowPalletsType = [ STATUSES.SUCCESS, STATUSES.NO_DATA ].includes(defaultPalletTypeRequestStatus);
  return <>
    {shouldShowPalletsType && <PalletesType defaultPalletType={defaultPalletType} />}
    <div className='add-product__palletes-fields'>
      <TextField
        {...register(PACKAGE_FORM.PALLETE_CNT, { required: true, validate: (value?: number) => value ? checkDecimal(value, t('errors__message__incorrectValue')) : t('errors__message__incorrectValue'), min: { value: 1, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.PALLETE_CNT])}
        helperText={errors[PACKAGE_FORM.PALLETE_CNT]?.message}
        variant='outlined'
        label={t('calc__text__packaging__numberOfPalletes')}
        InputProps={{
          endAdornment: ADORNMENTS.AMOUNT,
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        {...register(PACKAGE_FORM.PALLETE_HEIGHT, { required: true, validate: (value?: number) => value ? checkTwoDecimalsAfterDot(value, errorValue) : t('errors__message__incorrectValueTwoDecimalsAfterDot'), min: { value: 0, message: t('errors__message__incorrectValueTwoDecimalsAfterDot')} })}
        error={Boolean(errors[PACKAGE_FORM.PALLETE_HEIGHT])}
        helperText={errors[PACKAGE_FORM.PALLETE_HEIGHT]?.message}
        variant='outlined'
        label={t('calc__text__packaging__heightOfThePallet')}
        InputProps={{
          endAdornment: ADORNMENTS.SIZE_SMALL,
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        {...register(PACKAGE_FORM.PALLETE_WEIGHT, { required: true, validate: (value?: number) => value ? checkTwoDecimalsAfterDot(value, errorValue) : t('errors__message__incorrectValueTwoDecimalsAfterDot'), min: { value: 0, message: t('errors__message__incorrectValueTwoDecimalsAfterDot')} })}
        error={Boolean(errors[PACKAGE_FORM.PALLETE_WEIGHT])}
        helperText={errors[PACKAGE_FORM.PALLETE_WEIGHT]?.message}
        variant='outlined'
        label={t('calc__text__packaging__weightOfThePallet')}
        InputProps={{
          endAdornment: ADORNMENTS.WEIGHT,
        }}
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <div className='add-product__palletes-two_tiers'>
      <FormControlLabel
        className={`add-product__${PACKAGE_FORM.TWO_TIERS_ALLOWED}`}
        {...register(PACKAGE_FORM.TWO_TIERS_ALLOWED)}
        control={<Checkbox checked={hasTwoTiers} />}
        onChange={() => setHasTwoTiers(!hasTwoTiers)}
        label={t('calc__text__packaging__twoTiersAllowed')}
      />
    </div>
  </>

}

export default PalletesFields
