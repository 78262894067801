import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {useTranslation} from "react-i18next";
import {COLORS} from "../../../../../../../config/constants";
import Link from "@mui/material/Link";
import ServiceCheckbox from './serviceCheckbox';
import {useCurrentRoute} from "../../../../../../../api/calc";

const enum ServiceStatus {
  ACTIVE_SERVICE = 1,
  ADDITIONAL_SERVICE = 2,
}

type Props = {
  isActive?: boolean
  defaultExpanded: boolean
  services: AddService[]
  documents: FreightDocument[]
  routeId: number
  handleClickAccordion(value: boolean): void
  handleChangeServices(service: number, routeId: number): void
}

const Services = ({isActive, services, handleChangeServices, handleClickAccordion, routeId, documents, defaultExpanded} : Props) => {
  const { t } = useTranslation()

  const currentRoute = useCurrentRoute()

  const [ isExpanded, setIsExpanded ] = useState(defaultExpanded)

  useEffect(() => {
    setIsExpanded(defaultExpanded)
  }, [ defaultExpanded ])

  const activeServices: AddService[] = [];
  const addServices: AddService[] = [];

  services.map(service => {
    if (service.id) {
      addServices.push(service)
    } else {
      activeServices.push(service)
    }
  })

  return <Accordion sx={{
    boxShadow: 'none',
    background: isActive ? COLORS.LIME : COLORS.WHITE,
    marginBottom: 0,
    padding: '6px 24px 0',
    '&.Mui-expanded': {
      marginBottom: 3,
    }
  }}
  className='delivery-card__accordion'
  expanded={isExpanded}
  onChange={() => {
    handleClickAccordion(!isExpanded)
    setIsExpanded(!isExpanded)
  }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{ padding: 0}}
    >
      <Typography variant='body1'>
        {t('calc__text__delivery__tariffTermsAndAdditionalServices')}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: 0 }}>
      {(activeServices.length > 0) && (<>
        <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 1 }}>
          {t('common__text__tariff')}
        </Typography>
        {activeServices.map((service, index) =>
          <ServiceCheckbox
            service={service}
            key={index}
            handleChangeServices={handleChangeServices}
            currentRoute={currentRoute}
            routeId={routeId}
          />)}
      </>)}
      {documents.map(document => (
        <Typography variant='body2' sx={{ marginTop: '10px' }} key={document.title}>
          <Link href={document.link} target='_blank'>{document.title}</Link>
        </Typography>
      ))}
      {(addServices.length > 0) && <>
        <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 1,marginTop: 2 }}>
          {t('common__text__additionalServices')}
        </Typography>
        {addServices.map((service, index) => <ServiceCheckbox service={service} key={index} handleChangeServices={handleChangeServices} currentRoute={currentRoute} routeId={routeId} />)}
      </>}
      {activeServices.length === 0 && addServices.length === 0 && (
        <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 1 }}>
          {t('calc__text__delivery__noAdditionalServices')}
        </Typography>
      )}
    </AccordionDetails>
  </Accordion>
}

export default Services
