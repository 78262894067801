import React, {useEffect} from "react";
import Edit from './components/edit';
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {
  useCurrentRoute,
  useGetAdditionalServicesByOrderIdQuery,
  useSetCompletedByOrderIdMutation,
  useSummaryCompleted,
} from "../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";

type Props = {
  handleComplete(): void
}

const Additional = ({handleComplete}: Props) => {

  const orderId = useGetCalcIdFromRoute()

  const { t } = useTranslation()

  const summaryCompleted = useSummaryCompleted()

  const isCompleted = summaryCompleted?.additional_services;

  const currentRoute = useCurrentRoute()

  const { data: additionalServices, isSuccess } = useGetAdditionalServicesByOrderIdQuery(orderId)

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  useEffect(() => {
  }, [ currentRoute?.selected_services ]);

  const expenses: AdditionalService[] = [];
  const services: AdditionalService[] = [];

  additionalServices?.map((additionalService: AdditionalService) => {
    if (additionalService.id) {
      services.push(additionalService)
    } else {
      expenses.push(additionalService)
    }
  })

  const hasServices = services.length > 0;

  useEffect(() => {
    if (!hasServices && isSuccess) {
      setCompleted({
        orderId,
        additional_services: true
      })
    }
  }, [ hasServices ]);

  const getActualServices = () => {
    const selectedServices: number[] | undefined = currentRoute?.selected_services
    if (!selectedServices) {
      return t('calc__text__additional__noAdditionalServices')
    }

    const getName = (accumulator: string, name: string, cost: number) => accumulator ? `${accumulator}, ${name} — ${cost} USD` : `${name} — ${cost} USD`

    const userServices = services.reduce(
      (accumulator, service) => {
        if (!service.id) {
          return '';
        }
        return selectedServices.includes(service.id) ? getName(accumulator, service.name, service.cost) : ''
      },
      ''
    );

    return userServices || t('calc__text__additional__noAdditionalServices')
  };

  const info = [
    {
      label: t('common__text__additionalServices'),
      value: hasServices ? getActualServices() : t('common__text__nonExistent'),
    }
  ]

  const commonPart = <div className='additional'>
    <Divider />
    <Typography variant='body1' sx={{
      fontWeight: 500,
      marginTop: 3,
    }}>
      {t('calc__text__additional__possibleAdditionalCosts')}
    </Typography>
    {expenses.length > 0 ? expenses.map((item: AdditionalService, index: number) => <Typography variant='body1' key={index} sx={{ marginTop: '4px'}}>
      {item.name} — <b>{`${item.cost} USD`}</b>
    </Typography>) : <Typography variant='body1' sx={{ marginTop: '4px'}}>
      {t('common__text__nonExistent')}
    </Typography>}
  </div>

  return <>
    {commonPart}
    <Edit services={services} handleComplete={handleComplete} isCompleted={isCompleted} />
  </>
}

export default Additional
