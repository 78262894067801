import React from "react";

// @ts-ignore
import Flag from 'react-world-flags'

import {COLORS} from "../../../../../config/constants";

type FlagProps = {
    code?: string
    noMargin?: boolean
    isSmall?: boolean
}

export const getFlag = ({ code, noMargin, isSmall }: FlagProps) => {

  const marginValue = isSmall ? '4px' : '10px';

  return <Flag
    code={code || ''}
    style={{
      height: isSmall ? '16px' : '24px',
      marginRight: noMargin ? 0 : marginValue,
      border: `1px solid ${COLORS.DARK_GRAY}`,
      boxSizing: 'content-box',
    }}
    svg='true'
  />
}
