import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {useFormContext} from "react-hook-form";
import { PACKAGE_FORM, PackageForm } from '../add_form/helpers';
import {checkTwoDecimalsAfterDot} from '../../../../../../../../../utils/validation'
import {ADORNMENTS} from "../../../../../../../../../config/constants";

const PackageItem = () => {

  const { t } = useTranslation()

  const { register, formState, getValues, trigger } = useFormContext<PackageForm>()

  const { errors } = formState;

  const errorValue = t('errors__message__incorrectValueTwoDecimalsAfterDot');

  const checkGrossWeightCartoonValueIsCorrect = (value: number) => {
    const netValue = getValues()[PACKAGE_FORM.NET_WEIGHT_CARTOON]

    const hasTwoDecimalsError = checkTwoDecimalsAfterDot(value, errorValue);

    if (hasTwoDecimalsError !== true) {
      return hasTwoDecimalsError
    }

    return Number(value) > Number(netValue) ? true: t('errors__message__incorrectValueGrossWeight');

  }

  return <>
    <div className='add-product__row'>
      <TextField
        className={`add-product__${PACKAGE_FORM.LENGTH}`}
        {...register(PACKAGE_FORM.LENGTH, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorValue), min: { value: 0, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.LENGTH])}
        helperText={errors[PACKAGE_FORM.LENGTH]?.message}
        variant='outlined'
        label={t('calc__text__packaging__lengthOfPackage')}
        // disabled={isLoading}
        InputProps={{
          endAdornment: ADORNMENTS.SIZE,
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        className={`add-product__${PACKAGE_FORM.WIDTH}`}
        {...register(PACKAGE_FORM.WIDTH, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorValue), min: { value: 0, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.WIDTH])}
        helperText={errors[PACKAGE_FORM.WIDTH]?.message}
        variant='outlined'
        label={t('calc__text__packaging__widthOfPackage')}
        // disabled={isLoading}
        InputProps={{
          endAdornment: ADORNMENTS.SIZE,
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        className={`add-product__${PACKAGE_FORM.HEIGHT}`}
        {...register(PACKAGE_FORM.HEIGHT, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorValue), min: { value: 0, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.HEIGHT])}
        helperText={errors[PACKAGE_FORM.HEIGHT]?.message}
        variant='outlined'
        label={t('calc__text__packaging__heightOfPackage')}
        // disabled={isLoading}
        InputProps={{
          endAdornment: ADORNMENTS.SIZE,
        }}
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <TextField
      className={`add-product__${PACKAGE_FORM.NET_WEIGHT_CARTOON}`}
      {...register(PACKAGE_FORM.NET_WEIGHT_CARTOON, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorValue), min: { value: 0, message: t('errors__message__incorrectValue')} })}
      error={Boolean(errors[PACKAGE_FORM.NET_WEIGHT_CARTOON])}
      helperText={errors[PACKAGE_FORM.NET_WEIGHT_CARTOON]?.message}
      variant='outlined'
      label={t('common__text__netWeightPackaging')}
      // disabled={isLoading}
      InputProps={{
        endAdornment: ADORNMENTS.WEIGHT,
      }}
      InputLabelProps={{ shrink: true }}
      onBlur={async () => {
        if (getValues(PACKAGE_FORM.GROSS_WEIGHT_CARTOON) && getValues(PACKAGE_FORM.NET_WEIGHT_CARTOON)) {
          await trigger([ PACKAGE_FORM.GROSS_WEIGHT_CARTOON ])
        }
      }}
    />
    <TextField
      className={`add-product__${PACKAGE_FORM.GROSS_WEIGHT_CARTOON}`}
      {...register(PACKAGE_FORM.GROSS_WEIGHT_CARTOON, { required: true, validate: (value: number) => checkGrossWeightCartoonValueIsCorrect(value), min: { value: 0, message: t('errors__message__incorrectValue')} })}
      error={Boolean(errors[PACKAGE_FORM.GROSS_WEIGHT_CARTOON])}
      helperText={errors[PACKAGE_FORM.GROSS_WEIGHT_CARTOON]?.message}
      variant='outlined'
      label={t('common__text__grossPackagingWeight')}
      // disabled={isLoading}
      InputProps={{
        endAdornment: ADORNMENTS.WEIGHT,
      }}
      InputLabelProps={{ shrink: true }}
    />
  </>
}

export default PackageItem
