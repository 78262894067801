import { DatePicker } from '@mui/x-date-pickers-pro';
import {useFormContext} from "react-hook-form";
import {INVOICE_FORM, InvoiceForm} from "../editInvoice";
import {useTranslation} from "react-i18next";
import moment, { Moment } from 'moment'
import 'moment/locale/ru';
import {useState} from "react";

const localLocale = moment();

localLocale.locale('ru');

const DateInput  = () => {

  const { t } = useTranslation()

  const { register, getValues, setValue } = useFormContext<InvoiceForm>()

  const [ date, setDate ] = useState<Moment | null>(moment(getValues()[INVOICE_FORM.INVOICE_DATE] || ''));
  const [ error, setError ] = useState(false);

  const handleValidateDatePicker = async (value: Moment | null) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');
    setDate(value);
    value && setValue(INVOICE_FORM.INVOICE_DATE, formattedDate)
    setError(formattedDate === 'Invalid date' ? true: Boolean(moment().diff(moment(value)) < 0))
  }

  return (
    <DatePicker
      label={t('calc__text__main__dateOfInvoice')}
      {...register(INVOICE_FORM.INVOICE_DATE, { required: true })}
      value={date}
      disableFuture
      onChange={(value) => handleValidateDatePicker(value)}
      slotProps={{
        textField: {
          error,
          helperText: error ? t('errors__message__incorrectDate') : ''},
      }}
    />
  )
}

export default DateInput
