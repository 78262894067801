import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";

import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputAdornment from "@mui/material/InputAdornment";

import './../auth.scss'
import './register.scss'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import { setToken, useSignInMutation, useSignUpMutation } from "../../../api/auth";
import { useAuthContext } from "../../../context/AuthContext";
import Notification, {NOTIFICATION_TYPE} from "../../common/notification";
import {ROUTES} from "../../../config/constants";
import {EMAIL_PATTERN} from "../../../utils/validation";

enum REGISTER_FORM {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD2 = 'password2',
  AGREED = 'agreed',
}

type RegisterForm = {
  first_name: string
  last_name: string
  email: string
  password: string
  password2: string
  agreed: boolean
}

const Register = () => {

  const { t } = useTranslation()

  const navigate = useNavigate();

  const { setUser, user, isSuccess } = useAuthContext();

  useEffect(() => {
    if (isSuccess && user) {
      navigate(ROUTES.CABINET)
    }
  }, [ user, isSuccess ])

  const [ signUp ] = useSignUpMutation()

  const [ signIn ] = useSignInMutation()

  const [ isVisiblePassword, setIsVisiblePassword ] = useState(false);
  const [ isVisiblePasswordConfirm, setIsVisiblePasswordConfirm ] = useState(false);

  const [ isLoading, setIsLoading ] = useState(false);

  const [ commonError, setCommonError ] = useState(false);

  const { register, watch, getValues, handleSubmit, trigger, setError, clearErrors, formState: { errors, isValid } } = useForm<RegisterForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      [REGISTER_FORM.AGREED]: true,
    }
  })

  const checkIsValid = () => {
    const agreedValue = watch(REGISTER_FORM.AGREED) ?? false
    return isValid && agreedValue
  }

  const validatePasswordConfirm = (value: string) => value === getValues(REGISTER_FORM.PASSWORD) ? true: 'Пароли не совпадают'

  const onSubmit = handleSubmit (async (values) => {
    setIsLoading(true);

    try {
      const response: any = await signUp(values)

      if (response?.error) {
        if (response.error.data.email) {
          setError(REGISTER_FORM.EMAIL, { type: "custom",  message: response.error.data.email[0] })
        }
        if (response.error.data.password) {
          setError(REGISTER_FORM.PASSWORD, { type: "custom",  message: response.error.data.password[0] })
        }
        throw response?.error;
      } else {

        clearErrors([ REGISTER_FORM.EMAIL, REGISTER_FORM.PASSWORD ])

        const authResponse: any = await signIn({
          email: values.email,
          password: values.password,
        })

        if (authResponse?.error) {
          throw authResponse?.error;
        } else {
          // set the token
          setToken(authResponse?.data.jwt);

          // set the user
          setUser(authResponse?.data.user);

          navigate(`/${ROUTES.CABINET}`, { replace: true });

        }

      }
    } catch (error) {
      setCommonError(true);
    } finally {
      setIsLoading(false);
    }
  })

  return (
    <form className='auth registration' onSubmit={onSubmit}>
      <Card>
        <CardContent className='auth__card'>
          <Typography variant='h5' gutterBottom>
            {t('common__text__registration')}
          </Typography>
          <Typography variant='body2' gutterBottom>
            {t('actions__message__getStarted')}
          </Typography>
          <div className='registration__form'>
            <TextField
              {...register(REGISTER_FORM.FIRST_NAME, { required: 'Поля обязательно для заполнения' })}
              error={Boolean(errors[REGISTER_FORM.FIRST_NAME])}
              variant='outlined'
              label={t('common__text__firstName')}
              helperText={errors[REGISTER_FORM.FIRST_NAME]?.message}
            />

            <TextField
              {...register(REGISTER_FORM.LAST_NAME, { required: t('common__text__isRequired') })}
              error={Boolean(errors[REGISTER_FORM.LAST_NAME])}
              variant='outlined'
              label={t('common__text__lastName')}
              helperText={errors[REGISTER_FORM.LAST_NAME]?.message}
            />

            <TextField
              {...register(REGISTER_FORM.EMAIL, { required: t('common__text__isRequired'), pattern: { value: EMAIL_PATTERN, message: t('errors__message__incorrectEmail')} })}
              error={Boolean(errors[REGISTER_FORM.EMAIL])}
              variant='outlined'
              label={t('common__text__email')}
              className='registration__email'
              helperText={errors[REGISTER_FORM.EMAIL]?.message}
            />

            <TextField
              {...register(REGISTER_FORM.PASSWORD, {
                required: t('common__text__isRequired'),
                minLength: { value: 8, message: `8 ${t('common__text__minLength')}`},
              })}
              error={Boolean(errors[REGISTER_FORM.PASSWORD])}
              variant='outlined'
              label={t('common__text__password')}
              type={isVisiblePassword? 'text' : 'password'}
              helperText={errors[REGISTER_FORM.PASSWORD]?.message}
              InputProps={{
                endAdornment: <InputAdornment position='start' onClick={() => setIsVisiblePassword(!isVisiblePassword)} className='auth__input-adornment'>{isVisiblePassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</InputAdornment>,
              }}
              onBlur={async () => {
                if (getValues(REGISTER_FORM.PASSWORD2)) {
                  await trigger([ REGISTER_FORM.PASSWORD2 ])
                }
              }}
            />

            <TextField
              {...register(REGISTER_FORM.PASSWORD2, {
                required: t('common__text__isRequired'),
                minLength: { value: 8, message: `8 ${t('common__text__minLength')}` },
                validate: value => validatePasswordConfirm(value)
              })}
              error={Boolean(errors[REGISTER_FORM.PASSWORD2])}
              variant='outlined'
              label={t('common__text__confirmPassword')}
              type={isVisiblePasswordConfirm? 'text' : 'password'}
              helperText={errors[REGISTER_FORM.PASSWORD2]?.message}
              InputProps={{
                endAdornment: <InputAdornment position='start' onClick={() => setIsVisiblePasswordConfirm(!isVisiblePasswordConfirm)} className='auth__input-adornment'>{isVisiblePasswordConfirm ? <VisibilityOffIcon/> : <VisibilityIcon/>}</InputAdornment>,
              }}
            />

          </div>
          <FormGroup>
            <FormControlLabel
              {...register(REGISTER_FORM.AGREED)}
              control={<Checkbox defaultChecked />}
              label={<>{t('actions__text__iAccept')} <Link href='' className='auth__forget-password'>{t('actions__text__termsOrConditions')}</Link></>}
            />
          </FormGroup>
          <Button
            variant='contained'
            sx={{
              marginTop: '12px',
              marginBottom: '4px',
            }}
            disabled={!checkIsValid() || isLoading}
            type='submit'
          >{isLoading ? t('common__message__loadingText') : t('common__text__registration')}</Button>
          <Typography variant='subtitle1' gutterBottom align='center'>
            {t('actions__message__alreadyAMember')} <Link href='/login'>{t('actions__text__login')}</Link>
          </Typography>
          {commonError && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__commonError')} />}
        </CardContent>
      </Card>
    </form>
  )
}

export default Register
