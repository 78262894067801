import React, {useState} from "react";

import {useTranslation} from "react-i18next";

import AddForm from '../add_form';

import Completed from "../../../../../../completed";
import ModalBig from "../../../../../../common/modalBig";

type Props = {
  isCompleted: boolean,
  declaration: Declaration
  index: number
  count: number
  feesTotal: number
  onSave(): void
  setIsConfirmButtonDisable(value: boolean): void
}

const CustomsDeclaration = ( { isCompleted, declaration, index, onSave, count, feesTotal, setIsConfirmButtonDisable } : Props ) => {

  const { t } = useTranslation()

  const [ shouldOpenModal, setShouldOpenModal ] = useState(false)

  const info = [
    {
      label: `${t('declension__text__HSCodeOne')} ${declaration.code}`,
      value: declaration.product_names.join(', '),
      isStrong: true,
    },
    {
      label: t('common__text__vatRate'),
      value: `${declaration.vat_rate}%`,
    },
    {
      label: t('calc__text__customs__customsCost'),
      value: `${declaration.lowin} USD`
    },
    {
      label: t('calc__text__customs__certificateOfOrigin'),
      value: declaration.origin_certificate ? t('common__text__yes') : t('common__text__no')
    },
    {
      label: t('calc__text__customs__dutyRate'),
      value: declaration.import_tax?.description
    },
    {
      label: t('common__text__customsPayment'),
      value:  `${declaration.subtotal} USD`,
      isStrong: true,
    },
  ]

  if (isCompleted) {
    return  <>
      <Completed info={info} onEdit={() => setShouldOpenModal(true)} hasTitle twoRows />
      {shouldOpenModal && <ModalBig
        isOpen
        onClose={() => setShouldOpenModal(false)}
        title={`${t('calc__text__customs__editDeclaration')}` }
      >
        <AddForm
          onCancel={() => setShouldOpenModal(false)}
          index={index}
          declaration={declaration}
          onSave={onSave}
          count={count}
          feesTotal={feesTotal}
          insideModal
        />
      </ModalBig>}
    </>
  }

  return <AddForm
    index={index}
    declaration={declaration}
    onSave={onSave}
    count={count}
    feesTotal={feesTotal}
    setIsConfirmButtonDisable={setIsConfirmButtonDisable}
  />;

}

export default CustomsDeclaration
