import React, {ReactNode, useState} from "react";

import {useTranslation} from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Tooltip from "../../../../../../../../common/tooltip";
import EditIcon from "@mui/icons-material/Edit";
import COLORS from 'styles/colors.scss'
import IconButton from "@mui/material/IconButton";

import './../../summary.scss'

import {INFORMERS_CODES} from "../../../../../../../../../config/constants";
import {useInformers} from "../../../../../../../../../api/informers";
import ModalBig from "../../../../../../../../common/modalBig";
import EditContainers from "../edit_containers";
import { SummaryData } from "../../index";
import Notification, {NOTIFICATION_TYPE} from "../../../../../../../../common/notification";

export const enum CONTAINERS_FORM {
    FT_20 = 'type_20dc',
    FT_40 = 'type_40dc',
    FT_40_HIGH_CUBE = 'type_40hc',
}

type Props =  {
    summary?: ContainersManual
    isManual?: boolean
    renderSummary(data: SummaryData): void
    handleEditSave?(): void
    handleSetAutoContainers?(): void
    showWarningNotificationContainers?: boolean
}

const Containers = ({summary, renderSummary, handleEditSave, handleSetAutoContainers, showWarningNotificationContainers, isManual}: Props) => {

  const { t } = useTranslation()

  const informers = useInformers();

  const getItemsContainers = () => [
    {
      label: '20 Ft',
      value: summary?.[CONTAINERS_FORM.FT_20] ?? 0
    },
    {
      label: '40 Ft',
      value: summary?.[CONTAINERS_FORM.FT_40] ?? 0
    },
    {
      label: '40 Ft High cube',
      value: summary?.[CONTAINERS_FORM.FT_40_HIGH_CUBE]?? 0
    }
  ]

  return <div className='summary-packaging__block-delivery'>
    {handleEditSave && <div className='summary-packaging__buttons'>
      <span className='summary-packaging__tooltipContainer'>
        <Tooltip content={informers[INFORMERS_CODES.CONTAINER_CNT]} />
      </span>
      <IconButton sx={{ margin: '0 0 0 -6px' }}>
        <EditIcon sx={{ color: COLORS.LIGHT_GRAY }} />
      </IconButton>
    </div>}
    <>
      {renderSummary({ title: <b>{t('calc__text__packaging__calculatedNumberOfContainers')}</b>, items: getItemsContainers(), hasChip: true})}
      {showWarningNotificationContainers && <Notification
        type={NOTIFICATION_TYPE.WARNING}
        title={t('common__message__warning')}
        text={t('calc__text__packaging__containersDoNotFitError')}
        sx={{ marginTop: 2 }}
        noCloseButton
      />}
      <Button
        variant='outlined'
        fullWidth
        onClick={handleSetAutoContainers}
        sx={{ marginTop: 4 }}
      >{isManual ? t('calc__text__packaging__calculateTheValueAutomatically') : t('calc__text__packaging__calculateTheValueManually')}</Button>
    </>
  </div>
}

export default Containers
