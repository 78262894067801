import React, { ReactNode } from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {useTranslation} from "react-i18next";

import {getPluralForm} from "../../../../../../../utils/validation";

import './summary.scss'

type SummaryData = {
  items: {
    label: ReactNode
    value: ReactNode
  }[]
}

type Props = {
  summary: DeclarationSummaryParamsOutput,
}


const Summary = ( {summary} : Props) => {

  const { t } = useTranslation()

  const renderSummary = ({ items } : SummaryData) => <>
    <ul className='summary-customs__total'>
      {items.map((item, key) => (
        <li key={key}>
          <Typography variant='caption'>
            {item.label}
          </Typography>
          <Typography variant='subtitle1'>
            {item.value}
          </Typography>
        </li>
      ))}
    </ul>
  </>

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];
  const CODE_DECLENSIONS: PluralForms = [ t('declension__text__HSCodeOne'), t('declension__text__HSCodeSeveral'), t('declension__text__HSCodeMany') ];
  const DECLARATION_DECLENSIONS: PluralForms = [ t('declension__text__customsDeclarationOne'), t('declension__text__customsDeclarationSeveral'), t('declension__text__customsDeclarationMany') ];

  const infoMain = [
    {
      label: t('calc__text__customs__brokerCommission'),
      value: <>{summary.bro_total} USD</>
    },
    {
      label: t('calc__text__customs__permissionDocumentation'),
      value:  <>ДС 3Д</>
    },
    {
      label: t('calc__text__customs__customsValuePerKg'),
      value:  <>50 USD</>
    }
  ]

  return <div className='summary-customs'>
    <Typography variant='h6'>
      {t('calc__text__customs__preliminaryDataOnCustomsPayments')}
    </Typography>
    <Typography variant='subtitle1'>
      <b>{summary.product_count}</b> {getPluralForm(summary.product_count, PRODUCTS_DECLENSIONS)},&nbsp;
      <b>{summary.code_count}</b> {getPluralForm(summary.code_count, CODE_DECLENSIONS)},&nbsp;
      <b>{summary.declaration_count}</b> {getPluralForm(summary.declaration_count, DECLARATION_DECLENSIONS)},&nbsp;
      <b>{summary.tax_total} USD</b> {t('calc__text__customs__customsDuty')}
    </Typography>
    <Divider sx={{ marginTop: 3}} />
    {renderSummary({ items: infoMain})}
    <Divider sx={{ marginBottom: 3}} />
    <Typography variant='subtitle1'>
      {`${t('calc__text__customs__totalCostOfCustomsPayments')} — `}
      <b>{summary.fees_total} USD</b>
    </Typography>
  </div>
}

export default Summary
