import React from "react";
import './summary.scss';
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import SummaryInvoice from './components/summary-invoice';
import {useBankCommissions} from "../../../../../../../api/calc";

const Summary = () => {

  const { t } = useTranslation()

  const bankCommissionsInvoice = useBankCommissions();

  if (!bankCommissionsInvoice?.invoices?.length){
    return null;
  }

  const bankCommissionsOrderSummary = bankCommissionsInvoice.order_summary;

  const compare = (a: BankCommissionsInvoice, b: BankCommissionsInvoice) => a.id > b.id ? 1 : -1;

  const sortedBankCommissionsInvoices = bankCommissionsInvoice?.invoices?.slice().sort(compare);

  return <div className='payments-summary'>
    <Typography variant='h6' sx={{ marginBottom: 3 }}>
      {t('calc__text__bankCommissions__preliminaryDataOnPaymentAndCommission')}
    </Typography>
    <hr className='payments-summary__hr'/>
    <div>
      {sortedBankCommissionsInvoices?.map((invoice, index) =>
        <SummaryInvoice invoice={invoice} key={invoice.id} index={index}/>)}
    </div>
    <div className='payments-summary__total'>
      <Typography variant='subtitle1'>
        {t('calc__text__bankCommissions__payment')}: <span>{bankCommissionsOrderSummary.total_payment_amount} USD</span>
      </Typography>
      <Typography variant='subtitle1' sx={{ marginTop: 1 }}>
        {t('calc__text__bankCommissions__finalCommissionOfTheBank')}: <span>{bankCommissionsOrderSummary?.total_banking_commissions_usd} USD</span>
      </Typography>
      <Typography variant='subtitle1' sx={{ marginTop: 1 }}>
        {t('calc__text__bankCommissions__finalCurrencyControlCommission')}: <span>{bankCommissionsOrderSummary?.total_currency_control_fees_usd}  USD</span>
      </Typography>
      <Typography variant='subtitle1' sx={{ marginTop: 1 }}>
        {t('calc__text__bankCommissions__totalToBePaid')}: <span>{bankCommissionsOrderSummary?.total_payment_amount_with_commissions}  USD</span>
      </Typography>
    </div>
  </div>
}

export default Summary
