import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { METHODS } from '../../index'
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";

type Props =  {
    deliveryMethod: number | null
    isModal?: boolean
    defaultValue?: number
    setDeliveryMethod(value: number | null): void
}

const DeliveryType = ({deliveryMethod, setDeliveryMethod, isModal, defaultValue}: Props) => {

  const { t } = useTranslation()

  return <FormControl>
    {isModal ? <Typography variant='h6'>{t('calc__text__packaging__deliveryMethod')}</Typography> : <FormLabel id='delivery-method-group-label'>{t('calc__text__packaging__deliveryMethod')}</FormLabel>}
    <RadioGroup
      aria-labelledby='delivery-method-group-label'
      name='radio-buttons-group'
      sx={{ marginTop: '12px' }}
      value={deliveryMethod}
      defaultValue={defaultValue}
      onChange={({ target: { value: type } }) => setDeliveryMethod(Number(type))}
    >
      <FormControlLabel sx={{ marginLeft: 0 }} value={METHODS.COMBINED_CARGO} control={<Radio />} label={t('calc__text__packaging__combinedCargo')} />
      <FormControlLabel sx={{ marginLeft: 0 }} value={METHODS.CONTAINERS} control={<Radio />} label={t('calc__text__packaging__containers')} />
    </RadioGroup>
  </FormControl>
}

export default DeliveryType
