import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useForm, FormProvider } from "react-hook-form";
import {useTranslation} from "react-i18next";
import IncotermInput from '../incotermsInput'
import LocationInput from '../locationInput'
import DateInput from '../dateInput'
import './editInvoice.scss'
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";
import {
  useCreateInvoiceMutation,
  useGetInvoices,
  useUpdateInvoiceMutation,
} from "../../../../../../../../../api/calc";
import React, {useEffect, useState} from "react";
import get from "lodash/get";
import Notification, {NOTIFICATION_TYPE} from "../../../../../../../../common/notification";
import cx from "classnames";
import findIndex from "lodash/findIndex";
import {INFORMERS_CODES} from "../../../../../../../../../config/constants";
import { useLazyGetCityQuery } from "../../../../../../../../../api/dictionary";
import {InputAdornment} from "@mui/material";
import Tooltip from "../../../../../../../../common/tooltip";
import {useInformers} from "../../../../../../../../../api/informers";

export const enum INVOICE_FORM {
  INVOICE_NUM = 'invoice_num',
  INVOICE_DATE = 'invoice_date',
  INCOTERMS = 'incoterms',
  LOCATION_CITY = 'location_city',
}

export type InvoiceForm = {
  [INVOICE_FORM.INVOICE_NUM]: string
  [INVOICE_FORM.INVOICE_DATE]: string
  [INVOICE_FORM.INCOTERMS]: string
  [INVOICE_FORM.LOCATION_CITY]: string
}

type Props = {
  handleSuccess: () => void
  id?: number
  noBorder?: boolean
  hasCancelButton?: boolean
  onClose?(): void
}

type OptionData = {
  label: string
}

type LocationItemDefaultCity = LocationItem & OptionData;

const EditInvoice = ( { handleSuccess, id, noBorder, hasCancelButton, onClose } : Props ) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute();

  const invoices = useGetInvoices()

  const currentInvoice = invoices?.find(invoice => invoice.id === id)

  const [ defaultCity, setDefaultCity ] = useState<LocationItemDefaultCity>()

  const defaultCityName = currentInvoice?.[INVOICE_FORM.LOCATION_CITY]

  useEffect(() => {
    if (defaultCityName){
      setDefaultCity({...defaultCityName, label: `${defaultCityName.name}, ${defaultCityName.country.name}`})
    }
  }, [ defaultCityName ])

  const [ showErrorNotification, setShowErrorNotification ] = useState<boolean | undefined>(false);

  const defaultValues = {
    [INVOICE_FORM.INVOICE_NUM]: currentInvoice?.[INVOICE_FORM.INVOICE_NUM] || '',
    [INVOICE_FORM.INVOICE_DATE]: currentInvoice?.[INVOICE_FORM.INVOICE_DATE] || '',
    [INVOICE_FORM.INCOTERMS]: currentInvoice?.[INVOICE_FORM.INCOTERMS] || '',
    [INVOICE_FORM.LOCATION_CITY]: currentInvoice?.[INVOICE_FORM.LOCATION_CITY].id || '',
  };

  const methods = useForm<InvoiceForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  })

  const { register, reset, getValues, watch, handleSubmit, formState: { errors, isValid } } = methods

  const [ createInvoice ] = useCreateInvoiceMutation()

  const [ updateInvoice ] = useUpdateInvoiceMutation()

  const invoiceNum = getValues()[INVOICE_FORM.INVOICE_NUM];

  const onSubmit = handleSubmit(async values => {

    const result = id ? await updateInvoice({ orderId, invoiceId: id, ...values}) : await createInvoice({ id: orderId, ...values})

    const invoiceId = get(result, 'data.id', '')
    if (!invoiceId) {
      setShowErrorNotification(true);
      return
    }
    setShowErrorNotification(false);
    handleSuccess()
    reset()
  })

  const getInvoiceNumber = () => {
    if (currentInvoice && invoices) {
      return findIndex(invoices, (invoice) => invoice.id === id) + 1
    }

    return invoices ? invoices.length + 1 : 1
  }

  const informers = useInformers();

  const watchAllFields = watch()

  const saveButtonIsActive = Boolean(watchAllFields[INVOICE_FORM.INVOICE_NUM])
  && Boolean(watchAllFields[INVOICE_FORM.INVOICE_DATE])
  && watchAllFields[INVOICE_FORM.INVOICE_DATE] !== 'Invalid date'
  && Boolean(watchAllFields[INVOICE_FORM.INCOTERMS])
  && Boolean(watchAllFields[INVOICE_FORM.LOCATION_CITY])

  return (
    <FormProvider {...methods}>
      <form className={cx({
        'calculator__group': true,
        'calculator__group_noBorder': noBorder,
      })} onSubmit={onSubmit}>
        <Typography variant='h6' className='calculator__group-title'>{t('common__text__invoice')}-{getInvoiceNumber()}</Typography>
        {!currentInvoice && <Notification
          type={NOTIFICATION_TYPE.INFO}
          title={t('calc__text__main__invoiceDetails')}
          text={t('calc__text__main__invoiceDetailsNotification')}
          sx={{ marginBottom: 4}}
        />}
        <div className='invoice'>
          <TextField
            {...register(INVOICE_FORM.INVOICE_NUM, { required: true}) }
            error={Boolean(errors[INVOICE_FORM.INVOICE_NUM])}
            helperText={errors[INVOICE_FORM.INVOICE_NUM]?.message}
            variant='outlined'
            label={t('calc__text__main__numOfInvoice')}
            className={`calculator__group-${INVOICE_FORM.INVOICE_NUM}`}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position='end' sx={{ right: '20px', position: 'absolute'}}><Tooltip content={informers?.[INFORMERS_CODES.INVOICE_NUM]} /></InputAdornment>
            }}
          />
          <DateInput />
          <IncotermInput/>
          {defaultCityName && defaultCity && <LocationInput defaultCity={defaultCity} />}
          {!defaultCityName && <LocationInput />}
        </div>
        <div className='main-form__buttons'>
          {hasCancelButton && <Button
            variant='outlined'
            sx={{ minWidth: '196px', marginRight: 3}}
            onClick={onClose}
          >{t('actions__text__cancel')}</Button>}
          <Button
            variant='contained'
            fullWidth={!hasCancelButton}
            type='submit'
            disabled={!saveButtonIsActive}
            startIcon={<CheckIcon />}
            sx={{ minWidth: hasCancelButton ? '416px': 'auto'}}
          >{t('actions__text__saveInvoice')} {invoiceNum && !id ? `№${invoiceNum}` : ''}</Button>
        </div>
        {showErrorNotification && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__save')} text={t('errors__message__tryLater5Min')} sx={{ marginTop: 4 }} /> }
      </form>
    </FormProvider>
  )
}

export default EditInvoice
