import { useState, ReactNode } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip from '@mui/material/Tooltip'
import { Alert } from 'assets/svg'

type TooltipParams = {
    content: ReactNode
    sx?: object
}


const CustomTooltip = ({content} : TooltipParams) => {
  const [ isTooltipOpened, setIsTooltipOpened ] = useState(false)

  const openTooltip = () => setIsTooltipOpened(true)

  const closeTooltip = () => setIsTooltipOpened(false)

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <Tooltip
        placement='top'
        open={isTooltipOpened}
        // // PopperProps={{ disablePortal: true }}
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
        title={content}
        arrow
      >
        <Alert onClick={openTooltip} />
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CustomTooltip
