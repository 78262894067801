import React, {ReactNode, useEffect, useState} from "react";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {useTranslation} from "react-i18next";
import {
  useSummaryByOrderIdQuery,
  useSummary,
  useUpdateSummaryByOrderIdMutation,
  useGetInvoices,
  useDeclarationsByOrderIdQuery,
  useSetCompletedByOrderIdMutation,
  useAddServicesToRouteByOrderIdMutation, useGetRouteByOrderIdQuery,
} from "../../../../../../../api/calc";

import './summary.scss'
import {getPluralForm} from "../../../../../../../utils/validation";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import CombinedCargo from './components/combined_cargo';
import Containers from './components/containers';
import DeliveryType from './components/delivery_type';
import Completed from "../../../../../../completed";
import EditContainers from "./components/edit_containers";
import isEmpty from "lodash/isEmpty";
import Chip from "@mui/material/Chip";

export const enum METHODS {
  COMBINED_CARGO = 1,
  CONTAINERS = 2
}

export const enum CONTAINERS_FORM {
  FT_20 = 'type_20dc',
  FT_40 = 'type_40dc',
  FT_40_HIGH_CUBE = 'type_40hc',
}

export type SummaryData = {
  title: ReactNode
  items: {
    label: ReactNode
    value: ReactNode
  }[]
  hasChip?: boolean
}

type Props =  {
  productsCount: number
  handleComplete(): void
}

const Summary = ({productsCount, handleComplete}: Props) => {

  const [ deliveryMethod, setDeliveryMethod ] = useState<number | null>(null);

  const [ editMode, setEditMode ] = useState(false);

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const invoices = useGetInvoices()

  const { data: summary, refetch, isSuccess } = useSummaryByOrderIdQuery(orderId)

  const summaryContainers = useSummary()

  const containersUser = summaryContainers?.containers_user;
  const currentDeliveryType = summaryContainers?.delivery_type;
  const showWarningNotificationContainers = summaryContainers?.not_enough_space_warning;

  const { refetch: refetchDeclaration } = useDeclarationsByOrderIdQuery(orderId, { skip: !currentDeliveryType })

  const [ saveSummary ] = useUpdateSummaryByOrderIdMutation()
  const [ setCompleted ] = useSetCompletedByOrderIdMutation()
  const [ addServices ] = useAddServicesToRouteByOrderIdMutation()

  const hasContainersManual = !isEmpty(summaryContainers?.containers_manual);

  useEffect( () => {
    refetch();
  }, [ invoices.length, productsCount ])

  useEffect(() => {
    !isSuccess && refetch();
  }, [])

  useEffect(() => {
    summary && setDeliveryMethod(currentDeliveryType);
  }, [ summary ])

  if (!summary?.products_count) {
    return null;
  }

  const renderSummary = ({ title, items, hasChip } : SummaryData) => <>
    {hasChip && (
      <Chip
        label={hasContainersManual ? t('calc__text__packaging__valuesAreCalculatedManually') :
          t('calc__text__packaging__valuesAreCalculatedAutomatically')}
        sx={{
          marginBottom: 3,
        }}
      />
    )}
    <Typography variant='subtitle1'>
      {title}
    </Typography>
    <ul className='summary-packaging__total'>
      {items.map((item, key) => (
        <li key={key}>
          <Typography variant='caption'>
            {item.label}
          </Typography>
          <Typography variant='subtitle1'>
            {item.value}
          </Typography>
        </li>
      ))}
    </ul>
  </>

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];
  const INVOICE_DECLENSIONS: PluralForms = [ t('declension__text__invoiceOne'), t('declension__text__invoiceSeveral'), t('declension__text__invoiceMany') ];

  const titleMain = <> <b>{summary.products_count}</b> {getPluralForm(summary.products_count, PRODUCTS_DECLENSIONS)}&nbsp;
    {t('common__text__in')}&nbsp;
    <b>{summary.invoices_count}</b> {getPluralForm(summary.invoices_count, INVOICE_DECLENSIONS)}, {t('common__text__finalCost')} — <b>{summary.price_total} USD</b></>

  const infoMain = [
    {
      label: t('common__text__totalAmount'),
      value: <>{summary.volume_total} {t('units__text__meter')}<sup>3</sup></>
    },
    {
      label: t('common__text__netGrossWeight'),
      value:  <>{summary.net_weight_total} {t('units__text__kg')} / {summary.gross_weight_total} {t('units__text__kg')}</>
    }
  ]

  const handleSendCompleted = async () => {
    await setCompleted({
      orderId,
      packaging: true,
      delivery: false,
      customs: false,
      additional_services: false,
    })

    await addServices({
      orderId,
      route: null,
      services: []
    });
  }

  const handleSave = async () => {
    let savedData: UpdateSummaryParamsInput = {
      orderId,
      delivery_type: deliveryMethod,
    }

    if (deliveryMethod === METHODS.CONTAINERS) {
      savedData = {
        ...savedData,
        containers_manual: containersUser
      }
    }

    await saveSummary(savedData)

    if (deliveryMethod !== summary.delivery_type) {
      await handleSendCompleted()
    }

    refetch();
    refetchDeclaration();
    handleComplete();
    setEditMode(false);
  }

  const isCombinedCargoMethod = summary.delivery_type === METHODS.COMBINED_CARGO;
  const isContainersMethod = summary.delivery_type === METHODS.CONTAINERS;

  const getValueCompleted = () => {
    if (isCombinedCargoMethod){
      return t('calc__text__packaging__combinedCargo')
    }
    if (isContainersMethod){
      return t('calc__text__packaging__containers')
    }
    return ''
  }

  const infoCompleted = [
    {
      label: t('calc__text__packaging__deliveryMethod'),
      value: getValueCompleted(),
      isStrong: true,
    }
  ]

  const handleEditSave = async (values?: ContainersManual) => {
    setDeliveryMethod(METHODS.CONTAINERS)

    const savedData: UpdateSummaryParamsInput = {
      orderId,
      delivery_type: deliveryMethod,
      containers_manual: values
    }

    await saveSummary(savedData)

    if (summary.delivery_type !== METHODS.CONTAINERS) {
      await handleSendCompleted()
    }

    setEditMode(false)

    refetch();
    refetchDeclaration();
  }

  const handleSetAutoContainers = async () => {
    await handleEditSave({})
    setEditMode(true);
  }

  return <>
    <div className='summary-packaging'>
      <Typography variant='h6' sx={{ marginBottom: 3}}>
        {t('calc__text__packaging__summaryAboutPackage')}
      </Typography>
      <Divider sx={{ marginBottom: 3}} />
      {renderSummary({ title: titleMain, items: infoMain})}
      <div className='summary-packaging__delivery'>
        {(editMode || !summary?.delivery_type) ? <>
          <DeliveryType deliveryMethod={deliveryMethod} setDeliveryMethod={setDeliveryMethod} defaultValue={summary.delivery_type} />
          {deliveryMethod && <div className='summary-packaging__block'>
            {deliveryMethod === METHODS.COMBINED_CARGO && <CombinedCargo summary={summary} renderSummary={renderSummary} handleSave={handleSave} /> }
            {deliveryMethod === METHODS.CONTAINERS && <EditContainers onSave={handleEditSave} handleSetAutoContainers={handleSetAutoContainers} />}
          </div>}
        </> : <Completed info={infoCompleted} onEdit={() => setEditMode(true)}>
          {isCombinedCargoMethod && <CombinedCargo summary={summary} renderSummary={renderSummary} />}
          {isContainersMethod && <Containers
            summary={containersUser}
            renderSummary={renderSummary}
            handleSetAutoContainers={handleSetAutoContainers}
            showWarningNotificationContainers={showWarningNotificationContainers}
            isManual={hasContainersManual}
          />}
        </Completed>}
      </div>
    </div>
  </>
}

export default Summary
