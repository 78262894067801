import React from "react";

import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './summary-invoice.scss';

type Props = {
  invoice: BankCommissionsInvoice;
  index: number
}

const SummaryInvoice = ({invoice, index}: Props) => {

  const { t } = useTranslation()

  const bankingCommissionsLength = invoice.banking_commissions.length;
  const hasAtLeastFourInvoices = bankingCommissionsLength >= 4;
  const tdWidth = 640 / (bankingCommissionsLength + 2);
  const tdWidthMany = 106;

  return <div className='payments-summary__invoice'>
    <Typography variant='caption'>
      {`${t('common__text__invoice')}-${index + 1}`}
    </Typography>
    <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 3 }}>№ {invoice.invoice_num} </Typography>
    <TableContainer className='payments-summary__invoice-table'>
      <Table sx={{ width: 640, tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                padding: '16px 0',
                width: hasAtLeastFourInvoices ? tdWidth : 'auto'
              }}
            >{t('common__text__transactions')}</TableCell>
            {invoice.banking_commissions.map((bankCommission, index) => (
              <TableCell align='right' key={index} sx={ hasAtLeastFourInvoices ? { width: `${tdWidthMany}px` } : { width: `${tdWidth}px`}}>{`${t('calc__text__bankCommissions__payment')}-${index + 1},`} {hasAtLeastFourInvoices && <br/>} { bankCommission.commission_percentage && `${parseFloat(bankCommission.commission_percentage)}%, USD`}</TableCell>
            ))}
            <TableCell align='right' sx={{ textTransform: 'capitalize', width: tdWidth }}>{`${t('calc__text__bankCommissions__amount')},`} {hasAtLeastFourInvoices && <br/>} USD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell
              component='th'
              scope='row'
              sx={{
                padding: '16px 0',
              }}
            >{t('calc__text__bankCommissions__payment')}</TableCell>
            {invoice.banking_commissions.map((bankCommission, index) => (
              <TableCell align='right' key={index} >{bankCommission.payment_amount}</TableCell>
            ))}
            <TableCell align='right'>{invoice?.invoice_summary?.total_payment_amount}</TableCell>
          </TableRow>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component='th' scope='row' sx={{
              padding: '16px 0',
            }}>{t('calc__text__bankCommissions__bankCommissionForTheTransfer')}</TableCell>
            {invoice.banking_commissions.map((bankCommission, index) => (
              <TableCell align='right' key={index}>{bankCommission.bank_transfer_fee_usd}</TableCell>
            ))}
            <TableCell align='right'>{invoice?.invoice_summary?.total_banking_commissions_usd}</TableCell>
          </TableRow>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component='th' scope='row' sx={{
              padding: '16px 0',
            }}>{t('calc__text__bankCommissions__currencyControlCommission')}</TableCell>
            {invoice.banking_commissions.map((bankCommission, index) => (
              <TableCell align='right' key={index} >{bankCommission.currency_control_fee_usd}</TableCell>
            ))}
            <TableCell align='right'>{invoice.invoice_summary?.total_currency_control_fees_usd}</TableCell>
          </TableRow>
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component='th' scope='row' sx={{ textTransform: 'uppercase', padding: '16px 0',}}>{t('common__text__inTotal')}</TableCell>
            {invoice.banking_commissions.map((bankCommission, index) => {
              // @ts-ignore
              const total = Number(bankCommission?.payment_amount) + bankCommission?.bank_transfer_fee_usd + bankCommission.currency_control_fee_usd;
              return (
                <TableCell align='right' sx={{ fontWeight: 500 }} key={index}>{total}</TableCell>
              )
            })}
            <TableCell align='right' sx={{ fontWeight: 500 }}>{invoice?.invoice_summary?.total_payment_amount_with_commissions}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </div>
}

export default SummaryInvoice
