import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Template from '../../../../../../common/templateFilter';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import { Ship, Car, Plane, Train } from "../../../../../../../assets/svg";
import {useParamsData} from "../../../../../../../api/calc";

type Props = {
  onChange(value: string[]): void
}

enum DELIVERY_TYPE {
  SEA = 'sea',
  AUTO = 'auto',
  AVIA = 'avia',
  RAILS = 'rails',
}

const ShippingMethod = ({onChange} : Props) => {

  const [ shippingMethods, setShippingMethods ] = useState<DELIVERY_TYPE[]>([]);

  const filterData = useParamsData();

  useEffect(() => {
    if (!filterData.shipping_method) {
      setShippingMethods([ DELIVERY_TYPE.SEA, DELIVERY_TYPE.AUTO, DELIVERY_TYPE.RAILS, DELIVERY_TYPE.AVIA ])
    }
  }, [ filterData ])

  const { t } = useTranslation()

  const handleChangeCheckbox = (value: DELIVERY_TYPE) => {
    if (shippingMethods.includes(value)) {
      const newArray = shippingMethods.filter((item) => item !== value)
      setShippingMethods(newArray)
      onChange(newArray)
    } else {
      setShippingMethods([ ...shippingMethods, value ])
      onChange([ ...shippingMethods, value ])
    }
  }

  return (
    <Template title={t('calc__text__packaging__deliveryMethod')} hasSeveralValues>
      <FormGroup>
        <FormControlLabel
          className='search-filter__control-label'
          control={<Checkbox defaultChecked checked={shippingMethods.includes(DELIVERY_TYPE.AUTO)} onChange={() => handleChangeCheckbox(DELIVERY_TYPE.AUTO)} />}
          label={<span className='search-filter__label'>{t('calc__text__delivery__automotive')} <Car /></span>}
          sx={{ marginBottom: '12px' }}
        />
        <FormControlLabel
          className='search-filter__control-label'
          control={<Checkbox defaultChecked checked={shippingMethods.includes(DELIVERY_TYPE.RAILS)} onChange={() => handleChangeCheckbox(DELIVERY_TYPE.RAILS)} />}
          label={<span className='search-filter__label'>{t('calc__text__delivery__railway')} <Train /></span>}
          sx={{ marginBottom: '12px' }}
        />
        <FormControlLabel
          className='search-filter__control-label'
          control={<Checkbox defaultChecked checked={shippingMethods.includes(DELIVERY_TYPE.AVIA)} onChange={() => handleChangeCheckbox(DELIVERY_TYPE.AVIA)} />}
          label={<span className='search-filter__label'>{t('calc__text__delivery__air')} <Plane /></span>}
        />
        <FormControlLabel
          className='search-filter__control-label'
          control={<Checkbox defaultChecked checked={shippingMethods.includes(DELIVERY_TYPE.SEA)} onChange={() => handleChangeCheckbox(DELIVERY_TYPE.SEA)} />}
          label={<span className='search-filter__label'>{t('calc__text__delivery__water')} <Ship /></span>}
        />
      </FormGroup>
    </Template>
  )
}

export default ShippingMethod
