import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from "react-i18next"
import Select, {SelectChangeEvent} from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { COLORS } from "../../../../../../../../../config/constants";
import {PackageForm, PACKAGE_FORM} from "../add_form/helpers";
import { useLazyGetPalletesQuery } from "../../../../../../../../../api/palletes";

type PalletOption = {
  code: string
  name: string
}

type Option = {
  value: string
  label: string
}

type Props = {
  defaultPalletType?: Option
}

const PalletesType  = ({defaultPalletType}: Props) => {

  const [ error, setError ] = useState(false);

  const { getValues, register, setValue } = useFormContext<PackageForm>()

  const [ defaultValue ] = useState<Option | undefined>(defaultPalletType)

  const [ palletesRequest, { data } ] = useLazyGetPalletesQuery()

  useEffect(() => {
    palletesRequest('')
  }, [  ])

  const { t } = useTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    setValue(PACKAGE_FORM.PALLETE_TYPE, event.target.value)
  };

  const options = data?.map((item: PalletOption) => ({
    value: item.code,
    label: item.name,
  }));

  return (
    <FormControl
      fullWidth
      error={error}
      {...register(PACKAGE_FORM.PALLETE_TYPE, { required: true })}
      className='add-product__palletes-type'
    >
      <InputLabel id='palletesType' shrink sx={{ background: COLORS.WHITE}}>{t('calc__text__packaging__palletType')}</InputLabel>
      <Select
        labelId='palletesType'
        id='palletesType'
        label={t('calc__text__packaging__palletType')}
        onClose={() => setError(!(getValues()[PACKAGE_FORM.PALLETE_TYPE]))}
        onChange={handleChange}
        defaultValue={defaultValue?.value}
      >
        {options?.map((option: Option) => (
          <MenuItem {...option} key={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{t('common__text__isRequired')}</FormHelperText>}
    </FormControl>
  )
}

export default PalletesType
