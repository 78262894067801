import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import CommonInvoice from './components/invoice-title';
import Payments from './components/payments';

import './invoice.scss';

type Props = {
    invoice: InvoiceParamsOutput,
    index: number
    refetch(): void
}

const Invoice = ({invoice, index, refetch}: Props) => {

  const [ shouldShowInvoiceInfo, setShouldShowInvoiceInfo ] = useState(true)

  const handleChangeShowInvoiceInfo = () => setShouldShowInvoiceInfo(!shouldShowInvoiceInfo);

  return <div className='bank-fees__invoice'>
    <Accordion
      expanded={shouldShowInvoiceInfo}
      onChange={handleChangeShowInvoiceInfo}
      sx={{
        marginBottom: 0,
        boxShadow: 'none',
        '&.Mui-expanded:last-of-type': {
          marginBottom: 0,
        }
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          '.MuiAccordionSummary-content': {
            display: 'block',
            margin: 0,
          },
          '.Mui-expanded.MuiAccordionSummary-content': {
            margin: 0,
          }
        }}
      >
        <CommonInvoice
          isOpen={shouldShowInvoiceInfo}
          handleClickArrow={handleChangeShowInvoiceInfo}
          index={index}
          invoiceNumber={invoice.invoice_num}
          invoiceAmount={invoice.price_total}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}>
        {invoice.price_total && <Payments
          invoiceNumber={invoice.invoice_num}
          invoiceId={invoice.id.toString()}
          refetch={refetch}
          invoiceAmount={invoice.price_total}
        />}
      </AccordionDetails>
    </Accordion>
  </div>
}

export default Invoice
