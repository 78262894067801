import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import AddForm from './components/add_form';
import Completed from '../../../../../../completed'
import ModalBig from "../../../../../../common/modalBig";
import CommonInvoice from './components/common_invoice'
import {
  useProductsByInvoiceIdQuery,
  useRemoveProductMutation,
  useInvoicesByOrderIdQuery,
  useDeclarationsByOrderIdQuery,
  useDeclarationSummaryByOrderIdQuery,
  useSummary,
  useSummaryByOrderIdQuery,
  useUpdateSummaryByOrderIdMutation,
  useSetCompletedByOrderIdMutation,
  useGetOrderSummaryInvoicesByIdQuery,
  useGetAdditionalServicesByOrderIdQuery, useAddServicesToRouteByOrderIdMutation
} from "../../../../../../../api/calc";
import ModalSmall from "../../../../../../common/modalSmall";
import findIndex from "lodash/findIndex";
import sortBy from "lodash/sortBy";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";

import './invoice.scss'

type Props = {
  invoice: InvoiceParamsOutput,
  index: number
  productsCount: number
}

const Invoice = ( {invoice, index, productsCount} : Props) => {

  const orderId = useGetCalcIdFromRoute()

  const { t } = useTranslation()

  const { data: products, refetch } = useProductsByInvoiceIdQuery(invoice.id)

  const summaryOrder = useSummary();

  const isPackagingCompleted = Boolean(summaryOrder?.delivery_type);

  const [ shouldRefetchCustoms, setShouldRefetchCustoms ] = useState(true);

  const { refetch: refetchInvoices } = useInvoicesByOrderIdQuery(orderId, { skip: !orderId })
  const { refetch: refetchDeclaration } = useDeclarationsByOrderIdQuery(orderId, { skip: !shouldRefetchCustoms })
  const { refetch: refetchSummaryDeclaration } = useDeclarationSummaryByOrderIdQuery(orderId, { skip: !shouldRefetchCustoms })
  const { refetch: refetchSummaryPackaging } = useSummaryByOrderIdQuery(orderId, { skip: !shouldRefetchCustoms })
  const { refetch: refetchOrderSummary } = useGetOrderSummaryInvoicesByIdQuery(orderId, { skip: !orderId })
  const { refetch: refetchAdditionalServices } = useGetAdditionalServicesByOrderIdQuery(orderId, { skip: !orderId })

  const [ showForm, setShowForm ] = useState(false)

  const [ currentProductId, setCurrentProductId ] = useState<number>();

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const [ shouldOpenRemoveModal, setShouldOpenRemoveModal ] = useState(false)

  const [ shouldShowInvoiceInfo, setShouldShowInvoiceInfo ] = useState(true)

  const [ removeProduct ] = useRemoveProductMutation()
  const [ saveSummary ] = useUpdateSummaryByOrderIdMutation()
  const [ setCompleted ] = useSetCompletedByOrderIdMutation()
  const [ addServices ] = useAddServicesToRouteByOrderIdMutation()

  const myProducts = sortBy(products, 'id');

  const getInfo = (product: ProductParamsOutput, index: number) => [
    {
      label: `${t('common__text__addItem')}-${index + 1}`,
      value: `${product.name} (${product.country_data?.name})`,
    },
    {
      label: t('common__text__pricePerPiece'),
      value: `${product.unit_price} USD`
    },
    {
      label: t('common__text__packageVolume'),
      value: <>{product.volume_total || 0} {t('units__text__meter')}<sup>3</sup></>
    },
    {
      label: t('common__text__netWeightPackaging'),
      value: `${product.net_weight_total} ${t('units__text__kg')}`
    },
    {
      label: t('common__text__grossPackagingWeight'),
      value: `${product.gross_weight_total} ${t('units__text__kg')}`
    }
  ]

  const handleSuccessAdded = () => {
    refetchInvoices()
    refetch()
    setShowForm(false)
    setOpenEditModal(false)
    setShouldRefetchCustoms(true);

    if (isPackagingCompleted) {
      refetchDeclaration()
      refetchSummaryDeclaration()
      refetchSummaryPackaging()
      refetchOrderSummary()
    }
  }

  const removeItem = (productId?: number | string) => {
    if (productId) {
      removeProduct({
        invoiceId: invoice.id,
        productId: Number(productId)
      }).then(async () => {
        setShouldOpenRemoveModal(false)
        refetch()
        refetchInvoices()
        setShouldRefetchCustoms(true);

        if (productsCount === 1) {
          await saveSummary({
            orderId,
            delivery_type: null,
            isEmpty: true,
            containers_manual: {},
            containers_user: {}
          })

          await addServices({
            orderId,
            route: null,
            services: [],
          });

          setCompleted({
            orderId,
            packaging: false,
            delivery: false,
            customs: false,
            additional_services: false
          })
        }


        if (isPackagingCompleted) {
          refetchDeclaration()
          refetchSummaryDeclaration()
          refetchSummaryPackaging()
          refetchOrderSummary()
          refetchAdditionalServices()
        } else {
          refetchSummaryDeclaration()
        }
      })

    }
  }

  const onRemove = (id: number) => {
    setCurrentProductId(id)
    setShouldOpenRemoveModal(true)
  }

  const onEdit = (id: number) => {
    setCurrentProductId(id)
    setOpenEditModal(true)
  }

  const getCurrentIndex = () => findIndex(myProducts, (product) => product.id === currentProductId) + 1

  return <><div className='packaging-invoice'>
    <CommonInvoice index={index} invoice={invoice} isOpen={shouldShowInvoiceInfo} handleClickArrow={() => setShouldShowInvoiceInfo(!shouldShowInvoiceInfo)} showArrow={myProducts?.length > 0}/>
    {shouldShowInvoiceInfo && myProducts?.map((product, index) => <Completed
      key={product.id}
      info={getInfo(product, index)}
      caption={`${product.price_total} USD ${t('common__text__for')} ${product.pieces} ${t('units__text__items')}`}
      hasTitle
      onEdit={() => onEdit(product.id)}
      onRemove={() => onRemove(product.id)}
    />)}
    {showForm ? <AddForm
      onCancel={() => setShowForm(false)}
      invoice={invoice}
      handleSuccess={handleSuccessAdded}
      count={products ? products.length + 1 : 0}
    /> : (
      <Button
        color='secondary'
        variant='outlined'
        fullWidth
        startIcon={<AddIcon />}
        sx={{
          marginTop: products ? 0 : 3,
        }}
        onClick={() => setShowForm(true)}
      >
        {`${t('actions__text__addItem')}-${products ? products.length + 1 : '1'}`}
      </Button>
    )}
  </div>
  {openEditModal && <ModalBig
    isOpen
    onClose={() => setOpenEditModal(false)}
    title={`${t('actions__text__editItem')}-${getCurrentIndex()}` }
  >
    <AddForm
      onCancel={() => setOpenEditModal(false)}
      invoice={invoice}
      invoiceId={index}
      handleSuccess={handleSuccessAdded}
      id={currentProductId}
      count={getCurrentIndex()}
      insideModal
    />
  </ModalBig>}
  {shouldOpenRemoveModal && <ModalSmall
    isOpen={shouldOpenRemoveModal}
    onContinue={removeItem}
    index={currentProductId}
    onClose={() => setShouldOpenRemoveModal(false) }
    title={t('common__text__deleteItem')}
    description={<>{t('actions__message__removeConfirm')}<span className='packaging-invoice__text_bold'>{` ${t('common__text__addItem')}-${getCurrentIndex()}`}?</span></>}
    buttonYesText={t('actions__text__yesDelete')}
  />}
  </>
}

export default Invoice
